import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "tss-react/mui";
import { useNavigate, useLocation } from "react-router-dom";
import { useTheme } from "@mui/material/styles";

import { Theme } from "@mui/material/styles";
import { CustomColor } from "../../utils/theme";
import { type IStyleProps, type IExpandButtonProps } from "../../utils/interfaces";

const useStyles = makeStyles<IStyleProps>()((theme, { isExpanded }) => ({
  button: {
    minWidth: "150px",
    width: isExpanded ? "auto" : "150px",
    height: "auto",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    transition: "all 0.3s ease",
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(2),
    },
  },
}));

const ExpandButton = ({
  isExpanded,
  icon,
  text,
  expandComponent,
  redirectTo,
  colorKey,
} : IExpandButtonProps) => {
  const { classes } = useStyles({ isExpanded });
  const navigate = useNavigate();
  const theme = useTheme();
  const location = useLocation();

  const toggleExpand = () => {
    if (isRouteActive(redirectTo)) {
      return;
    }
    navigate(redirectTo);
  };

  const isRouteActive = (routePath : string) => {
    return location.pathname === routePath;
  };

  // Assuming `theme` is of type Theme from @mui/material/styles
  const getBackgroundColor = (colorKey: string, theme: Theme): string => {
    // Attempt to access the colorKey directly with type assertion
    const color = (theme.palette as any)[colorKey] as CustomColor | undefined;

    // If color exists and has a 'main' property, return it
    if (color && typeof color.main === 'string') {
        return color.main;
    }

    // Fallback to the primary main color if the colorKey is not found
    return theme.palette.primary.main;
  };

  return (
    <div
      style={{ backgroundColor: getBackgroundColor(colorKey, theme) }}
      onClick={toggleExpand}
      className={classes.button}
    >
      {!isExpanded && (
        <>
          {icon}
          <p>{text}</p>
        </>
      )}
      {isExpanded && expandComponent}
    </div>
  );
};

ExpandButton.propTypes = {
  isExpanded: PropTypes.bool.isRequired,
  icon: PropTypes.element.isRequired,
  text: PropTypes.string.isRequired,
  expandComponent: PropTypes.element.isRequired,
  redirectTo: PropTypes.string.isRequired,
  colorKey: PropTypes.string.isRequired,
};

export default ExpandButton;
