import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import TextWithCounter from "./TextWithCounter";

// Define your component styles using makeStyles
const useStyles = makeStyles()((theme) => ({
  panel: {
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap", // This allows the items to wrap onto the next line
    justifyContent: "left",
    margin: theme.spacing(0, 0),
    backgroundColor: theme.palette.common.white,
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(0),
    },
  },
  layoutContainer: {
    display: "grid",
    gridTemplateColumns: "auto auto",
    marginBottom: theme.spacing(0), // Space before the bottomContainer
    width: "100%",
    [theme.breakpoints.down("md")]: {
      gridTemplateColumns: "auto", // Single column on small screens
    },
  },
  textWithCounter: {
    flexBasis: "50%", // This ensures it's always full width
    width: "100%",
    [theme.breakpoints.down("md")]: {
      width: "100%", // 100% of the viewport width on small screens
    },
  },
  textAreaTitle: {
    width: "90%",
    height: "30px",
    padding: theme.spacing(1),
    fontSize: "16px",
    border: `1px solid ${theme.palette.greenBorder.hover}`,
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(0),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    resize: "vertical",
  },
}));

interface ITextTitleBlockProps {
  showTitle: boolean;
  title: string;
  titlePlaceHolder: string;
  onTitleChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  content: string;
  contentPlaceHolder: string;
  onContentChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  aiContent: string;
  onAIContentChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  showApproxAudioLength: boolean;
  showAiContent: boolean;
  isTyping: boolean;
}

function TextTitleBlock({
  showTitle,
  title,
  titlePlaceHolder,
  onTitleChange,
  content,
  contentPlaceHolder,
  onContentChange,
  aiContent,
  onAIContentChange,
  showApproxAudioLength,
  showAiContent,
  isTyping,
} : ITextTitleBlockProps) {
  const { t } = useTranslation();
  const { classes } = useStyles();

  const onLocalTitleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    onTitleChange(event);
  };

  return (
    <div className={classes.panel}>
      {showTitle ? (
        <textarea
          value={title}
          onInput={onLocalTitleChange}
          className={classes.textAreaTitle}
          placeholder={titlePlaceHolder}
        />
      ) : null}
      <div className={classes.layoutContainer}>
        <div className={classes.textWithCounter}>
          <TextWithCounter
            title={t("content")}
            content={content}
            contentPlaceHolder={contentPlaceHolder}
            onContentChange={onContentChange}
            showApproxAudioLength={showApproxAudioLength}
            borderColor="greenBorder"
          />
        </div>
        <div className={classes.textWithCounter}>
          {showAiContent ? (
            <div>
              <TextWithCounter
                title={t("aiContent")}
                content={aiContent}
                onContentChange={onAIContentChange}
                contentPlaceHolder=""
                showApproxAudioLength={showApproxAudioLength}
                borderColor="red"
                isTyping={isTyping}
              />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

TextTitleBlock.propTypes = {
  showTitle: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  titlePlaceHolder: PropTypes.string.isRequired,
  onTitleChange: PropTypes.func.isRequired,
  content: PropTypes.string.isRequired,
  contentPlaceHolder: PropTypes.string.isRequired,
  onContentChange: PropTypes.func.isRequired,
  aiContent: PropTypes.string,
  onAIContentChange: PropTypes.func.isRequired,
  showApproxAudioLength: PropTypes.bool.isRequired,
  isTyping: PropTypes.bool,
};

export default TextTitleBlock;
