import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "tss-react/mui";
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles()((theme) => ({
  navbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(1), // Add a little padding for good measure
    [theme.breakpoints.down("md")]: {
      justifyContent: "flex-start", // Change to flex-start to align items to the left on smaller screens
    },
  },

  buttonGroup: {
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },

  button: {
    padding: theme.spacing(1, 2),
    margin: theme.spacing(1),
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.gray.hover,
    },
    fontSize: "1rem",
    color: theme.palette.gray.main,
  },
  copyRight: {
    padding: theme.spacing(1, 2),
    margin: theme.spacing(1),
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.gray.hover,
    },
    fontSize: "1rem",
    color: theme.palette.gray.main,
  },
  menuBox: {
    flexGrow: 1,
    display: "flex", // Default
    [theme.breakpoints.up("sm")]: {
      display: "none", // Hide on medium and larger screens
    },
  },
  menuPaper: {
    display: "none",
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
  },
}));

interface IFooterButtonProps {
  label: string;
  onClick: () => void;
};

// Footer Button Component
const FooterButton = ({ label, onClick } : IFooterButtonProps) => (
  <div className={useStyles().classes.button} onClick={onClick}>
    {label}
  </div>
);

FooterButton.propTypes = {
  label: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

// Footer Component
const Footer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { classes } = useStyles();

  const pages = [t("dataprotection"), t('conditions'), t("imprint")];

  // useState hook with specific type for anchor element
  const [anchorElNav, setAnchorElNav] = React.useState<HTMLElement | null>(null);

  // Event handler with specific event type
  const handleOpenNavMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    console.log("handleCloseNavMenu");
    setAnchorElNav(null);
  };

  const handleNavIconClick = (page : string) => {
    switch (page) {
      case t("dataprotection"):
        navigate("/dataprotection");
        break;
      case t("imprint"):
        navigate("/imprint");
        break;
      case t("conditions"):
        navigate("/conditions");
        break;
      default:
        navigate("/");
    }
  };

  return (
    <nav className={classes.navbar}>
    <Box className={classes.menuBox}>
        <IconButton
          size="large"
          aria-label="menu appbar"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={(e) => handleOpenNavMenu(e)}
          color="inherit"
        >
          <MenuIcon />
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorElNav}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          open={Boolean(anchorElNav)}
          onClose={handleCloseNavMenu}
          className={classes.menuPaper}
        >
          {pages.map((page) => (
            <MenuItem key={page} onClick={() => {handleNavIconClick(page); setAnchorElNav(null);}}>
              <Typography textAlign="center">{page}</Typography>
            </MenuItem>
          ))}
        </Menu>
      </Box>
      <div className={classes.buttonGroup}>
        {pages.map((page) => (
          <FooterButton
            key={page}
            label={page}
            onClick={() => handleNavIconClick(page)}
          />
        ))}
      </div>
      <div className={classes.copyRight}>{t("copyright")}</div>
    </nav>
  );
};

export default Footer;
