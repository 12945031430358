import { initializeApp, getApp } from "firebase/app";
import { firebaseConfig } from "./constants";

function initializeAppIfNecessary() {
  try {
    return getApp();
  } catch (any) {

    return initializeApp(firebaseConfig);
  }
}

const app = initializeAppIfNecessary();
export default app;
