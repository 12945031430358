import React, { useState } from "react";
import { makeStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TablePagination,
} from "@mui/material";
import { type IAudioGeneratedDataGridProps } from "../../../utils/interfaces";

const useStyles = makeStyles()((theme) => ({
  table: {
    minWidth: 650,
    [theme.breakpoints.down("sm")]: {
      minWidth: 300,
    },
  },
  headCell: {
    backgroundColor: theme.palette.violet.main,
    color: "white",
  },
  bodyCell: {
    fontSize: 14,
    padding: theme.spacing(1),
  },
  evenRow: {
    backgroundColor: theme.palette.violet.mainAlpha,
    "&:hover": {
      // Apply the hover effect only when hovering
      backgroundColor: theme.palette.blue.hoverAlpha,
    },
  },
  oddRow: {
    backgroundColor: theme.palette.blue.mainAlpha,
    "&:hover": {
      // Apply the hover effect only when hovering
      backgroundColor: theme.palette.blue.hoverAlpha,
    },
  },
  downloadButton: {
    backgroundColor: theme.palette.violet.main,
    color: "#fff",
    "&:hover": {
      backgroundColor: theme.palette.violet.hover,
    },
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    fontSize: 12,
    textTransform: "none",
  },
}));

function AudioGeneratedDataGrid({
  audioGeneratorData,
  handleRowSelection,
  handleButtonClick,
} : IAudioGeneratedDataGridProps) {
  const { classes } = useStyles();
  const { t } = useTranslation();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event : React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage : number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to the first page when rows per page change
  };

  const rowsToDisplay = audioGeneratorData.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const formatDate = (dateString : string) => {
    if (dateString === "" || dateString == null) {
      return "";
    }

    const date = new Date(dateString);
    return (
      date.toLocaleDateString("de-DE") + " " + date.toLocaleTimeString("de-DE")
    );
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="Audio Generated Data">
          <TableHead>
            <TableRow>
              {[
                "audioRequestName",
                "state",
                "length",
                "insertTime",
                "processedTime",
                "errorText",
                "download",
              ].map((header) => (
                <TableCell key={header} className={classes.headCell}>
                  {t(header)}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rowsToDisplay.map((item, index) => (
              <TableRow
                key={item.id}
                onClick={() => handleRowSelection(item)}
                className={`${
                  index % 2 === 0 ? classes.evenRow : classes.oddRow
                }`}
              >
                <TableCell className={classes.bodyCell}>{item.name}</TableCell>
                <TableCell className={classes.bodyCell}>
                  {item.status}
                </TableCell>
                <TableCell className={classes.bodyCell}>
                  {item.audio_option_name}
                </TableCell>
                <TableCell className={classes.bodyCell}>
                  {formatDate(item.insert_time)}
                </TableCell>
                <TableCell className={classes.bodyCell}>
                  {formatDate(item.processed_time)}
                </TableCell>
                <TableCell className={classes.bodyCell}>
                  {item.error_text}
                </TableCell>
                <TableCell className={classes.bodyCell}>
                  {item.download_url !== "" && item.download_url != null && (
                    <Button
                      className={classes.downloadButton}
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent row click event
                        handleButtonClick(item);
                      }}
                    >
                      {t("download")}
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 20, 50, 100]}
        component="div"
        count={audioGeneratorData.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage={t("rowsPerPage")} // Localized label
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to} ${t("of")} ${
            count !== -1 ? count : `${t("moreThan")} ${to}`
          }`
        }
      />
    </>
  );
}

export default AudioGeneratedDataGrid;
