import React, { useState } from "react";
import { makeStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { type IHistoryProps } from "../../../utils/interfaces";

const useStyles = makeStyles()((theme) => ({
  wrapper: {
    paddingTop: "5px",
    paddingBottom: "5px",
    paddingLeft: "10px",
    paddingRight: "40px", // Adjust this to make room for the context menu
    marginBottom: theme.spacing(1),
    borderRadius: "10px",
    backgroundColor: theme.palette.violet.main,
    position: "relative", // Needed for absolute positioning of the context menu
    "&:hover": {
      cursor: "pointer",
      backgroundColor: theme.palette.violet.hover,
    },
    minHeight: "45px",
  },
  p: {
    margin: "0px",
    padding: "0px",
    fontFamily: theme.typography.button.fontFamily,
    fontWeight: theme.typography.button.fontWeight,
    fontSize: 14,
    color: "white",
  },
  dots: {
    position: "absolute",
    top: 5,
    right: 5,
    background: "none",
    border: "none",
    cursor: "pointer",
    fontSize: "24px",
    padding: "5px",
    display: "none", // Hidden by default, shown on hover
  },
  menuOptions: {
    flexDirection: "column",
    position: "absolute",
    top: "100%",
    right: 0,
    transform: "translateY(-100%)", // Adjust this to position the menu above the dots
    display: "none", // Initially hidden
    backgroundColor: theme.palette.blue.main,
    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)",
    borderRadius: "5px",
    padding: "5px",
  },
  menuItem: {
    padding: "5px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.blue.hover, // Adjust hover color
    },
    fontFamily: theme.typography.button.fontFamily,
    fontWeight: theme.typography.button.fontWeight,
    fontSize: 14,
    color: "white",
  },
}));

export default function History({ index, chat, onClick, onDelete, onRename } : IHistoryProps) {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const [showDots, setShowDots] = useState(false);
  const [showMenu, setShowMenu] = useState(false);

  return (
    <div>
      {chat && chat.name ? (
        <div
          className={classes.wrapper}
          onClick={onClick}
          onMouseEnter={() => setShowDots(true)}
          onMouseLeave={() => {
            setShowDots(false);
            setShowMenu(false);
          }}
        >
          <p className={classes.p}>{chat.name}</p>
          {showDots && (
            <button
              className={classes.dots}
              style={{ display: "block" }}
              onClick={(e) => {
                e.stopPropagation(); // Prevent the wrapper's onClick
                setShowMenu(!showMenu); // Toggle the menu
              }}
            >
              ...
            </button>
          )}
          {showMenu && (
            <div className={classes.menuOptions} style={{ display: "block" }}>
              <div
                className={classes.menuItem}
                onClick={(e) => {
                  e.stopPropagation();
                  onRename(index);
                  setShowMenu(false); // Close the menu after action
                }}
              >
                {t("rename")}
              </div>
              <div
                className={classes.menuItem}
                onClick={(e) => {
                  e.stopPropagation();
                  onDelete(index);
                  setShowMenu(false); // Close the menu after action
                }}
              >
                {t("delete")}
              </div>
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
}
