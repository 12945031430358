import React from "react";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "../../../utils/FirebaseAuth";
import PasswordResetForm from "./PasswordResetForm";
import { json } from "react-router-dom";
import { isErrorWithCode } from "../../../utils/generalFunctions";

function PasswordResetPage() {
  return <PasswordResetForm />;
}

export default PasswordResetPage;

export async function action({ request } : { request: Request}) {
  try {
    const data = await request.formData();
    const email = data.get("email") as string;
    await sendPasswordResetEmail(auth, email);

    // Password reset email sent!
    const message = "passwordResetEmailSent";
    return json({ message: message }, { status: 200 });
  } catch (error) {
    if (isErrorWithCode(error)) {
      const errorCode = error.code;
      var message = "errorSendingPasswordResetEmail";

      if (errorCode === "auth/invalid-email") {
        message = "invalidEmail";
      } else if (errorCode === "auth/user-not-found") {
        message = "userNotFound";
      } else if (errorCode === "auth/too-many-requests") {
        message = "tooManyRequests";
      }

      return json({ message: message }, { status: 422 });
    }
    console.error("Error sending password reset email: ", error);
  }
}
