import { useNavigate } from "react-router-dom";

const useApi = () => {
  const navigate = useNavigate();

  const customFetch = async (url, options) => {
    try {
      const response = await fetch(url, options);
      if (response.status === 401) {
        // Redirect to logout
        navigate("/home");
        return null; // Early return to avoid further processing
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.log("Error in API call:", error);
      throw error; // Re-throw the error for the caller to handle if needed
    }
  };

  return customFetch;
};

export default useApi;
