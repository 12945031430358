import { useState, useEffect } from 'react';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';
import { type IApiBasePathProps, type IFileRow, type IFileActionOption } from '../../../utils/interfaces';
import FileSelect from './FileSelect';
import { Container } from '@mui/material';
import Navigation from '../../navigation/Navigation';
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import OptionSelector from '../../navigation/OptionSelector';
import FileChat from './FileChat';

const useStyles = makeStyles()((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    maxWidth: "1400px",
  },
  configContainer: {
    width: "100%",
    flex: 1,
    display: "flex",
    flexDirection: "row",
  },
  content: {
    padding: theme.spacing(4),
    width: "100%",
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  iconButton: {
    padding: 0,
    width: "40px",
    height: "40px",
  },
  button: {
    textTransform: "none",
    marginTop: theme.spacing(0),
    backgroundColor: theme.palette.blue.main,
  },
  layoutContainer: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap", // Allow the containers to wrap
    justifyContent: "space-between",
    marginBottom: theme.spacing(0), // Space before the bottomContainer
  },
  fileSelectPage: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  optionSelectorsContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "center",
    alignItems: "flex-start",
    flexBasis: "100%",
    [theme.breakpoints.down("lg")]: {
      flexDirection: "column",
      flexBasis: "100%",
      flexWrap: "wrap",
    },
    margin: theme.spacing(0),
  },
}));

function FileSelectPage ({apiBasePath}: IApiBasePathProps) {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const navigate = useNavigate();
  const location = useLocation(); 
  const [selectedFile, setSelectedFile] = useState<IFileRow | null>(null);
  const [action, setAction] = useState<IFileActionOption | null>(null);
  const [actionId, setActionId] = useState<number>(-1);

  const actionOptions = [
    { id:1, name: t("fileUpload"), value: "/fileUpload" },
    { id:2, name: t("fileSelect"), value: "/fileSelect" },
  ];

  const isRouteActive = (routePath : string) => {
    return location.pathname === routePath;
  };

  useEffect(() => {
    for (let i = 0; i < actionOptions.length; i++) {
      if (isRouteActive(actionOptions[i].value)) {
        setAction(actionOptions[i]);
        setActionId(actionOptions[i].id);
        break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRowSelection = (row: any) => {
    setSelectedFile(row);
  }

  const handleActionOptionClick = async (selectedActionId : number) => {
    // Find the action directly without looping, thanks to the beauty of array.find!
    const selectedAction = actionOptions.find(action => action.id === selectedActionId);
    
    if (selectedAction && selectedActionId !== action?.id) {
      setActionId(selectedActionId); // Update the action ID state.
      setAction(selectedAction); // Update the action state.
  
      try {
        await navigate(selectedAction.value); // Use async/await to ensure navigation is complete.
      } catch (error) {
        console.error('Navigation failed:', error);
      }
    }
  };

  // const handleChatClicked = () => {
  //   console.log('Chat clicked');
  //   console.log('Row selected:', selectedFile);
  // }

  const optionSelectors = (
    <div className={classes.optionSelectorsContainer}>
      <OptionSelector
        title={t("fileAction")}
        options={actionOptions}
        selectedOption={actionId}
        onOptionClick={handleActionOptionClick}
        colorKey="greenSelect"
        hoverDisplay={false}
      />
    </div>
  );

  return (
    <Container className={classes.container} maxWidth={false}>
      <Navigation
          apiBasePath={apiBasePath}
          personalizeElement={<></>}
          chatElement={<></>}
          fileElement={optionSelectors}
          audioElement={<></>}
        />
      <div className={classes.layoutContainer}>
        <div className={classes.configContainer}>
          <div className={classes.content}>
            <div className={classes.fileSelectPage}>
              <h1>{t('fileSelection')}</h1>
              <FileSelect apiBasePath={apiBasePath} handleRowSelection={handleRowSelection} /> 
              <FileChat apiBasePath={apiBasePath} file={selectedFile} /> 
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default FileSelectPage;