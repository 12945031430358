import React from "react";
import { auth } from "../../../utils/FirebaseAuth";
import ChangePasswordForm from "./ChangePasswordForm";
import { json } from "react-router-dom";
import { updatePassword, signInWithEmailAndPassword } from "firebase/auth";
import { isErrorWithCode } from "../../../utils/generalFunctions";

function ChangePasswordPage() {
  return <ChangePasswordForm />;
}

export default ChangePasswordPage;

export async function action({ request } : { request: Request}) {
  try {
    const data = await request.formData();
    const email = data.get("email") as string;
    const oldPassword = data.get("oldPassword") as string;
    const newPassword = data.get("newPassword") as string;
    const confirmPassword = data.get("confirmPassword");

    if (newPassword !== confirmPassword) {
      return json({ message: "passwordsDontMatch" }, { status: 422 });
    }
    const userCredential = await signInWithEmailAndPassword(
      auth,
      email,
      oldPassword
    );
    console.log(userCredential);
    const user = auth.currentUser;
    console.log(user);
    //const credential = EmailAuthProvider.credential(email, oldPassword);
    //await user.reauthenticateWithCredential(credential);
    if (user === null) {
      return json({ message: "userNotFound" }, { status: 422 });
    }
    await updatePassword(user, newPassword);

    // Password reset email sent!
    const message = "passwordChanged";
    return json({ message: message, status: 200 }, { status: 200 });
  } catch (error) {
    console.error("Error changing password: ", error);
    if (isErrorWithCode(error)) {
    const errorCode = error.code;
    var message = "errorChangingPassword";

    if (errorCode === "auth/invalid-email") {
        message = "invalidEmail";
      } else if (errorCode === "auth/user-not-found") {
        message = "userNotFound";
      } else if (errorCode === "auth/too-many-requests") {
        message = "tooManyRequests";
      } else if (errorCode === "auth/weak-password") {
        message = "weakPassword";
      } else if (errorCode === "auth/wrong-password") {
        message = "wrongPassword";
      } else if (errorCode === "auth/operation-not-allowed") {
        message = "operationNotAllowed";
      } else {
        console.log("Error changing password: " + errorCode);
      }
      return json({ message: message, status: 422 }, { status: 422 });
    }
    else {
      return json({ message: "errorChangingPassword", status: 422 }, { status: 422 });
    }
    
  }
}
