import React, { useEffect, useState, useRef } from "react";
import { Container, Button, Snackbar } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import AudioGeneratedDataGrid from "./AudioGeneratedDataGrid";
import TextTitleBlock from "../personalize/TextTitleBlock";
import OptionSelector from "../../navigation/OptionSelector";
import { useTranslation } from "react-i18next";
import Navigation from "../../navigation/Navigation";
import { useRouteLoaderData } from "react-router-dom";
import { 
  type IApiBasePathProps, 
  type IAuthData, 
  type IAudioOptions, 
  type IAudioDownloadData, 
  type IAudioRequestItem, 
  type SnackbarColor 
} from "../../../utils/interfaces";

const useStyles = makeStyles()((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  select: {
    margin: theme.spacing(2),
    minWidth: 200,
  },
  button: {
    fontFamily: theme.typography.button.fontFamily,
    textTransform: "none",
    marginTop: theme.spacing(2),
    backgroundColor: theme.palette.blue.main,
  },
  layoutContainer: {
    display: "flex",
    flexDirection: "row", // Set direction to row
    flexWrap: "wrap", // Allow the containers to wrap
    justifyContent: "space-between", // Add space between the main content and sidebar
    marginBottom: theme.spacing(4), // Space before the bottomContainer
    padding: theme.spacing(4),
  },
  textTitleBlockContainer: {
    flexBasis: "100%", // Take up 60% of the container
    [theme.breakpoints.down("sm")]: {
      // Adjust for medium and smaller screens
      flexBasis: "100%", // Full width on smaller screens
    },
  },
  optionSelectorsContainer: {
    flexBasis: "100%", // Take up 35% of the container
    [theme.breakpoints.down("sm")]: {
      flexBasis: "100%", // Stack vertically on smaller screens
      marginTop: theme.spacing(2), // Add some space between the containers on smaller screens
    },
  },
  bottomContainer: {
    flexBasis: "100%", // This ensures it's always full width
    marginTop: theme.spacing(2),
  },
  buttonDiv: {
    display: "flex",
    flexDirection: "row",
    alignItems: "Center",
    justifyContent: "space-evenly",
    padding: theme.spacing(1),
    flexBasis: "100%",
  },
}));

function useInterval(callback: () => void, delay: number | null) {
  // Since the callback might not be a function initially or might change, 
  // we use useRef to hold its latest version. The type of the ref is a 
  // function or null to cover the initial state.
  const savedCallback = useRef<(() => void) | null>(null);

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      // Check if savedCallback.current is a function before calling it.
      // This is necessary because TypeScript recognizes that savedCallback.current
      // could be null.
      if(savedCallback.current) {
        savedCallback.current();
      }
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

function AudioPage({apiBasePath} : IApiBasePathProps) {
  const loaderData = useRouteLoaderData("root") as IAuthData;
  const token = loaderData.token;
  const { t } = useTranslation();

  const [audioOptions, setAudioOptions] = useState<IAudioOptions[]>([]);
  const [requestContent, setRequestContent] = useState("");

  const { classes } = useStyles();
  const [requestName, setRequestName] = useState("");
  const [audioOption, setAudioOption] = useState(-1);
  const [requestId, setRequestId] = useState(-1);
  const [timeStarted, setTimeStarted] = useState(false);
  const [audioGeneratorData, setAudioGeneratorData] = useState([]);
  const [usedContent, setUsedContent] = useState("");

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarColor, setSnackbarColor] = useState<SnackbarColor>("");

  const [showHistory, setShowHistory] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const payload = {
          token: token,
        };

        const body = JSON.stringify(payload);
        const audioOptionResponse = await fetch(
          apiBasePath + `/api/get_audio_options`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: body,
          }
        );
        const audioOptionData = await audioOptionResponse.json();
        //console.log(audioOptionData);
        setAudioOptions(audioOptionData);
      } catch (error) {
        console.log("Error fetching options:", error);
      }
    };

    fetchData();
  }, [apiBasePath, token]);

  useEffect(() => {
    for (let i = 0; i < audioOptions.length; i++) {
      setAudioOption(audioOptions[i].id);
      break;
    }
  }, [audioOptions]);

  useEffect(() => {
    fetchAudioGeneratorData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchAudioGeneratorData = async () => {
    try {
      const payload = {
        token: token,
      };

      const body = JSON.stringify(payload);
      const response = await fetch(apiBasePath + `/api/get_audio_of_user`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: body,
      });
      const data = await response.json();

      // console.log(data);

      setAudioGeneratorData(data);
    } catch (error) {
      console.log("Error fetching audio generator requests:", error);
    }
  };

  const showSnackbar = (message : string, color : SnackbarColor) => {
    setSnackbarMessage(message);
    setSnackbarColor(color);
    setSnackbarOpen(true);
  };

  useInterval(() => {
    if (timeStarted) {
      checkAudioRequestStatus();
    }
  }, 2000);

  const checkAudioRequestStatus = async () => {
    const payload = {
      requestId: requestId,
      token: token,
    };

    const body = JSON.stringify(payload);

    try {
      const response = await fetch(
        apiBasePath + "/api/check_audio/" + requestId,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: body,
        }
      );

      if (response.ok) {
        const data = await response.json();
        console.log(data);
        const finished = data.finished;
        const errorText = data.errorText;
        const status = data.status;

        if (status === "processing" || status === "queued") {
          showSnackbar(t("audioGenerationInProgress"), "success");
          fetchAudioGeneratorData();
          setTimeStarted(true);
          console.log("Time needs to continue");
        } else if (finished && (errorText == null || errorText === "")) {
          showSnackbar(t("audioGenerationFinished"), "success");
          fetchAudioGeneratorData();
          setTimeStarted(false);
          console.log("Time needs to stop");
        } else if (errorText != null && errorText !== "") {
          showSnackbar(t("errorGenerationAudio") + errorText, "error");
          setTimeStarted(false);
          fetchAudioGeneratorData();
          console.log("Time needs to stop");
        }
      } else {
        console.log("Post request failed!");
        fetchAudioGeneratorData();
      }
    } catch (error) {
      console.error("An error occurred while making the post request:", error);
    }
  };

  const handlePostButtonClick = async () => {
    if (!audioOption) {
      showSnackbar(t("selectAudioLengthFirst"), "error");
      return;
    }

    const payload = {
      audioOptionId: audioOption,
      text: requestContent,
      token: token,
      requestName: requestName,
    };

    const body = JSON.stringify(payload);

    try {
      const response = await fetch(apiBasePath + "/api/queue_audio_request", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: body,
      });

      if (response.ok) {
        const data = await response.json();
        const requestId = data.request_id;

        console.log("Request id:", requestId);
        setRequestId(requestId);
        setTimeStarted(true);

        showSnackbar(t("audioGenerationStarted"), "success");
      } else {
        console.log("Post request failed!");
        setTimeStarted(false);
        showSnackbar(t("errorCallingApi"), "error");
      }
    } catch (error) {
      console.error("An error occurred while making the post request:", error);
      showSnackbar(t("errorCallingApi"), "error");
    }
  };

  const handleRequestNameChange = (event : React.ChangeEvent<HTMLTextAreaElement>) => {
    setRequestName(event.target.value);
  };

  const handleTextChange = (event : React.ChangeEvent<HTMLTextAreaElement>) => {
    setRequestContent(event.target.value);
  };

  const handleRowSelection = (item : IAudioRequestItem) => {
    setRequestContent(item.original_content);
    setRequestName(item.name);
    setAudioOption(item.audio_option_id);
    setRequestId(item.id);
    //console.log(item.used_content);
    setUsedContent(item.used_content === null ? "" : item.used_content);
  };

  const openUrlInNewTabWithPost = (url : string, data : IAudioDownloadData) => {
    // Create a form element
    const form = document.createElement("form");
    form.method = "POST";
    form.action = url;
    form.target = "_blank"; // This will open the form submission in a new tab

    // Add the token as a hidden field
    const hiddenField = document.createElement("input");
    hiddenField.type = "hidden";
    hiddenField.name = "token"; // Adjust the 'name' to what your API expects
    hiddenField.value = data.token; // Assuming you pass the token in the 'data' object
    form.appendChild(hiddenField);

    // Append the form to the body
    document.body.appendChild(form);

    // Submit the form
    form.submit();

    // Remove the form from the body
    document.body.removeChild(form);
  };

  // const openUrlInNewTabWithPost = async (url, data) => {
  //   // Create an invisible iframe
  //   const iframe = document.createElement('iframe');
  //   iframe.style.display = 'none';
  //   document.body.appendChild(iframe);

  //   try {
  //     // Send POST request via Fetch API
  //     const response = await fetch(url, {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify(data),
  //     });

  //     const result = await response.json();

  //     if (result && result.url) {
  //       // If the response contains a URL, set the iframe's src to that URL
  //       iframe.src = result.url;
  //     } else {
  //       console.error('No URL received from the server');
  //     }
  //   } catch (error) {
  //     console.error('Error making POST request:', error);
  //   }
  // };

  const handleGridButtonClick = async (item : IAudioRequestItem) => {
    if (!item) {
      showSnackbar("Item is empty, please try again", "error");
      return;
    }

    const url = apiBasePath + `/api/get_audio/${item.id}`;
    const tokenData = { token: token } as IAudioDownloadData; 
    openUrlInNewTabWithPost(url, tokenData);
  };

  const handleNewButtonClick = () => {
    setRequestContent("");
    setRequestName("");
    setRequestId(-1);
    setTimeStarted(false);
    setUsedContent("");
  };

  const handleHistoryButtonClick = () => {
    setShowHistory(!showHistory);
  };

  const optionSelectors = (
    <div className={classes.layoutContainer}>
      <div className={classes.optionSelectorsContainer}>
        <OptionSelector
          title={t("selectAudioLength")}
          options={audioOptions}
          selectedOption={audioOption}
          onOptionClick={setAudioOption}
          colorKey="greenSelect"
        />
      </div>
    </div>
  );

  return (
    <div>
      <Container className={classes.container} maxWidth={false}>
        <Navigation
          apiBasePath={apiBasePath}
          personalizeElement={<></>}
          chatElement={<></>}
          fileElement={<></>}
          audioElement={optionSelectors}
        />
        <div className={classes.layoutContainer}>
          <div className={classes.textTitleBlockContainer}>
            <TextTitleBlock
              showTitle={true}
              title={requestName}
              content={requestContent}
              onContentChange={handleTextChange}
              onTitleChange={handleRequestNameChange}
              titlePlaceHolder={t("audioRequestName")}
              contentPlaceHolder={t("audioContentPlaceholder")}
              aiContent={usedContent}
              onAIContentChange={() => {}}
              showApproxAudioLength={true}
              showAiContent={usedContent !== undefined && usedContent !== ""}
              isTyping={false}
            />
          </div>
          <div className={classes.bottomContainer}>
            <div className={classes.buttonDiv}>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={handlePostButtonClick}
              >
                {t("generateAudio")}
              </Button>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={handleNewButtonClick}
              >
                {t("new")}
              </Button>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={handleHistoryButtonClick}
              >
                {t("history")}
              </Button>
            </div>
            {showHistory ? (
              <AudioGeneratedDataGrid
                audioGeneratorData={audioGeneratorData}
                handleRowSelection={handleRowSelection}
                handleButtonClick={handleGridButtonClick}
              />
            ) : null}
            <Snackbar
              open={snackbarOpen}
              autoHideDuration={5000}
              onClose={() => setSnackbarOpen(false)}
              message={snackbarMessage}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              ContentProps={{
                style: {
                  backgroundColor:
                    snackbarColor === "success" ? "green" : "red",
                },
              }}
            />
          </div>
        </div>
      </Container>
    </div>
  );
}

export default AudioPage;
