import React, { useEffect, useState } from "react";
import { 
  Container, 
  Snackbar,
  IconButton,
  Button,
  Checkbox,
  FormControlLabel
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { makeStyles } from "tss-react/mui";
import OptionSelector from "../../navigation/OptionSelector";
import { useTranslation } from "react-i18next";
import { useRouteLoaderData, useLocation, useNavigate  } from "react-router-dom";
import SideBar from "../../navigation/SideBar";
import useApi from "../../../utils/useApi";
import { type IApiBasePathProps, type IAuthData, type IGptOptions, type SnackbarColor, type IPage } from "../../../utils/interfaces";
import ExpandButton from "../../navigation/ExpandButton";
import { storeUserDataEncrypted } from "../../../utils/auth";

const useStyles = makeStyles()((theme) => ({
  container: {
    padding: theme.spacing(4),
    //maxWidth: "1200px",
    margin: "auto", // Centers the container
    overflowX: 'auto', // Allows horizontal scrolling if necessary
    overflowY: 'hidden' // Prevents vertical scrolling
  },
  configContainer: {
    width: "100%",
    flex: 1,
    display: "flex",
    flexDirection: "row",
  },
  content: {
    padding: theme.spacing(4),
    width: "100%",
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  iconButton: {
    padding: 0,
    width: "40px",
    height: "40px",
  },
  button: {
    textTransform: "none",
    marginTop: theme.spacing(0),
    backgroundColor: theme.palette.blue.main,
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(2),
    gap: theme.spacing(2),
    alignItems: "center",
  },
  layoutContainer: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap", // Allow the containers to wrap
    justifyContent: "space-between",
    marginBottom: theme.spacing(0), // Space before the bottomContainer
  },

  optionSelectorsContainer: {
    display: "flex",
    flexDirection: "row", // Change to row for horizontal alignment
    flexWrap: "nowrap", // Keeps items in a row but allows them to wrap to a new line if needed
    justifyContent: "center", // Adjust this as needed for alignment (e.g., 'flex-start', 'space-between')
    alignItems: "flex-start",
    flexBasis: "100%", // Adjust or remove this as needed
    [theme.breakpoints.down("lg")]: {
      flexDirection: "column", // Stack vertically on smaller screens
      flexBasis: "100%",
      flexWrap: "wrap",
    },

    margin: theme.spacing(0), // Adds some margin around the container
    // ... any other styling you need ...
  },
  bottomContainer: {
    flexBasis: "100%", // This ensures it's always full width
    marginTop: theme.spacing(0),
  },

  disclaimerOpenAI: {
    textAlign: "center",
    color: theme.palette.red.main,
    fontSize: "10px",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    width: "90%",
    [theme.breakpoints.down("md")]: {
      paddingRight: theme.spacing(1),
      paddingLeft: theme.spacing(1),
    },
  },
  checkbox: {
    marginLeft: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      marginLeft: theme.spacing(1),
    },
  },
}));

function PersonalizePage({apiBasePath} : IApiBasePathProps ) {
  const customFetch = useApi();
  const navigate = useNavigate();
  const loaderData = useRouteLoaderData("root") as IAuthData;
  const token = loaderData.token;
  const selectedUser = loaderData.userData;
  const { t } = useTranslation();
  const { classes } = useStyles();
  const [gptOptions, setGptOptions] = useState<IGptOptions[]>([]);
  const [gpt, setGpt] = useState(-1);
  const [gptName, setGptName] = useState("");
  const [gptIsGRDPCompliant, setGptIsGRDPCompliant] = useState(false);
  const [isSideBarOpen, setIsSideBarOpen] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarColor, setSnackbarColor] = useState<SnackbarColor>(""); // 'success' or 'error'
  const location = useLocation(); 
  const [showGptSelector, setShowGptSelector] = useState(selectedUser.showGptSelector);
  const [useStreaming, setUseStreaming] = useState(selectedUser.useStreaming);
  const isAdmin = selectedUser.roleId === 1;

  let pages : IPage[] = [
    { text : t("promtConfig"), route: "/configuration" }, 
    { text: t("gptConfig"), route: "/gptConfig" },
    
  ];
  if (isAdmin) {
    pages.push({text: t("gptConfigForAllUsers"), route: "/gptConfigForAllUsers"});
    pages.push({ text: t("promptConfigForAllUsers"), route: "/promptConfigForAllUsers" });
  }

  useEffect(() => {
    const fetchData = async () => {
      const payload = { token: token };
      const body = JSON.stringify(payload);
      const options = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: body,
      };

      try {
        const gptData = await customFetch(
          apiBasePath + `/api/get_gpt_models`,
          options
        );
        if (gptData) {
          setGptOptions(gptData);
        }
      } catch (error) {
        console.log("Error fetching gpt models:", error);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiBasePath, token]);

  useEffect(() => {
    for (let i = 0; i < gptOptions.length; i++) {
      setGpt(gptOptions[i].id);
      setGptName(gptOptions[i].name);
      setGptIsGRDPCompliant(gptOptions[i].isGRDPCompliant);
      break;
    }
  }, [gptOptions]);

  useEffect(() => {
    if (gptOptions.length > 0) {
        for (let i = 0; i < gptOptions.length; i++) {
          if (gptOptions[i].id === gpt) {
            setGptName(gptOptions[i].name);
            setGptIsGRDPCompliant(gptOptions[i].isGRDPCompliant);
            break;
          }
        }
    }
  }, [gpt, gptOptions]);

  const showSnackbar = (message : string, color : SnackbarColor) => {
    setSnackbarMessage(message);
    setSnackbarColor(color);
    setSnackbarOpen(true);
  };

  const isRouteActive = (routePath : string) => {
    return location.pathname === routePath;
  };

  const handleDrawerOpen = () => {
    setIsSideBarOpen(true);
  };

  const handleDrawerClose = () => {
    setIsSideBarOpen(false);
  };

  const saveSettings = async () => {
    if (selectedUser) {
      try {
        const payload = {
          token: token,
          userId: selectedUser.id,
          showGptSelector: showGptSelector,
          gptVersionId: gpt,
          useStreaming: useStreaming
        };
        const body = JSON.stringify(payload);
        const fetchOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: body,
        };

        const response = await customFetch(
          apiBasePath + "/api/save_user_settings",
          fetchOptions
        );
        if (response.user) {
          console.log("User settings saved:", response.user);
          storeUserDataEncrypted(response.user);
          navigate(0);
        }

        showSnackbar(t("gptVersionAssigned"), "success");

      } catch (error) {
        console.error("Error assigning GPT version to user:", error);
        showSnackbar(t("gptVersionNotAssigned"), "error");
      }
    }
  }

  const handleCheckBoxChange = (checked: boolean) => {
    setShowGptSelector(checked);
  }

  const handleStreamingCheckBoxChange = (checked: boolean) => {
    setUseStreaming(checked);
  }

  const optionSelectors = (
    <div className={classes.optionSelectorsContainer}>
        <OptionSelector
          title={t("gptSelect")}
          options={gptOptions}
          selectedOption={gpt}
          onOptionClick={setGpt}
          colorKey="violetSelect"
          hoverDisplay={false}
        />
    </div>
  );

  return (
    <Container className={classes.container} maxWidth={false}>
      <div className={classes.layoutContainer}>
        { isSideBarOpen === false ? 
          <div className={classes.iconButton}>
              <IconButton 
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
            >
              <MenuIcon />
            </IconButton> 
          </div>
            : null
        }
        <div className={classes.configContainer}>
          <SideBar pages={pages} isOpen={isSideBarOpen} onClose={handleDrawerClose} />
          <div className={classes.content}>
            <ExpandButton
              isExpanded={isRouteActive("/gptConfig")}
              icon={
                <></>
              }
              text={t("personalization")}
              expandComponent={<div>{optionSelectors}</div>}
              redirectTo={"/gptConfig"}
              colorKey={"violet"}
              
            />
            <div className={classes.disclaimerOpenAI}>
              {
                  (!gptIsGRDPCompliant) ? t("disclaimerOpenAI"): ""
              }
            </div> 
            <div className={classes.checkbox}>
              <FormControlLabel
                label={t('showGptSelectorOnToolsPage')}
                control={<Checkbox checked={showGptSelector} onChange={(e,checked) => handleCheckBoxChange(checked)} />}
              />
            </div>
            {
              
              <div className={classes.checkbox}>
                <FormControlLabel
                  label={t('streamChatAnswers')}
                  control={<Checkbox checked={useStreaming} onChange={(e,checked) => handleStreamingCheckBoxChange(checked)} />}
                />
              </div>
              
            } 
            <div className={classes.buttonContainer}>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={saveSettings}
              >
                {t("save")}
              </Button>
            </div>
            <Snackbar
              open={snackbarOpen}
              autoHideDuration={5000}
              onClose={() => setSnackbarOpen(false)}
              message={snackbarMessage}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              ContentProps={{
                style: {
                  backgroundColor:
                    snackbarColor === "success" ? "green" : "red",
                },
              }}
            />
          </div>
        </div>
      </div>
    </Container>
  );
}

export default PersonalizePage;
