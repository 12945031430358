import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "tss-react/mui";
import {
  Avatar,
  Button,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import logo from "../../images/logo.png";
import settings from "../../images/settings.png";
import profileIcon from "../../images/profile.png";
import ReactCountryFlag from "react-country-flag"
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { useNavigate, useSubmit } from "react-router-dom";

const useStyles = makeStyles()((theme) => ({
  navbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(1), // Add a little padding for good measure
    [theme.breakpoints.down("sm")]: {
      justifyContent: "flex-start", // Change to flex-start to align items to the left on smaller screens
    },
  },
  logo: {
    height: "60px",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      height: "50px", // Reduce logo size on smaller screens
    },
  },
  headerContainer: {
    display: "flex",
    [theme.breakpoints.down("md")]: {
      display: "none", // Hide on medium and smaller screens
    },
    alignItems: "center",
    flex: 1, // This will allow the header container to grow
    marginRight: theme.spacing(2), // This adds a gap between the header and the buttons
  },
  header: {
    color: theme.palette.blue.main,
    textAlign: "left",
    marginLeft: theme.spacing(1), // Add a bit of margin to the left of the header for spacing
  },
  buttonGroup: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    alignItems: "center",
  },
  languageButtons: {
    display: "flex",
    flexDirection: "column", // Stack language buttons vertically
    marginLeft: theme.spacing(0), // Space from the other button group
  },
  button: {
    padding: theme.spacing(1, 2),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(0.5),
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.gray.hover,
    },
    fontSize: "1.5rem",
    color: theme.palette.gray.main,
  },
  avatarButton2: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(0),
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.gray.hover,
    },
    fontSize: "1.5rem",
    color: theme.palette.gray.main,
    [theme.breakpoints.down("md")]: {
      // needs to be aligned on the right...
      marginLeft: "auto",
    },
  },
  settings: {
    height: "37px",
  },
  profile: {
    height: "50px",
  },
  flagIcon: {
    width: "20px",
    height: "auto",
  },
  country: {
    marginLeft: theme.spacing(1),
  },
  languageButton: {
    minWidth: "auto", // Minimize button width
    padding: theme.spacing(0.5), // Reduced padding
    marginLeft: theme.spacing(1), // Space between language buttons
    color: theme.palette.gray.main,
  },
  menuBox: {
    flexGrow: 1,
    display: "flex", // Default
    [theme.breakpoints.up("sm")]: {
      display: "none", // Hide on medium and larger screens
    },
  },
  menuPaper: {
    display: "none",
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
  },
  avatarBox: {
    flexGrow: 0,
  },
  avatarButton: {
    p: 0,
  },
}));

interface INavigationBarProps {
  name: string | null;
  photoUrl: string | undefined;
};

const NavigationBar = ({ name, photoUrl } : INavigationBarProps) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const navigate = useNavigate();
  const submit = useSubmit();

  const pages = [t("tools"), t("help"), t("settings")];
  const pages2 = [t("tools"), t("help")];
  var profileMenuItems = [t("signin"), t("profile"), t("logout")];
  if (name === null) {
    profileMenuItems = [t("signin")];
  } else {
    profileMenuItems = [t("profile"), t("logout")];
  }

  var headerText = name + t("personalAIHub");
  if (name === null) {
    headerText = "";
  }

  const [anchorElNav, setAnchorElNav] = React.useState<HTMLElement | null>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<HTMLElement | null>(null);

  const profilePic = photoUrl ? photoUrl : profileIcon;

  const handleOpenNavMenu =  (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu =  (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleProfileItemClick =  (event: React.MouseEvent<HTMLLIElement>) => {
    const page = event.currentTarget.textContent;

    switch (page) {
      case t("signin"):
        navigate("/auth?mode=login");
        break;

      case t("profile"):
        navigate("/profile");
        break;

      case t("logout"):

        submit(null, { action: "/logout", method: "post" });
        break;
      default:
        break;
    }
    handleCloseUserMenu();
  };

  const handleNavIconClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const page = event.currentTarget.textContent;
    handleNavigation(page);
  };

  const handleMenuItemClick = (event: React.MouseEvent<HTMLLIElement>) => {
    const page = event.currentTarget.textContent;
    handleNavigation(page);
  };

  function handleNavigation(page: string | null) {
    switch (page) {
      case t("tools"):
        navigate("/personalize");
        break;
  
      case t("templates"):
        navigate("/templates");
        break;
  
      case t("help"):
        navigate("/faq");
        break;
  
      case t("settings"):
        navigate("/configuration");
        break;
  
      case t("dataprotection"):
        navigate("/dataprotection");
        break;
  
      case t("imprint"):
        navigate("/imprint");
        break;
  
      default:
        navigate("/");
    }
    handleCloseNavMenu();
  }

  const handleLanguageChange = (languageCode : string) => {
    i18next.changeLanguage(languageCode);
  };

  const onSettingsClick = () => {
    navigate("/configuration");
  };

  return (
    <nav className={classes.navbar}>
      <img
        src={logo}
        alt="Logo"
        className={classes.logo}
        onClick={() => navigate("/personalize")}
      />
      <div className={classes.headerContainer}>
        <h1 className={classes.header}>{headerText}</h1>
      </div>
      <Box className={classes.menuBox}>
        <IconButton
          size="large"
          aria-label="menu appbar"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleOpenNavMenu}
          color="inherit"
        >
          <MenuIcon />
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={anchorElNav}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          open={Boolean(anchorElNav)}
          onClose={handleCloseNavMenu}
          className={classes.menuPaper}
        >
          {pages.map((page) => (
            <MenuItem key={page} onClick={(e) => handleMenuItemClick(e)}>
              <Typography textAlign="center">{page}</Typography>
            </MenuItem>
          ))}
        </Menu>
      </Box>
      <div className={classes.buttonGroup}>
        {pages2.map((page) => (
          <div
            key={page}
            className={classes.button}
            onClick={(e) => handleNavIconClick(e)}
          >
            {page}
          </div>
        ))}
        <div className={classes.button} onClick={onSettingsClick}>
          <img src={settings} alt="Settings" className={classes.settings} />
        </div>
      </div>
      <Box className={classes.avatarBox}>
        <IconButton
          onClick={handleOpenUserMenu}
          className={classes.avatarButton}
        >
          <Avatar alt="Someone" src={profilePic} />
        </IconButton>
        <Menu
          
          id="menu-appbar"
          anchorEl={anchorElUser}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorElUser)}
          onClose={handleCloseUserMenu}
        >
          {profileMenuItems.map((item) => (
            <MenuItem key={item} onClick={(e) => handleProfileItemClick(e)}>
              <Typography textAlign="center">{item}</Typography>
            </MenuItem>
          ))}
        </Menu>
      </Box>

      <div className={classes.languageButtons}>
        <Button
          className={classes.languageButton}
          onClick={() => handleLanguageChange("en")}
        >
          <ReactCountryFlag countryCode="us" />
          <div className={classes.country}>EN</div>
        </Button>
        <Button
          className={classes.languageButton}
          onClick={() => handleLanguageChange("de")}
        >
          <ReactCountryFlag countryCode="de" />
          <div className={classes.country}>DE</div>
        </Button>
      </div>
    </nav>
  );
};

NavigationBar.propTypes = {
  name: PropTypes.string,
};

export default NavigationBar;


