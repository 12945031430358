import React from "react";
import { Navigate, useLocation, useRouteLoaderData } from "react-router-dom";
import { type IAuthData } from "../../utils/interfaces";

interface IProtectedRouteProps {
  children: React.ReactNode;
}

const ProtectedRoute = ({ children } : IProtectedRouteProps) => {
  const loaderData = useRouteLoaderData("root") as IAuthData;
  const location = useLocation();
  const token = loaderData.token;
  const userData = loaderData.userData;

  if (!token) {
    return <Navigate to="/home" replace state={{ from: location }} />;
  }

  if (location.pathname === "/payment" || location.pathname === "/profile") {
    return children;
  }

  if (userData.isValidSubscription !== "valid") {
    return <Navigate to="/payment" replace state={{ from: location }} />;
  }

  return children;
};

export { ProtectedRoute };
