import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import TypingIndicator from "../chat/TypingIndicator";
import { type ITextWithCounterProps } from "../../../utils/interfaces";

// Define your component styles using makeStyles
interface StyleProps {
  borderColorKey: string;
}

const useStyles = makeStyles<StyleProps>()((theme, { borderColorKey }) => ({
  textArea: {
    width: "90%",
    height: "300px",
    padding: theme.spacing(1),
    fontSize: "16px",
    border: `1px solid ${(theme.palette as any)[borderColorKey].hover}`,
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(0),
    marginLeft: theme.spacing(0),
    marginRight: theme.spacing(0),
    resize: "vertical",
    display: "block", // Makes sure the textarea is treated as a block element
  },
  textWithCounter: {
    flexBasis: "50%", // This ensures it's always full width
  },
  counter: {
    fontSize: "10px",
    marginTop: 0,
    position: "absolute", // Positions the counter in relation to the textAreaContainer
    bottom: "-20px", // Adjust this value to place it right below the textarea
    left: 0,
    width: "100%", // This makes sure the counter spans the entire width of the textarea
    marginLeft: theme.spacing(0.5),
  },
  textAreaContainer: {
    position: "relative", // This should keep the counter within the container
    marginBottom: "30px", // Add enough space to accommodate the counter
    width: "90%",
    minWidth: "400px",
    [theme.breakpoints.down("md")]: {
      width: "90vw", // 100% of the viewport width on small screens
      minWidth: "150px",
     // maxWidth: "80vw",
    },
  },
  title: {
    fontSize: "20px",
    fontWeight: "bold",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(0),
    color: (theme.palette as any)[borderColorKey].main,
  },
  audioLength: {
    fontSize: "10px",
    marginLeft: theme.spacing(5),
  },
}));

TextWithCounter.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  contentPlaceHolder: PropTypes.string,
  onContentChange: PropTypes.func.isRequired,
  showApproxAudioLength: PropTypes.bool,
  borderColor: PropTypes.string.isRequired,
  isTyping: PropTypes.bool,
};

function TextWithCounter({
  title,
  content,
  contentPlaceHolder,
  onContentChange,
  showApproxAudioLength,
  borderColor,
  isTyping,
} : ITextWithCounterProps) {
  const { classes } = useStyles({ borderColorKey: borderColor });
  const { t } = useTranslation();
  const [contentWordCount, setContentWordCount] = useState(0);

  useEffect(() => {
    setContentWordCount(wordCount(content));
  }, [content]);

  const wordCount = (str : string) => {
    if (str === undefined || str === null) {
      return 0;
    }
    return str.trim().split(/\s+/).filter(Boolean).length;
  };

  const calculateApproximateAudioLength = (wordCount : number) => {
    return Math.round(wordCount / 110);
  };

  return (
    <div>
      <div className={classes.title}>{title}</div>
      <div className={classes.textAreaContainer}>
        {isTyping ? (
          <div className={classes.textArea}>
            <TypingIndicator />
          </div>
        ) : (
          <>
            <textarea
              value={content}
              onInput={onContentChange}
              className={classes.textArea}
              placeholder={contentPlaceHolder}
            />
            <div className={classes.counter}>
              {t("wordCount")}: {contentWordCount}{" "}
              {showApproxAudioLength ? (
                <span className={classes.audioLength}>
                  {t("approxAudioLength")}:{" "}
                  {calculateApproximateAudioLength(contentWordCount)}{" "}
                  {t("minutes")}
                </span>
              ) : null}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default TextWithCounter;
