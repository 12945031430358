import { ThemeProvider } from "@mui/material";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import ChatPage from "./components/pages/chat/ChatPage";
import PromptConfigPage from "./components/pages/config/PromptConfigPage";
import PromptConfigForAllUsers from "./components/pages/config/PromptConfigForAllUsers";
import PersonalizePage from "./components/pages/personalize/PersonalizePage";
import AudioPage from "./components/pages/audio/AudioPage";
import FAQPage from "./components/pages/other/FAQPage";
import DataProtectionPage from "./components/pages/other/DataProtectionPage";
import ImprintPage from "./components/pages/other/ImprintPage";
import HomePage from "./components/pages/other/HomePage";
import {
  default as AuthPage,
  action as authAction,
} from "./components/pages/login/AuthPage";
import { ProtectedRoute } from "./components/routing/ProtectedRoute";
import { tokenLoader } from "./utils/auth";
import RootLayout from "./components/routing/RootLayout";
import ErrorPage from "./components/routing/ErrorPage";
import { action as logoutAction } from "./components/routing/Logout";
import { CheckLoginRequired } from "./components/routing/CheckLoginRequired";
import { action as googleSignInAction } from "./components/pages/login/SignInWithGoogle";
import PaymentPage from "./components/pages/profile/PaymentPage";
import ProfilePage from "./components/pages/profile/ProfilePage";
import PasswordResetPage, {
  action as passwordResetAction,
} from "./components/pages/login/PasswordResetPage";
import ChangePasswordPage, {
  action as changePasswordAction,
} from "./components/pages/login/ChangePasswordPage";
import VerifyEmailPage from "./components/pages/login/VerifyEmailPage";
import FAQPageStatic from "./components/pages/other/FAQPageStatic";
import ConditionsPage from "./components/pages/other/ConditionsPage";
import { apiBasePath } from "./utils/constants";
import { default as theme } from "./utils/theme";
import GptConfigForAllUsers from "./components/pages/config/GptConfigForAllUsers";
import GptConfig from "./components/pages/config/GptConfig";
import FileUploadPage from "./components/pages/files/FileUploadPage"
import FileSelectPage from "./components/pages/files/FileSelectPage";
//import FileChatPage from "./components/pages/files/FileChatPage";

const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    errorElement: <ErrorPage />,
    id: "root",
    loader: tokenLoader,
    children: [
      { index: true, element: <HomePage />, id: "home" },
      { path: "home", element: <HomePage />, id: "home2" },
      {
        path: "auth",
        element: (
          <CheckLoginRequired>
            <AuthPage />
          </CheckLoginRequired>
        ),
        id: "auth",
        action: authAction,
      },
      {
        path: "signinwithgoogle",
        action: googleSignInAction,
        id: "signinwithgoogle",
      },
      {
        path: "personalize",
        element: (
          <ProtectedRoute>
            <PersonalizePage apiBasePath={apiBasePath} />
          </ProtectedRoute>
        ),
        id: "personalize",
      },
      {
        path: "chat",
        element: (
          <ProtectedRoute>
            <ChatPage apiBasePath={apiBasePath} />
          </ProtectedRoute>
        ),
        id: "chat",
      },
      {
        path: "configuration",
        element: (
          <ProtectedRoute>
            <PromptConfigPage apiBasePath={apiBasePath} />
          </ProtectedRoute>
        ),
        id: "configuration",
      },
      {
        path: "promptConfigForAllUsers",
        element: (
          <ProtectedRoute>
            <PromptConfigForAllUsers apiBasePath={apiBasePath} />
          </ProtectedRoute>
        ),
        id: "promptConfigForAllUsers",
      },
      {
        path: "audio",
        element: (
          <ProtectedRoute>
            <AudioPage apiBasePath={apiBasePath} />
          </ProtectedRoute>
        ),
        id: "audio",
      },
      {
        path: "payment",
        element: (
          <ProtectedRoute>
            <PaymentPage apiBasePath={apiBasePath} />
          </ProtectedRoute>
        ),
        id: "payment",
      },
      {
        path: "profile",
        element: (
          <ProtectedRoute>
            <ProfilePage apiBasePath={apiBasePath} />
          </ProtectedRoute>
        ),
        id: "profile",
      },
      {
        path: "gptConfigForAllUsers",
        element: (
          <ProtectedRoute>
            <GptConfigForAllUsers apiBasePath={apiBasePath} />
          </ProtectedRoute>
        ),
        id: "gptConfigForAllUsers",
      },
      {
        path: "gptConfig",
        element: (
          <ProtectedRoute>
            <GptConfig apiBasePath={apiBasePath} />
          </ProtectedRoute>
        ),
        id: "gptConfig",
      },
      {
        path: "fileUpload",
        element: (
          <ProtectedRoute>
            <FileUploadPage apiBasePath={apiBasePath} />
          </ProtectedRoute>
        ),
        id: "fileUpload",
      },
      {
        path: "fileSelect",
        element: (
          <ProtectedRoute>
            <FileSelectPage apiBasePath={apiBasePath} />
          </ProtectedRoute>
        ),
        id: "fileSelect",
      },
      {
        path: "dataprotection",
        element: <DataProtectionPage />,
        id: "dataprotection",
      },
      { path: "imprint", element: <ImprintPage />, id: "imprint" },
      {
        path: "faq2",
        element: <FAQPage apiBasePath={apiBasePath} />,
        id: "faq2",
      },
      {
        path: "faq",
        element: <FAQPageStatic />,
        id: "faq",
      },
      { path: "logout", action: logoutAction, id: "logout" },
      {
        path: "passwordForgotten",
        element: <PasswordResetPage />,
        action: passwordResetAction,
        id: "passwordForgotten",
      },
      {
        path: "changePassword",
        element: <ChangePasswordPage />,
        action: changePasswordAction,
        id: "changePassword",
      },
      { path: "verifyEmail", element: <VerifyEmailPage />, id: "verifyEmail" },
      { path: "conditions", element: <ConditionsPage />, id: "conditions" },
      //{ path: '*', element: <ErrorPage />, id: 'noMatch' },
    ],
  },
]);

function App() {

  return (
    <ThemeProvider theme={theme}>
      <RouterProvider router={router}></RouterProvider>
    </ThemeProvider>
  );
}

export default App;
