import React from "react";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    maxWidth: "960px",
    marginLeft: "auto", // Center the container
    marginRight: "auto", // Center the container
        //width: "100%", 
        whiteSpace: "pre-wrap",
        wordWrap: "break-word",
        wordBreak: "break-word",
  },
}));

function ConditionsPage() {
  const { classes } = useStyles();
  return (
    <div className={classes.container}>
      <h1>Allgemeine Geschäftsbedingungen (AGB) von KI24.online</h1>

      <h2>1. Geltungsbereich und Begriffsbestimmungen</h2>

      <p>
        1.1 Diese Allgemeinen Geschäftsbedingungen (nachfolgend „AGB“) regeln
        das Vertragsverhältnis zwischen der nudge GmbH, dem Betreiber von
        KI24.online, Grindelberg 15a, 20144 Hamburg (nachfolgend „Anbieter“) und
        den Nutzern (nachfolgend „Kunden“) der Plattform KI24.online, auf
        welcher KI-basierte Sprachmodelle zum Bearbeiten und Generieren von
        neuen Inhalten genutzt werden können.
      </p>

      <p>
        1.2. KI24.online stellt eine Online-Plattform bereit, die den Kunden
        Zugang zu KI-Sprachmodellen ermöglicht, welche über Microsoft Azure
        betrieben und nach den Grundsätzen der Datenschutz-Grundverordnung
        (DSGVO) konform sind. Weitere Informationen können unter folgendem Link
        abgerufen werden.
      </p>

      <p>
        <a
          href="https://www.microsoft.com/licensing/terms/productoffering/MicrosoftAzure/MCA#ServiceSpecificTerms"
          target="_blank"
          rel="noreferrer"
        >
          https://www.microsoft.com/licensing/terms/productoffering/MicrosoftAzure/MCA#ServiceSpecificTerms
        </a>
      </p>

      <p>
        1.3. Nutzer der Plattform können sowohl Verbraucher als auch Unternehmer
        sein. Verbraucher im Sinne der AGB ist jede natürliche Person, die ein
        Rechtsgeschäft zu einem Zweck abschließt, der überwiegend weder ihrer
        gewerblichen noch ihrer selbstständigen beruflichen Tätigkeit
        zugerechnet werden kann. Unternehmer ist jede natürliche oder
        juristische Person oder rechtsfähige Personengesellschaft, die bei
        Abschluss eines Rechtsgeschäfts in Ausübung ihrer selbstständigen
        beruflichen oder gewerblichen Tätigkeit handelt.
      </p>

      <h2>2. Vertragsschluss</h2>

      <p>
        2.1. Der Vertrag über die Nutzung der Plattform KI24.online kommt durch
        die Registrierung des Kunden und die Annahme der Registrierung durch den
        Anbieter zustande.
      </p>

      <p>
        2.2. Der Kunde verpflichtet sich, bei der Registrierung wahrheitsgemäße
        Angaben zu machen. Änderungen seiner Daten hat der Kunde unverzüglich
        dem Anbieter mitzuteilen.
      </p>

      <h2>3. Leistungen des Anbieters</h2>

      <p>
        3.1. Der Anbieter ermöglicht dem Kunden den Zugriff auf KI-Sprachmodelle
        über die Plattform KI24.online.
      </p>

      <p>
        3.2. Der Anbieter gewährleistet eine Verfügbarkeit der Plattform von 98%
        im Jahresmittel. Hiervon ausgenommen sind Zeiten, in denen die Server
        aufgrund von technischen oder sonstigen Problemen, die nicht im
        Einflussbereich des Anbieters liegen (höhere Gewalt, Verschulden
        Dritter, etc.), über das Internet nicht zu erreichen sind.
      </p>

      <h2>4. Pflichten des Kunden</h2>

      <p>
        4.1. Der Kunde verpflichtet sich, die Dienste der Plattform KI24.online
        nicht zu missbräuchlichen Zwecken zu verwenden und alle Handlungen zu
        unterlassen, die zu einer Beeinträchtigung der Funktionalität der
        Plattform führen können.
      </p>

      <p>
        4.2. Der Kunde ist für die Geheimhaltung der Zugangsdaten verantwortlich
        und haftet für sämtliche Aktivitäten, die unter Verwendung seiner
        Zugangsdaten vorgenommen werden.
      </p>

      <p>
        4.3. Die Nutzung ist personengebunden. Nutzerdaten dürfen nicht an
        Dritte weitergegeben werden.
      </p>

      <h2>5. Vergütung</h2>

      <p>
        5.1. Die Nutzung der Plattform KI24.online ist kostenpflichtig. Die
        jeweiligen Preise werden dem Kunden im Rahmen des Bestellvorgangs
        mitgeteilt.
      </p>

      <p>
        5.2. Die Abrechnung der Leistungen erfolgt über den
        Zahlungsdienstleister Stripe. Der Kunde stimmt zu, dass seine
        Zahlungsdaten zu diesem Zweck an Stripe übermittelt werden. Es gelten
        die Datenschutzerklärung (
        <a
          href="https://stripe.com/de/privacy"
          target="_blank"
          rel="noreferrer"
        >
          https://stripe.com/de/privacy
        </a>
        )und die AGB (
        <a
          href="https://stripe.com/de/legal/consumer"
          target="_blank"
          rel="noreferrer"
        >
          https://stripe.com/de/legal/consumer
        </a>
        ) von Stripe.
      </p>

      <h2>6. Datenschutz</h2>

      <p>
        6.1. Der Anbieter verarbeitet personenbezogene Daten des Kunden zur
        Vertragserfüllung. Alle Daten werden DSGVO-konform behandelt.
      </p>

      <p>
        6.2. Der Kunde hat das Recht auf Auskunft, Berichtigung, Löschung und
        Einschränkung der Verarbeitung seiner Daten.
      </p>

      <h2>7. Haftung</h2>

      <p>
        7.1 Der Anbieter stellt auf der Plattform KI24.online KI-Sprachmodelle
        von OpenAI zur Verfügung, die über Microsoft Azure betrieben werden. Der Anbieter
        übernimmt keine Gewähr für die Genauigkeit, Vollständigkeit,
        Zuverlässigkeit oder Aktualität der durch die KI-Modelle generierten
        Inhalte oder Ergebnisse.
      </p>

      <p>
        7.2 Die Nutzung der auf KI24.online bereitgestellten Inhalte und Daten
        erfolgt auf eigenes Risiko des Nutzers. Der Anbieter haftet nicht für
        direkte oder indirekte Schäden, die aus der Nutzung oder Unmöglichkeit
        der Nutzung der KI-generierten Inhalte oder Daten entstehen, es sei
        denn, solche Schäden sind auf Vorsatz oder grobe Fahrlässigkeit dem Anbieter zurückzuführen.
      </p>

      <p>7.3 Haftungsbeschränkung</p>

      <p>
        7.3.1 Die Haftung des Anbieters für leichte Fahrlässigkeit ist, soweit
        gesetzlich zulässig, ausgeschlossen. Dies gilt insbesondere für
        Datenverlust oder Schäden an Soft- oder Hardware des Nutzers, die durch
        die Nutzung der Plattform KI24.online entstehen können.
      </p>

      <p>
        7.3.2 Der Anbieter haftet nicht für die Verfügbarkeit der
        KI-Sprachmodelle und für Ausfallzeiten der Plattform KI24.online, die
        außerhalb ihres Einflussbereiches liegen, wie z.B. technische Probleme
        des Internetproviders, höhere Gewalt oder ähnliche Ereignisse.
      </p>

      <p>7.4 Externe Links</p>

      <p>
        7.4.1 Die Plattform KI24.online kann Links zu externen Websites Dritter
        enthalten, auf deren Inhalte der Anbieter keinen Einfluss hat. Für die
        Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder
        Betreiber der Seiten verantwortlich. Der Anbieter übernimmt für diese
        externen Inhalte keine Haftung.
      </p>

      <p>7.5 Änderungen des Haftungsausschlusses</p>

      <p>
        7.5.1 Der Anbieter behält sich das Recht vor, diesen
        Haftungsausschluss jederzeit zu ändern. Änderungen werden wirksam,
        sobald sie auf der Plattform KI24.online veröffentlicht werden.
      </p>

      <h2>8. Änderung der AGB</h2>

      <p>8.1. Der Anbieter behält sich vor, diese AGB jederzeit zu ändern.</p>

      <p>
        8.2. Über Änderungen der AGB wird der Kunde per E-Mail informiert. Die
        Änderungen gelten als angenommen, wenn der Kunde nicht binnen vier
        Wochen widerspricht.
      </p>

      <h2>9. Schlussbestimmungen</h2>

      <p>
        9.1. Es gilt das Recht der Bundesrepublik Deutschland. Ausschließlicher
        Gerichtsstand ist Hamburg.
      </p>

      <p>
        9.2. Sollten einzelne Bestimmungen dieser AGB unwirksam sein oder
        werden, bleibt die Wirksamkeit der übrigen Bestimmungen unberührt.
      </p>

      <p>Stand: 18.01.2024</p>
    </div>
  );
}

export default ConditionsPage;
