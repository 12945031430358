import React from "react";
import {
  Form,
  useNavigation,
  useActionData,
  useNavigate,
} from "react-router-dom";
import { useTranslation } from "react-i18next";
import { makeStyles } from "tss-react/mui";
import { TextField, Button } from "@mui/material";
import { type IChangePasswordActionData } from "../../../utils/interfaces";

const useStyles = makeStyles()((theme) => ({
  form: {
    maxWidth: "600px",
    margin: "2rem auto",
  },
  formContainer: {
    display: "flex",
    flexDirection: "column", // Stacks children vertically
    alignItems: "center", // Aligns children centrally
    // ... other styles ...
  },
  textField: {
    display: "block", // Ensures each TextField is a block-level element
    //maxWidth: '100%',
    margin: "0.5rem auto", // Centers the field and adds vertical margin
    //width: '100%', // Sets width to fill its container
    [theme.breakpoints.down("sm")]: {
      maxWidth: "90%",
    },
  },
  actions: {
    display: "flex",
    gap: "1rem",
    justifyContent: "center",
    alignItems: "center",
  },
  button: {
    textTransform: "none",
    marginTop: theme.spacing(1),
    backgroundColor: theme.palette.blue.main,
    color: "white",
    height: "40px",
  },
  errorMessage: {
    color: "var(--color-red-500)",
    textAlign: "center",
    marginTop: "1rem",
  },
}));

export default function PasswordResetForm() {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const navigation = useNavigation();
  const data = useActionData() as IChangePasswordActionData;
  const navigate = useNavigate();
  const isSubmitting = navigation.state === "submitting";

  //console.log('PasswordResetForm data: ' + data);

  const navBack = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    //submit(null, { action: '/auth?mode=login', method: 'get' });
    navigate("/auth?mode=login");
  };

  return (
    <div>
      <Form
        method="post"
        className={`${classes.form} ${classes.formContainer}`}
      >
        {data && data.errors && (
          <ul>
            {Object.values(data.errors).map((err) => (
              <li key={err}>{err}</li>
            ))}
          </ul>
        )}
        {data && data.message && (
          <p className={classes.errorMessage}>{t(data.message)}</p>
        )}
        <TextField
          className={classes.textField}
          label={t("email")}
          name="email"
          type="email"
          margin="normal"
          fullWidth
        />
        <div className={classes.actions}>
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            type="submit"
            disabled={isSubmitting}
          >
            {isSubmitting ? t("submitting") : t("resetPassword")}
          </Button>
        </div>
      </Form>
      <div className={classes.actions}>
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          type="button"
          onClick={navBack}
        >
          {t("back")}
        </Button>
      </div>
    </div>
  );
}
