import { useEffect, useState } from "react";
import { Outlet, useLoaderData, useSubmit } from "react-router-dom";
import { makeStyles } from "tss-react/mui";
import NavigationBar from "../navigation/NavigationBar";
import Footer from "../navigation/Footer";
import { getTokenDuration } from "../../utils/auth";
import { type IAuthData } from "../../utils/interfaces";

const useStyles = makeStyles()((theme) => ({
  rootLayout: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh", // Full viewport height
    width: "100%",
    [theme.breakpoints.down("md")]: {
      maxWidth: "100%", // 100% of the viewport width on small screens
      width: "100%",
    },
  },

  content: {
    flex: 1, // Takes up all available space
    display: "flex",
    flexDirection: "column",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      maxWidth: "100%", // 100% of the viewport width on small screens
      width: "100%",
    },
  },
  footer: {
    width: "100%",
    [theme.breakpoints.down("md")]: {
      maxWidth: "100%", // 100% of the viewport width on small screens
      width: "100%",
    },
  },
}));

function RootLayout() {
  const { classes } = useStyles();
  const loaderData = useLoaderData() as IAuthData;
  const submit = useSubmit();
  const [localUserName, setLocalUserName] = useState<string | null>(null);
  const [localPhotoURL, setLocalPhotoURL] = useState<string | null>(null);

  useEffect(() => {
    if (!loaderData) {
      return;
    }

    const { token, userName, photoURL } = loaderData;
    if (token === "EXPIRED") {
      submit(null, { action: "/logout", method: "post" });
      return;
    }

    const tokenDuration = getTokenDuration();
    if (tokenDuration > 0) {
      setTimeout(() => {
        submit(null, { action: "/logout", method: "post" });
      }, tokenDuration);
    }

    if (userName) {
      setLocalUserName(userName.replace(/['"]+/g, ""));
    } else {
      setLocalUserName(null);
    }
    if (photoURL) {
      setLocalPhotoURL(photoURL.replace(/['"]+/g, ""));
    } else {
      setLocalPhotoURL(null);
    }
  }, [loaderData, submit]);

  return (
    <div className={classes.rootLayout}>
      <NavigationBar
        name={localUserName ? localUserName : null}
        photoUrl={localPhotoURL ? localPhotoURL : undefined}
      />
      <main className={classes.content}>
        {/* {navigation.state === 'loading' && <p>Loading...</p>} */}
        <Outlet />
      </main>
      <div className={classes.footer}>
        <Footer />
      </div>
    </div>
  );
}

export default RootLayout;
