import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/cjs/Page/AnnotationLayer.css';
import 'react-pdf/dist/cjs/Page/TextLayer.css';
import { makeStyles } from 'tss-react/mui';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { type FileType } from '../../../utils/interfaces';
import { Container } from '@mui/material';
//import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const useStyles = makeStyles()((theme) => ({
  pdfDiv: {
    padding: '20px',
    backgroundColor: theme.palette.gray.main,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  button: {
    fontFamily: theme.typography.button.fontFamily,
    textTransform: "none",
    minWidth: 200,
    backgroundColor: theme.palette.blue.main,
  },
  buttonBar: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: theme.spacing(2),
  },
  p : {
    fontFamily: theme.typography.button.fontFamily,
    color: "white",
    fontSize: "0.75rem"
  },
  text: {
    //fontFamily: theme.typography.button.fontFamily,
    color: "black",
    padding: "20px",
    width: "100%",
    whiteSpace: 'pre-wrap', // Ensures line breaks and preserves formatting
    wordBreak: 'break-word', // Breaks long words if necessary
  },
  doc: {
    //padding: "20px",
    width: "100%",
    height: "800px",
  },
  msdocRenderer: {
    width: "100%",
    height: "800px",
    padding: 0,
    margin: 0,
  },
}));

interface IPDFRenderer {
  fileUrl : string, 
  options: {
    cMapUrl: string; 
    standardFontDataUrl: string; 
  }, 
  viewportHeight: number, 
  viewportWidth:number,
  pageNumber: number,
  onDocumentLoadSuccess: ({ numPages }: { numPages: number }) => void,
  onPageLoadSuccess: (page: any) => void,
  onLoadError: (error: Error) => void,

}

const PDFRenderer = React.memo(({fileUrl, options, viewportHeight, viewportWidth, pageNumber, onDocumentLoadSuccess, onPageLoadSuccess, onLoadError}: IPDFRenderer) => (
  <div style={{ height: viewportHeight, width: viewportWidth }}>
     <Document options={options}
      file={fileUrl}
      onLoadSuccess={onDocumentLoadSuccess}
      onLoadError={onLoadError}
    >
      <Page 
        key={`page_${pageNumber}`} 
        pageNumber={pageNumber}    
        onLoadSuccess={onPageLoadSuccess}
      />
    </Document>
  </div>
));

const IFramePDFRenderer = React.memo(({fileUrl, viewportHeight, viewportWidth}: {fileUrl: string, viewportHeight: number, viewportWidth: number}) => (
     <iframe 
        style={{ width: '100%', height: 800, padding: 0 }}
        id="pdf-iframe"
        title="pdf-iframe"
        src={fileUrl}
      />
));

const DocRenderer = React.memo(({fileUrl}: {fileUrl: string}) => (
  <div>
    <Container id="msdocRenderer">
      <iframe
        style={{ width: '100%', height: 800, padding: 0 }}
        id="msdoc-iframe"
        title="msdoc-iframe"
        src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
          fileUrl,
        )}`}
      />
    </Container>
  </div>
));

// const DocRenderer = React.memo(({fileUrl}: {fileUrl: string}) => (
//   <div>
//     <DocViewer documents={[{ uri: fileUrl }]} pluginRenderers={DocViewerRenderers} style={{ width: 700, height: 700 }}/>
//   </div>
// ));

const fetchFileContent = async (url: string): Promise<string | null> => {
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return await response.text();
  } catch (error) {
    console.error("Failed to fetch file:", error);
    return null;
  }
};

interface IFileViewerProps {
  fileUrl: string;
  fileType: FileType;
}

const FileViewer = ({ fileUrl, fileType} : IFileViewerProps) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const [fileContent, setFileContent] = useState<string>("");
  const [numPages, setNumPages] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState(1);
  const file  = useMemo(() => ({ fileUrl }), [fileUrl]);
  //const fileOptions = useMemo(() => ({ options }), []);
  const [viewportHeight, setViewportHeight] = useState<number>(0);
  const [viewportWidth, setViewportWidth] = useState<number>(0);
  const useIFrame = true;

  const options = React.useMemo(() => ({
    cMapUrl: 'your_cMapUrl',
    standardFontDataUrl: 'your_standardFontDataUrl'
  }), []);

  const handlePageChange = (newPageNumber : number) => {
    setPageNumber(newPageNumber); // Change the page number, triggering re-render
  };

  useEffect(() => {
    // For PDFs, we'll let the PDF viewer handle it, but for text and JSON,
    // we fetch the content and display it.
    if (fileType === 'txt' || fileType === 'json') {
      fetchFileContent(fileUrl).then(content => {
        if (!content) {
          setFileContent(t('failedToFetchFileContent'));
          return;
        }
        // For JSON files, let's prettify them.
        if (fileType === 'json') {
          try {
            const json = JSON.parse(content);
            setFileContent(JSON.stringify(json, null, 2));
          } catch (error) {
            console.error("Failed to parse JSON:", error);
            setFileContent(t('failedToParseJSONCheckConsoleForDetails'));
          }
        } else {
          setFileContent(content);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileUrl, fileType]);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    console.log('Load successfull');
    setNumPages(numPages);
  };

  const onPageLoadSuccess = (page: any) => {
    console.log('Page load successful! Page: ' + page);
    const viewPort = page.getViewport({ scale: 1.0 });
    setViewportHeight(viewPort.height);
    setViewportWidth(viewPort.width);
  };

  const onLoadError = (error: Error) => {
    console.error("Failed to load document:", error);
  };

  const increasePageNumber = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (pageNumber < numPages) {
      handlePageChange(pageNumber + 1);
    }
  };
  
  const decreasePageNumber = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (pageNumber > 1) {
      handlePageChange(pageNumber - 1);
      
    }
  };

  const renderFile = () => 
  {
    switch (fileType) {
      case 'pdf':
        return (
          fileUrl && fileUrl !== "" &&
          <div className={classes.pdfDiv}>
            { useIFrame ? 
              <IFramePDFRenderer fileUrl={fileUrl} viewportHeight={viewportHeight} viewportWidth={viewportWidth} />
              :
              <>
                <PDFRenderer 
                  fileUrl={file.fileUrl} 
                  options={options} 
                  viewportHeight={viewportHeight} 
                  viewportWidth={viewportWidth} 
                  pageNumber={pageNumber}
                  onDocumentLoadSuccess={onDocumentLoadSuccess}
                  onPageLoadSuccess={onPageLoadSuccess}
                  onLoadError={onLoadError}
                />
                <div className={classes.buttonBar}>
                  <Button 
                    className={classes.button}
                    onClick={decreasePageNumber} variant='contained' color='primary'>
                    {'<<<'}
                  </Button>
                  <p className={classes.p}>
                    {t('page')} {pageNumber} {t('of')} {numPages}
                  </p>
                  <Button 
                    className={classes.button} 
                    onClick={increasePageNumber} variant='contained' color='primary'>
                    {'>>>'}
                  </Button>    
                </div>
              </> 
            }
          </div>
        );
      case 'txt':
      case 'json':
        return <pre className={classes.text}>{fileContent || "Loading..."}</pre>;
        case 'doc':
        case 'docx':
            return <div className={classes.doc}>
                <DocRenderer fileUrl={fileUrl} />
              </div>;
      default:
        return <p className={classes.text}>{t('unsupportedFileType')}</p>;
    }
  };

  return (
    <div style={{width: "100%"}}>
      {renderFile()}
    </div>
  );
};

FileViewer.propTypes = {
  fileUrl: PropTypes.string.isRequired,
  fileType: PropTypes.oneOf(['pdf', 'txt', 'doc', 'docx', 'json']).isRequired,
};

export default FileViewer;
