import React, { useState, useEffect } from 'react';
import FileUploader from './FileUploader';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from 'react-i18next';
import { useRouteLoaderData, useNavigate, useLocation } from 'react-router-dom';
import { type IApiBasePathProps, type IAuthData, type IFileActionOption, type SnackbarColor } from '../../../utils/interfaces';
import { Container, Snackbar } from '@mui/material';
import Navigation from '../../navigation/Navigation';
import OptionSelector from '../../navigation/OptionSelector';

const useStyles = makeStyles()((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  configContainer: {
    width: "100%",
    flex: 1,
    display: "flex",
    flexDirection: "row",
  },
  content: {
    padding: theme.spacing(4),
    width: "100%",
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  iconButton: {
    padding: 0,
    width: "40px",
    height: "40px",
  },
  button: {
    textTransform: "none",
    marginTop: theme.spacing(0),
    backgroundColor: theme.palette.blue.main,
  },
  layoutContainer: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap", // Allow the containers to wrap
    justifyContent: "space-between",
    marginBottom: theme.spacing(0), // Space before the bottomContainer
  },
  fileUploadPage: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  optionSelectorsContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "center",
    alignItems: "flex-start",
    flexBasis: "100%",
    [theme.breakpoints.down("lg")]: {
      flexDirection: "column",
      flexBasis: "100%",
      flexWrap: "wrap",
    },
    margin: theme.spacing(0),
  },
}));


function FileUploadPage ({apiBasePath}: IApiBasePathProps) {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const navigate = useNavigate();
  const location = useLocation(); 
  const loaderData = useRouteLoaderData("root") as IAuthData;
  const token = loaderData.token;
  const [action, setAction] = useState<IFileActionOption | null>(null);
  const [actionId, setActionId] = useState<number>(-1);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarColor, setSnackbarColor] = useState<SnackbarColor>(""); 

  const showSnackbar = (message : string, color : SnackbarColor) => {
    setSnackbarMessage(message);
    setSnackbarColor(color);
    setSnackbarOpen(true);
  };

  const actionOptions = [
    { id:1, name: t("fileUpload"), value: "/fileUpload" },
    { id:2, name: t("fileSelect"), value: "/fileSelect" },
  ];

  const isRouteActive = (routePath : string) => {
    return location.pathname === routePath;
  };

  useEffect(() => {
    for (let i = 0; i < actionOptions.length; i++) {
      if (isRouteActive(actionOptions[i].value)) {
        setAction(actionOptions[i]);
        setActionId(actionOptions[i].id);
        break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFileUpload = async (file: File) => {
    // Handle the file upload logic here
    console.log('File uploaded:', file);
    const formData = new FormData();
    formData.append('file', file); // 'file' is the key expected on the server side
    formData.append('token', token!);

    try {
      const response = await fetch(
        apiBasePath + '/api/upload', {
        method: 'POST', 
        body: formData,
        // Note: Fetch API automatically sets the `Content-Type` to 'multipart/form-data'
      });

      if (response.status === 401) {
        // Redirect to logout
        navigate("/home");
        return null; // Early return to avoid further processing
      }

      const result = await response.json();

      if (response.ok) {
        showSnackbar(t('fileUploadSuccess'), "success");
        // Handle success scenario (e.g., display success message)
      } else {
        console.error('Upload Error:', result.error);
        // Handle error scenario (e.g., display error message)
      }
    } catch (error) {
      console.error('Fetch Error:', error);
      // Handle network errors (e.g., display error message)
    }
  };

  const handleActionOptionClick = async (selectedActionId : number) => {
    // Find the action directly without looping, thanks to the beauty of array.find!
    const selectedAction = actionOptions.find(action => action.id === selectedActionId);
    
    if (selectedAction && selectedActionId !== action?.id) {
      setActionId(selectedActionId); // Update the action ID state.
      setAction(selectedAction); // Update the action state.
  
      try {
        await navigate(selectedAction.value); // Use async/await to ensure navigation is complete.
      } catch (error) {
        console.error('Navigation failed:', error);
      }
    }
  };

  const handleFileError = (error: string) => {
    // Handle the file upload error logic here
    console.error('File upload error:', error);
    showSnackbar(error, "error");
  };

  const optionSelectors = (
    <div className={classes.optionSelectorsContainer}>
      <OptionSelector
        title={t("fileAction")}
        options={actionOptions}
        selectedOption={actionId}
        onOptionClick={handleActionOptionClick}
        colorKey="greenSelect"
        hoverDisplay={false}
      />
    </div>
  );

  return (
    <Container className={classes.container} maxWidth={false}>
      <Navigation
          apiBasePath={apiBasePath}
          personalizeElement={<></>}
          chatElement={<></>}
          fileElement={optionSelectors}
          audioElement={<></>}
        />
      <div className={classes.layoutContainer}>
        <div className={classes.configContainer}>
          <div className={classes.content}>
            <div className={classes.fileUploadPage}>
              <h1>{t('fileUploadHeading')}</h1>
              <FileUploader 
                onFileSelectSuccess={handleFileUpload} 
                onFileSelectError={handleFileError} />
            </div>
          </div>
        </div>
      </div>
      <Snackbar
              open={snackbarOpen}
              autoHideDuration={5000}
              onClose={() => setSnackbarOpen(false)}
              message={snackbarMessage}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              ContentProps={{
                style: {
                  backgroundColor:
                    snackbarColor === "success" ? "green" : "red",
                },
              }}
            />
    </Container>
  );
};

export default FileUploadPage;