import React, { useState } from "react";
import {
  Container,
  IconButton,
} from "@mui/material";
import { useRouteLoaderData } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import { makeStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { type IApiBasePathProps, type IAuthData, type IPage } from "../../../utils/interfaces";
import SideBar from "../../navigation/SideBar";
import PromptConfig from "./PromptConfig";

const useStyles = makeStyles()((theme) => ({
  container: {
    padding: theme.spacing(4),
    //maxWidth: "1200px",
    width: "100%",
    alignItems: "left",
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    justifyContent: "space-between",
    margin: 0,
  },
  configContainer: {
    width: "100%",
    flex: 1,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "top",
    justifyItems: "stretch",
  },
  drawer: {
    width: "100%",
    [theme.breakpoints.down("md")]: {
      width: "auto",
    },
  },
  content: {
    padding: theme.spacing(4),
    width: "100%",
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },

  iconButton: {
    padding: 0,
    width: "40px",
    height: "40px",
  },

}));


function PromptConfigPage({apiBasePath} : IApiBasePathProps) {
  const { t } = useTranslation();
  const loaderData = useRouteLoaderData("root") as IAuthData;
  const user = loaderData.userData;
  const { classes } = useStyles();
  const [isSideBarOpen, setIsSideBarOpen] = useState(true);
  const isAdmin = user.roleId === 1;

  let pages : IPage[] = [
    { text : t("promtConfig"), route: "/configuration" }, 
    { text: t("gptConfig"), route: "/gptConfig" },
    
  ];
  if (isAdmin) {
    pages.push({text: t("gptConfigForAllUsers"), route: "/gptConfigForAllUsers"});
    pages.push({ text: t("promptConfigForAllUsers"), route: "/promptConfigForAllUsers" });
  }

  const handleDrawerOpen = () => {
    setIsSideBarOpen(true);
  };

  const handleDrawerClose = () => {
    setIsSideBarOpen(false);
  };

  return (
    <div>
      <Container className={classes.container}  >
      { isSideBarOpen === false ? 
          <div className={classes.iconButton}>
              <IconButton 
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
            >
              <MenuIcon />
            </IconButton> 
            </div>
            : null
          }
        <div className={classes.configContainer}>
          <div className={classes.drawer}>
          <SideBar pages={pages} isOpen={isSideBarOpen} onClose={handleDrawerClose}/>
          </div>
          <div className={classes.content}>
            <PromptConfig apiBasePath={apiBasePath} user={user}/>
            
          </div>
        </div>
      </Container>
    </div>
  );
}

export default PromptConfigPage;
