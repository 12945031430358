import React, { useState, useEffect } from "react";
import {
  Container,
  IconButton,
  Paper,
} from "@mui/material";
import { useRouteLoaderData } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import { makeStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { type IApiBasePathProps, type IAuthData, type IUser, type IPage } from "../../../utils/interfaces";
import SideBar from "../../navigation/SideBar";
import PromptConfig from "./PromptConfig";
import UserSelectTable from "./UserSelectTable";
import useApi from "../../../utils/useApi";

const useStyles = makeStyles()((theme) => ({
  container: {
    padding: theme.spacing(4),
    //maxWidth: "1200px",
    width: "100%",
    alignItems: "left",
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    justifyContent: "space-between",
    margin: 0,
  },
  configContainer: {
    width: "100%",
    flex: 1,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "top",
    justifyItems: "stretch",
  },
  drawer: {
    width: "100%",
    [theme.breakpoints.down("md")]: {
      width: "auto",
    },
  },
  content: {
    padding: theme.spacing(4),
    width: "100%",
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  paper: {
    padding: theme.spacing(2),
    boxShadow: "0px 4px 12px rgba(0,0,0,0.1)", // Adds a subtle shadow
    borderRadius: theme.shape.borderRadius, // Uses theme's border radius for consistency
  },
  iconButton: {
    padding: 0,
    width: "40px",
    height: "40px",
  },

}));


function PromptConfigForAllUsers({apiBasePath} : IApiBasePathProps) {
  const { t } = useTranslation();
  const loaderData = useRouteLoaderData("root") as IAuthData;
  const user = loaderData.userData;
  const customFetch = useApi();
  const token = loaderData.token;
  const { classes } = useStyles();
  const [isSideBarOpen, setIsSideBarOpen] = useState(true);
  const [users, setUsers] = useState<IUser[]>([]);
  const [selectedUser, setSelectedUser] = useState<IUser | null>(null);
  const isAdmin = user.roleId === 1;

  let pages : IPage[] = [
    { text : t("promtConfig"), route: "/configuration" }, 
    { text: t("gptConfig"), route: "/gptConfig" },
    
  ];
  if (isAdmin) {
    pages.push({text: t("gptConfigForAllUsers"), route: "/gptConfigForAllUsers"});
    pages.push({ text: t("promptConfigForAllUsers"), route: "/promptConfigForAllUsers" });
  }

  const handleDrawerOpen = () => {
    setIsSideBarOpen(true);
  };

  const handleDrawerClose = () => {
    setIsSideBarOpen(false);
  };

  useEffect(() => {
    if (users.length === 0 && selectedUser !== null) {
      for (let i = 0; i < users.length; i++) {
        if (users[i].id === selectedUser.id) {
          setSelectedUser(users[i]);
          break;
        }
      }
    }
        // eslint-disable-next-line react-hooks/exhaustive-deps
  } , [users]);

  useEffect(() => {
    if (users.length === 0) {
      fetchUsers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users]);

  const fetchUsers = async () => {
    try {
      const payload = {
        token: token,
      };
      const body = JSON.stringify(payload);
      const fetchOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: body,
      };

      const data = await customFetch(
        apiBasePath + "/api/get_all_users",
        fetchOptions
      );
      // console.log(data);
      setUsers(data);
    } catch (error) {
      console.error("Error fetching options:", error);
    }
  };

  return (
    <div>
      <Container className={classes.container}  >
      { isSideBarOpen === false ? 
          <div className={classes.iconButton}>
              <IconButton 
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
            >
              <MenuIcon />
            </IconButton> 
            </div>
            : null
          }
        <div className={classes.configContainer}>
          <div className={classes.drawer}>
          <SideBar pages={pages} isOpen={isSideBarOpen} onClose={handleDrawerClose}/>
          </div>
          <div className={classes.content}>
            <Paper className={classes.paper}>
              <UserSelectTable
                users={users}
                selectedUser={selectedUser}
                setSelectedUser={setSelectedUser}
              />
            </Paper>
            <PromptConfig apiBasePath={apiBasePath} user={selectedUser}/>
            
          </div>
        </div>
      </Container>
    </div>
  );
}

export default PromptConfigForAllUsers;
