import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useLocation, useRouteLoaderData } from "react-router-dom";
import { makeStyles } from "tss-react/mui";
//import {  Container } from '@mui/material';
import { useTranslation } from "react-i18next";
import ExpandButton from "./ExpandButton";
import personalize from "../../images/personalize.png";
import prompting from "../../images/prompting.png";
import audio from "../../images/audio.png";
import { type INavigationProps, type IAuthData, type IFeature } from "../../utils/interfaces";
import { getFeatures } from "../../utils/generalFunctions";

const useStyles = makeStyles()((theme) => ({
  AppBar: {
    marginBottom: theme.spacing(0),
    backgroundColor: theme.palette.common.white,
    padding: 0,
  },
  toolbar: {
    padding: 0,
  },
  navContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
    padding: 0,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  icon: {
    height: "50px",
  },
}));

function Navigation({ apiBasePath, personalizeElement, chatElement, fileElement, audioElement } : INavigationProps) {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const loaderData = useRouteLoaderData("root") as IAuthData;
  const token = loaderData.token;
  const location = useLocation(); // This gives you the location object
  const [features, setFeatures] = useState<IFeature[]>([]); 

  // This function checks if the button's path matches the current location
  const isRouteActive = (routePath : string) => {
    return location.pathname === routePath;
  };

  useEffect(() => {
    const features = getFeatures(apiBasePath, token);
    features.then((features) => {
      setFeatures(features);
    });
  }, [apiBasePath, token]);

  const hasFeature = (feature : string) => {
    return features.some((f) => f.name === feature) ;
  }

  const isFileRouteActive = isRouteActive("/fileSelect") || isRouteActive("/fileUpload") || isRouteActive("/fileChat");

  return (
    <div className={classes.navContainer}>
      <ExpandButton
        isExpanded={isRouteActive("/personalize")}
        icon={
          <img src={personalize} alt="personalize" className={classes.icon} />
        }
        text={t("personalization")}
        expandComponent={<div>{personalizeElement}</div>}
        redirectTo={"/personalize"}
        colorKey={"violet"}
      />
      <ExpandButton
        isExpanded={isRouteActive("/chat")}
        icon={<img src={prompting} alt="chat" className={classes.icon} />}
        text={t("prompting")}
        expandComponent={<div>{chatElement}</div>}
        redirectTo={"/chat"}
        colorKey={"blue"}
      />
      {hasFeature("files")  && (
        <ExpandButton
          isExpanded={isFileRouteActive}
          icon={<img src={personalize} alt="fileSelect" className={classes.icon} />}
          text={t("Files")}
          expandComponent={<div>{fileElement}</div>}
          redirectTo={"/fileSelect"}
          colorKey={"green"}
        />
      )}
      {hasFeature("audio") && (
        <ExpandButton
          isExpanded={isRouteActive("/audio")}
          icon={<img src={audio} alt="audio" className={classes.icon} />}
          text={t("audio")}
          expandComponent={<div>{audioElement}</div>}
          redirectTo={"/audio"}
          colorKey={"green"}
        />
      )}
    </div>
  );
}

Navigation.propTypes = {
  personalizeElement: PropTypes.element,
  chatElement: PropTypes.element,
  audioElement: PropTypes.element,
};

export default Navigation;
