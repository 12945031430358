import React, { useEffect, useState } from "react";
import { Container, Button, Snackbar } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import TextTitleBlock from "./TextTitleBlock";
import OptionSelector from "../../navigation/OptionSelector";
import { useTranslation } from "react-i18next";
import { useRouteLoaderData } from "react-router-dom";
import PersonalizeHistoryGrid from "./PersonalizeHistoryGrid";

import Navigation from "../../navigation/Navigation";
import useApi from "../../../utils/useApi";
import { type IApiBasePathProps, type IAuthData, type IActionOptions, type IToneOptions, type IGptOptions, type IPersonalizeHistoryGridRow, type SnackbarColor } from "../../../utils/interfaces";

const useStyles = makeStyles()((theme) => ({
  container: {
    padding: theme.spacing(4),
    maxWidth: "1400px",
  },
  button: {
    textTransform: "none",
    marginTop: theme.spacing(0),
    backgroundColor: theme.palette.blue.main,
  },
  layoutContainer: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap", // Allow the containers to wrap
    justifyContent: "space-between",
    marginBottom: theme.spacing(0), // Space before the bottomContainer
    padding: theme.spacing(2), 
  },
  textTitleBlockContainer: {
    marginBottom: theme.spacing(0),
    width: "100%", // This ensures it's always full width
    flexBasis: "100%", // Take up 60% of the container
    [theme.breakpoints.down("sm")]: {
      // Adjust for medium and smaller screens
      flexBasis: "100%", // Full width on smaller screens
    },
  },
  optionSelectorsContainer: {
    display: "flex",
    flexDirection: "row", // Change to row for horizontal alignment
    flexWrap: "nowrap", // Keeps items in a row but allows them to wrap to a new line if needed
    justifyContent: "center", // Adjust this as needed for alignment (e.g., 'flex-start', 'space-between')
    alignItems: "flex-start",
    flexBasis: "100%", // Adjust or remove this as needed
    [theme.breakpoints.down("lg")]: {
      flexDirection: "column", // Stack vertically on smaller screens
      flexBasis: "100%",
      flexWrap: "wrap",
    },

    margin: theme.spacing(0), // Adds some margin around the container
    // ... any other styling you need ...
  },
  bottomContainer: {
    flexBasis: "100%", // This ensures it's always full width
    marginTop: theme.spacing(0),
  },
  buttonDiv: {
    display: "flex",
    flexDirection: "row",
    alignItems: "Center",
    justifyContent: "space-evenly",
    padding: theme.spacing(1),
    flexBasis: "100%",
  },
  disclaimerOpenAI: {
    color: theme.palette.red.main,
    fontSize: "10px",
    marginBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    width: "90%",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  disclaimerDesktop: {
    color: "black",
    fontSize: "10px",
    marginBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    width: "90%",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  disclaimerMobile: {
    color: "black",
    fontSize: "10px",
    marginBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    width: "90%",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

function PersonalizePage({apiBasePath} : IApiBasePathProps ) {
  const customFetch = useApi();
  const loaderData = useRouteLoaderData("root") as IAuthData;
  const token = loaderData.token;
  const userData = loaderData.userData;
  const { t } = useTranslation();

  const [typeOptions, setTypeOptions] = useState<IActionOptions[]>([]);
  const [toneOptions, setToneOptions] = useState<IToneOptions[]>([]);
  const [actionOptions, setActionOptions] = useState<IActionOptions[]>([]);
  const [gptOptions, setGptOptions] = useState<IGptOptions[]>([]);
  const [title, setTitle] = useState("");
  const [text, setText] = useState("");

  const { classes } = useStyles();
  const [action, setAction] = useState(-1);
  const [type, setType] = useState(-1);
  const [tone, setTone] = useState(-1);
  const [gpt, setGpt] = useState(-1);
  const [gptName, setGptName] = useState("");
  const [gptIsGRDPCompliant, setGptIsGRDPCompliant] = useState(false);
  const [article, setArticle] = useState({
    title: title,
    content: text,
    result: text,
  });

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarColor, setSnackbarColor] = useState<SnackbarColor>(""); 

  const [resultText, setResultText] = useState("");
  const [showHistory, setShowHistory] = useState(false);
  const [isTyping, setIsTyping] = React.useState(false);

  const [showGptConfig, setShowGptConfig] = useState(userData.showGptSelector);

  useEffect(() => {
    const fetchData = async () => {
      const payload = { token: token };
      const body = JSON.stringify(payload);
      const options = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: body,
      };

      try {
        const typeData = await customFetch(
          apiBasePath + `/api/get_types`,
          options
        );
        if (typeData) {
          setTypeOptions(typeData);
        }
      } catch (error) {
        console.log("Error fetching types:", error);
      }
      try {
        const toneData = await customFetch(
          apiBasePath + `/api/get_tones`,
          options
        );
        if (toneData) {
          setToneOptions(toneData);
        }
      } catch (error) {
        console.log("Error fetching tones:", error);
      }
      try {
        const actionData = await customFetch(
          apiBasePath + `/api/get_actions`,
          options
        );
        if (actionData) {
          setActionOptions(actionData);
        }
      } catch (error) {
        console.log("Error fetching chat actions:", error);
      }
      try {
        const gptData = await customFetch(
          apiBasePath + `/api/get_gpt_models`,
          options
        );
        if (gptData) {
          setGptOptions(gptData);
        }
      } catch (error) {
        console.log("Error fetching gpt models:", error);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiBasePath, token]);

  useEffect(() => {
    for (let i = 0; i < actionOptions.length; i++) {
      setAction(actionOptions[i].id);
      break;
    }
  }, [actionOptions]);

  useEffect(() => {
    for (let i = 0; i < typeOptions.length; i++) {
      setType(typeOptions[i].id);
      break;
    }
  }, [typeOptions]);

  useEffect(() => {
    for (let i = 0; i < toneOptions.length; i++) {
      setTone(toneOptions[i].id);
      break;
    }
  }, [toneOptions]);

  useEffect(() => {
    for (let i = 0; i < gptOptions.length; i++) {
      setGpt(gptOptions[i].id);
      setGptName(gptOptions[i].name);
      setGptIsGRDPCompliant(gptOptions[i].isGRDPCompliant);
      break;
    }
  }, [gptOptions]);

  useEffect(() => {
    if (gptOptions.length > 0) {
        for (let i = 0; i < gptOptions.length; i++) {
          if (gptOptions[i].id === gpt) {
            setGptName(gptOptions[i].name);
            setGptIsGRDPCompliant(gptOptions[i].isGRDPCompliant);
            break;
          }
        }
    }
  }, [gpt, gptOptions]);

  const showSnackbar = (message : string, color : SnackbarColor) => {
    setSnackbarMessage(message);
    setSnackbarColor(color);
    setSnackbarOpen(true);
  };

  const handlePostButtonClick = async () => {
    if (!action || !type || !tone) {
      //setResultText('Please select values for all three dropdowns.');
      showSnackbar(t("valuesForAllThreeOptionsRequired"), "error");
      return;
    }

    if (!article.content) {
      //setResultText('Please enter text to simplify.');
      showSnackbar(t("enterTextToSimplify"), "error");
      return;
    }

    const payload = {
      token: token,
      actionId: action,
      typeId: type,
      toneId: tone,
      text: article.content,
      gptVersion: gptName
    };

    const body = JSON.stringify(payload);

    const options = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: body,
    };

    //setResultText('Waiting for Response...');
    showSnackbar(t("waitingForResponse"), "success");
    setIsTyping(true);

    try {
      const url = apiBasePath + "/api/simplifyselect";
      const data = await customFetch(url, options);

      setIsTyping(false);

      if (data) {
        const resultText = data.result;

        setResultText(resultText);
        setArticle((prevArticle) => ({
          ...prevArticle,
          result: resultText,
        }));
        showSnackbar(t("requestDone"), "success");
        //setOpen(true);
        //console.log('Post request done!');
      } else {
        console.log(t("requestFailed"));
        //setResultText('Request failed.');
        const resultText = t("requestFailed");

        setResultText(resultText);
        setArticle((prevArticle) => ({
          ...prevArticle,
          result: resultText,
        }));
        showSnackbar(t("requestFailed"), "error");
      }
    } catch (error) {
      setIsTyping(false);
      console.error("An error occurred while making the post request:", error);
      //setResultText('An error occurred while making the post request:', error);
      showSnackbar(t("requestFailed"), "error");
      const resultText = t("requestFailed");
      setResultText(resultText);
      setArticle((prevArticle) => ({
        ...prevArticle,
        result: resultText,
      }));
    }
  };

  const handleTextChange = (event : React.ChangeEvent<HTMLTextAreaElement>) => {
    setText(event.target.value);
    setArticle((prevArticle) => ({
      ...prevArticle,
      content: event.target.value,
    }));
  };

  const handleHistoryButtonClick = () => {
    setShowHistory(!showHistory);
  };

  const handleNewButtonClick = () => {
    setText("");
    setTitle("");
    setArticle((prevArticle) => ({
      ...prevArticle,
      title: "",
      content: "",
      result: "",
    }));
    setResultText("");
  };

  const handleRowSelection = (row : IPersonalizeHistoryGridRow) => {
    setText(row.text);
    setArticle((prevArticle) => ({
      ...prevArticle,
      title: "",
      content: row.text,
      result: row.aiContent,
    }));
    setResultText(row.aiContent);
    setAction(row.actionId);
    setType(row.typeId);
    setTone(row.toneId);
    setGpt(row.gptVersionId);
  };

  const optionSelectors = (
    <div className={classes.optionSelectorsContainer}>
      <OptionSelector
        title={t("actionSelect")}
        options={actionOptions}
        selectedOption={action}
        onOptionClick={setAction}
        colorKey="violetSelect"
      />
      <OptionSelector
        title={t("typeSelect")}
        options={typeOptions}
        selectedOption={type}
        onOptionClick={setType}
        colorKey="violetSelect"
      />
      <OptionSelector
        title={t("toneSelect")}
        options={toneOptions}
        selectedOption={tone}
        onOptionClick={setTone}
        colorKey="violetSelect"
      />
      { showGptConfig ?
        <OptionSelector
          title={t("gptSelect")}
          options={gptOptions}
          selectedOption={gpt}
          onOptionClick={setGpt}
          colorKey="violetSelect"
        />
        : null
      }
    </div>
  );

  return (
    <div>
      <Container className={classes.container} maxWidth={false}>
        <Navigation
          apiBasePath={apiBasePath}
          personalizeElement={optionSelectors}
          chatElement={<></>}
          fileElement={<></>}
          audioElement={<></>}
        />
        <div className={classes.layoutContainer}>
          <div className={classes.textTitleBlockContainer}>
            <TextTitleBlock
              showTitle={false}
              title={article.title}
              content={text}
              aiContent={resultText}
              onContentChange={handleTextChange}
              onTitleChange={(e) => setTitle(e.target.value)}
              titlePlaceHolder="Title"
              contentPlaceHolder={t('yourTextHere')}
              onAIContentChange={() => {}}
              showApproxAudioLength={false}
              showAiContent={true}
              isTyping={isTyping}
            />
          </div>
          <div className={classes.disclaimerDesktop}>
            {t("disclaimerDesktop")}
          </div>
          <div className={classes.disclaimerMobile}>
            {t("disclaimerMobile")}
          </div>
          <div className={classes.disclaimerOpenAI}>
            {
                (!gptIsGRDPCompliant) ? t("disclaimerOpenAI"): ""
            }
          </div> 
          <div className={classes.bottomContainer}>
            <div className={classes.buttonDiv}>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={handlePostButtonClick}
              >
                {t("simplify")}
              </Button>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={handleNewButtonClick}
              >
                {t("new")}
              </Button>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={handleHistoryButtonClick}
              >
                {t("history")}
              </Button>
            </div>
            {showHistory ? (
              <div>
                <PersonalizeHistoryGrid
                  apiBasePath={apiBasePath}
                  handleRowSelection={handleRowSelection}
                />
              </div>
            ) : null}
            <Snackbar
              open={snackbarOpen}
              autoHideDuration={5000}
              onClose={() => setSnackbarOpen(false)}
              message={snackbarMessage}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              ContentProps={{
                style: {
                  backgroundColor:
                    snackbarColor === "success" ? "green" : "red",
                },
              }}
            />
          </div>
        </div>
      </Container>
    </div>
  );
}

export default PersonalizePage;
