import React from "react";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    maxWidth: "960px",
    marginLeft: "auto", // Center the container
    marginRight: "auto", // Center the container
    //width: "100%", 
    whiteSpace: "pre-wrap",
    wordWrap: "break-word",
    wordBreak: "break-word",
  },
}));

function DataProtectionPage() {
  
  const { classes } = useStyles();

  // Set to the same value as the web property used on the site
  var gaProperty = "GTM-59K2QC2Q";

  // Disable tracking if the opt-out cookie exists.
  var disableStr = "ga-disable-" + gaProperty;
  if (document.cookie.indexOf(disableStr + "=true") > -1) {
    window[disableStr] = true;
  }

  // Opt-out function
  function gaOptout() {
    document.cookie =
      disableStr + "=true; expires=Thu, 31 Dec 2099 23:59:59 UTC; path=/";
    window[disableStr] = true;
  }

  const optOut = () => {
    gaOptout();
    alert("Google Analytics wurde deaktiviert");
  };

  return (
    <div className={classes.container}>
      <h1>Datenschutzerklärung</h1>
      <p>
        Verantwortlicher im Sinne der Datenschutzgesetze, insbesondere der
        EU-Datenschutzgrundverordnung (DSGVO), ist:
      </p>
      <p>
        nudge GmbH
        <br />
        Grindelberg 15a
        <br />
        20144 Hamburg
        <br />
        Tel: +49 (0) 40 87 09 94 87
        <br />
        E-Mail: info@nudge.media
      </p>
      <h2>Ihre Betroffenenrechte</h2>
      <p>
        Unter den angegebenen Kontaktdaten unseres Datenschutzbeauftragten
        können Sie jederzeit folgende Rechte ausüben:
      </p>
      <ul>
        <li>
          Auskunft über Ihre bei uns gespeicherten Daten und deren Verarbeitung
          (Art. 15 DSGVO),
        </li>
        <li>
          Berichtigung unrichtiger personenbezogener Daten (Art. 16 DSGVO),
        </li>
        <li>Löschung Ihrer bei uns gespeicherten Daten (Art. 17 DSGVO),</li>
        <li>
          Einschränkung der Datenverarbeitung, sofern wir Ihre Daten aufgrund
          gesetzlicher Pflichten noch nicht löschen dürfen (Art. 18 DSGVO),
        </li>
        <li>
          Widerspruch gegen die Verarbeitung Ihrer Daten bei uns (Art. 21 DSGVO)
          und
        </li>
        <li>
          Datenübertragbarkeit, sofern Sie in die Datenverarbeitung eingewilligt
          haben oder einen Vertrag mit uns abgeschlossen haben (Art. 20 DSGVO).
        </li>
      </ul>
      <p>
        Sofern Sie uns eine Einwilligung erteilt haben, können Sie diese
        jederzeit mit Wirkung für die Zukunft widerrufen.
      </p>
      <p>
        Sie können sich jederzeit mit einer Beschwerde an eine Aufsichtsbehörde
        wenden, z. B. an die zuständige Aufsichtsbehörde des Bundeslands Ihres
        Wohnsitzes oder an die für uns als verantwortliche Stelle zuständige
        Behörde.
      </p>
      <p>
        Eine Liste der Aufsichtsbehörden (für den nichtöffentlichen Bereich) mit
        Anschrift finden Sie unter:{" "}
        <a
          href="https://www.bfdi.bund.de/DE/Service/Anschriften/Laender/Laender-node.html"
          target="_blank"
          rel="noopener nofollow noreferrer"
        >
          https://www.bfdi.bund.de/DE/Service/Anschriften/Laender/Laender-node.html
        </a>
        .
      </p>
      <p></p>
      <h2>Cookies</h2>
      <p>
        Wie viele andere Webseiten verwenden wir auch so genannte „Cookies“. Bei
        Cookies handelt es sich um kleine Textdateien, die auf Ihrem Endgerät
        (Laptop, Tablet, Smartphone o.ä.) gespeichert werden, wenn Sie unsere
        Webseite besuchen.{" "}
      </p>
      <p>
        Sie können Sie einzelne Cookies oder den gesamten Cookie-Bestand
        löschen. Darüber hinaus erhalten Sie Informationen und Anleitungen, wie
        diese Cookies gelöscht oder deren Speicherung vorab blockiert werden
        können. Je nach Anbieter Ihres Browsers finden Sie die notwendigen
        Informationen unter den nachfolgenden Links:
      </p>
      <ul>
        <li>
          Mozilla Firefox:{" "}
          <a
            href="https://support.mozilla.org/de/kb/cookies-loeschen-daten-von-websites-entfernen"
            target="_blank"
            rel="nofollow noopener noreferrer"
          >
            https://support.mozilla.org/de/kb/cookies-loeschen-daten-von-websites-entfernen
          </a>
        </li>
        <li>
          Internet Explorer:{" "}
          <a
            href="https://support.microsoft.com/de-de/help/17442/windows-internet-explorer-delete-manage-cookies"
            target="_blank"
            rel="nofollow noopener noreferrer"
          >
            https://support.microsoft.com/de-de/help/17442/windows-internet-explorer-delete-manage-cookies
          </a>
        </li>
        <li>
          Google Chrome:{" "}
          <a
            href="https://support.google.com/accounts/answer/61416?hl=de"
            target="_blank"
            rel="nofollow noopener noreferrer"
          >
            https://support.google.com/accounts/answer/61416?hl=de
          </a>
        </li>
        <li>
          Opera:{" "}
          <a
            href="http://www.opera.com/de/help"
            target="_blank"
            rel="nofollow noopener noreferrer"
          >
            http://www.opera.com/de/help
          </a>
        </li>
        <li>
          Safari:{" "}
          <a
            href="https://support.apple.com/kb/PH17191?locale=de_DE&viewlocale=de_DE"
            target="_blank"
            rel="nofollow noopener noreferrer"
          >
            https://support.apple.com/kb/PH17191?locale=de_DE&viewlocale=de_DE
          </a>
        </li>
      </ul>
      <h3>Speicherdauer und eingesetzte Cookies:</h3>
      <p>
        Soweit Sie uns durch Ihre Browsereinstellungen oder Zustimmung die
        Verwendung von Cookies erlauben, können folgende Cookies auf unseren
        Webseiten zum Einsatz kommen:
      </p>
      <p></p>
      <h2>Technisch notwendige Cookies </h2>
      <h3>Art und Zweck der Verarbeitung: </h3>
      <p>
        Wir setzen Cookies ein, um unsere Website nutzerfreundlicher zu
        gestalten. Einige Elemente unserer Internetseite erfordern es, dass der
        aufrufende Browser auch nach einem Seitenwechsel identifiziert werden
        kann.
      </p>
      <p>
        Der Zweck der Verwendung technisch notwendiger Cookies ist, die Nutzung
        von Websites für die Nutzer zu vereinfachen. Einige Funktionen unserer
        Internetseite können ohne den Einsatz von Cookies nicht angeboten
        werden. Für diese ist es erforderlich, dass der Browser auch nach einem
        Seitenwechsel wiedererkannt wird.
      </p>
      <p>Für folgende Anwendungen benötigen wir Cookies:</p>
      <p>Übernahme von Spracheinstellungen</p>
      <h3>Rechtsgrundlage und berechtigtes Interesse: </h3>
      <p>
        Die Verarbeitung erfolgt gemäß Art. 6 Abs. 1 lit. f DSGVO auf Basis
        unseres berechtigten Interesses an einer nutzerfreundlichen Gestaltung
        unserer Website.
      </p>
      <h3>Empfänger: </h3>
      <p>
        Empfänger der Daten sind ggf. technische Dienstleister, die für den
        Betrieb und die Wartung unserer Website als Auftragsverarbeiter tätig
        werden.
      </p>
      <p></p>
      <h3>Bereitstellung vorgeschrieben oder erforderlich:</h3>
      <p>
        Die Bereitstellung der vorgenannten personenbezogenen Daten ist weder
        gesetzlich noch vertraglich vorgeschrieben. Ohne diese Daten ist jedoch
        der Dienst und die Funktionsfähigkeit unserer Website nicht
        gewährleistet. Zudem können einzelne Dienste und Services nicht
        verfügbar oder eingeschränkt sein.
      </p>
      <h3>Widerspruch</h3>
      <p>
        Lesen Sie dazu die Informationen über Ihr Widerspruchsrecht nach Art. 21
        DSGVO weiter unten.
      </p>
      <p></p>
      <h2>Registrierung auf unserer Website</h2>
      <h3>Art und Zweck der Verarbeitung:</h3>
      <p>
        Für die Registrierung auf unserer Website benötigen wir einige
        personenbezogene Daten, die über eine Eingabemaske an uns übermittelt
        werden.{" "}
      </p>
      <p>
        Zum Zeitpunkt der Registrierung werden zusätzlich folgende Daten
        erhoben:
      </p>
      <p></p>
      <p>
        Ihre Registrierung ist für das Bereithalten bestimmter Inhalte und
        Leistungen auf unserer Website erforderlich.
      </p>
      <h3>Rechtsgrundlage:</h3>
      <p>
        Die Verarbeitung der bei der Registrierung eingegebenen Daten erfolgt
        auf Grundlage einer Einwilligung des Nutzers (Art. 6 Abs. 1 lit. a
        DSGVO).
      </p>
      <h3>Empfänger:</h3>
      <p>
        Empfänger der Daten sind ggf. technische Dienstleister, die für den
        Betrieb und die Wartung unserer Website als Auftragsverarbeiter tätig
        werden.
      </p>
      <p></p>
      <h3>Speicherdauer:</h3>
      <p>
        Daten werden in diesem Zusammenhang nur verarbeitet, solange die
        entsprechende Einwilligung vorliegt.{" "}
      </p>
      <h3>Bereitstellung vorgeschrieben oder erforderlich:</h3>
      <p>
        Die Bereitstellung Ihrer personenbezogenen Daten erfolgt freiwillig,
        allein auf Basis Ihrer Einwilligung. Ohne die Bereitstellung Ihrer
        personenbezogenen Daten können wir Ihnen keinen Zugang auf unsere
        angebotenen Inhalte gewähren.{" "}
      </p>
      <p></p>
      <h2>Erbringung kostenpflichtiger Leistungen</h2>
      <h3>Art und Zweck der Verarbeitung:</h3>
      <p>
        Zur Erbringung kostenpflichtiger Leistungen werden von uns zusätzliche
        Daten erfragt, wie z.B. Zahlungsangaben, um Ihre Bestellung ausführen zu
        können.
      </p>
      <h3>Rechtsgrundlage:</h3>
      <p>
        Die Verarbeitung der Daten, die für den Abschluss des Vertrages
        erforderlich ist, basiert auf Art. 6 Abs. 1 lit. b DSGVO.
      </p>
      <h3>Empfänger:</h3>
      <p>Empfänger der Daten sind ggf. Auftragsverarbeiter.</p>
      <p></p>
      <h3>Speicherdauer:</h3>
      <p>
        Wir speichern diese Daten in unseren Systemen bis die gesetzlichen
        Aufbewahrungsfristen abgelaufen sind. Diese betragen grundsätzlich 6
        oder 10 Jahre aus Gründen der ordnungsmäßigen Buchführung und
        steuerrechtlichen Anforderungen.
      </p>
      <h3>Bereitstellung vorgeschrieben oder erforderlich:</h3>
      <p>
        Die Bereitstellung Ihrer personenbezogenen Daten erfolgt freiwillig.
        Ohne die Bereitstellung Ihrer personenbezogenen Daten können wir Ihnen
        keinen Zugang auf unsere angebotenen Inhalte und Leistungen gewähren.
      </p>
      <p></p>
      <h2>Newsletter</h2>
      <h3>Art und Zweck der Verarbeitung:</h3>
      <p>
        Für die Zustellung unseres Newsletters erheben wir personenbezogene
        Daten, die über eine Eingabemaske an uns übermittelt werden.
      </p>
      <p>
        Für eine wirksame Registrierung benötigen wir eine valide
        E-Mail-Adresse. Um zu überprüfen, dass eine Anmeldung tatsächlich durch
        den Inhaber einer E-Mail-Adresse erfolgt, setzen wir das
        „Double-Opt-in“-Verfahren ein. Hierzu protokollieren wir die Anmeldung
        zum Newsletter, den Versand einer Bestätigungsmail und den Eingang der
        hiermit angeforderten Antwort. Weitere Daten werden nicht erhoben.{" "}
      </p>
      <h3>Rechtsgrundlage:</h3>
      <p>
        Auf Grundlage Ihrer ausdrücklich erteilten Einwilligung (Art. 6 Abs. 1
        lit. a DSGVO), übersenden wir Ihnen regelmäßig unseren Newsletter bzw.
        vergleichbare Informationen per E-Mail an Ihre angegebene
        E-Mail-Adresse.{" "}
      </p>
      <p>
        Die Einwilligung zur Speicherung Ihrer persönlichen Daten und ihrer
        Nutzung für den Newsletterversand können Sie jederzeit mit Wirkung für
        die Zukunft widerrufen. In jedem Newsletter findet sich dazu ein
        entsprechender Link. Außerdem können Sie sich jederzeit auch direkt auf
        dieser Website abmelden oder uns Ihren Widerruf über die am Ende dieser
        Datenschutzhinweise angegebene Kontaktmöglichkeit mitteilen.
      </p>
      <h3>Empfänger:</h3>
      <p>Empfänger der Daten sind ggf. Auftragsverarbeiter.</p>
      <p></p>
      <h3>Speicherdauer:</h3>
      <p>
        Die Daten werden in diesem Zusammenhang nur verarbeitet, solange die
        entsprechende Einwilligung vorliegt. Danach werden sie gelöscht.
      </p>
      <h3>Bereitstellung vorgeschrieben oder erforderlich:</h3>
      <p>
        Die Bereitstellung Ihrer personenbezogenen Daten erfolgt freiwillig,
        allein auf Basis Ihrer Einwilligung. Ohne bestehende Einwilligung können
        wir Ihnen unseren Newsletter leider nicht zusenden.
      </p>
      <h3>Widerruf der Einwilligung:</h3>
      <p>
        Die Einwilligung zur Speicherung Ihrer persönlichen Daten und ihrer
        Nutzung für den Newsletterversand können Sie jederzeit mit Wirkung für
        die Zukunft widerrufen. Die Abmeldung kann über den in jeder E-Mail
        enthaltenen Link oder beim unten aufgeführten Datenschutzbeauftragten
        bzw. der für den Datenschutz zuständigen Person beantragt werden.{" "}
      </p>
      <p></p>
      <h2>Verwendung von Google Analytics</h2>
      <p>
        Soweit Sie ihre Einwilligung gegeben haben, wird auf dieser Website
        Google Analytics eingesetzt, ein Webanalysedienst der Google LLC, 1600
        Amphitheatre Parkway, Mountain View, CA 94043 USA (nachfolgend:
        „Google“). Google Analytics verwendet sog. „Cookies“, also Textdateien,
        die auf Ihrem Computer gespeichert werden und die eine Analyse der
        Benutzung der Webseite durch Sie ermöglichen. Die durch das Cookie
        erzeugten Informationen über Ihre Benutzung dieser Webseite werden in
        der Regel an einen Server von Google in den USA übertragen und dort
        gespeichert. Aufgrund der Aktivierung der IP-Anonymisierung auf diesen
        Webseiten, wird Ihre IP-Adresse von Google jedoch innerhalb von
        Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten
        des Abkommens über den Europäischen Wirtschaftsraum zuvor gekürzt. Nur
        in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google
        in den USA übertragen und dort gekürzt. Die im Rahmen von Google
        Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit
        anderen Daten von Google zusammengeführt.{" "}
      </p>
      <p>
        Nähere Informationen zu Nutzungsbedingungen und Datenschutz finden Sie
        unter{" "}
        <a
          href="https://www.google.com/analytics/terms/de.html und unter https://policies.google.com/?hl=de"
          rel="noreferrer noopener"
          target="_blank"
        >
          https://www.google.com/analytics/terms/de.html und unter
          https://policies.google.com/?hl=de
        </a>
        .{" "}
      </p>
      <p>
        Im Auftrag des Betreibers dieser Website wird Google diese Informationen
        benutzen, um Ihre Nutzung der Webseite auszuwerten, um Reports über die
        Webseitenaktivitäten zusammenzustellen und um weitere mit der
        Websitenutzung und der Internetnutzung verbundene Dienstleistungen
        gegenüber dem Webseitenbetreiber zu erbringen.{" "}
      </p>
      <p>
        Die von uns gesendeten und mit Cookies, Nutzerkennungen (z. B. User-ID)
        oder Werbe-IDs verknüpften Daten werden nach 14 Monaten automatisch
        gelöscht. Die Löschung von Daten, deren Aufbewahrungsdauer erreicht ist,
        erfolgt automatisch einmal im Monat.
      </p>
      <h3>Widerruf der Einwilligung:</h3>
      <p>
        Sie können das Tracking durch Google Analytics auf unserer Website
        unterbinden, indem Sie{" "}
        {// eslint-disable-next-line jsx-a11y/anchor-is-valid 
        <a
          title="Google Analytics Opt-Out-Cookie setzen"
          onClick={() => {
            optOut();
          }}
          href="#"
        >
          diesen Link anklicken
        </a>
        }
        . Dabei wird ein Opt-out-Cookie auf Ihrem Gerät installiert. Damit wird
        die Erfassung durch Google Analytics für diese Website und für diesen
        Browser zukünftig verhindert, solange das Cookie in Ihrem Browser
        installiert bleibt.
      </p>
      <p>
        Sie können darüber hinaus die Speicherung der Cookies durch eine
        entsprechende Einstellung Ihrer Browser-Software verhindern; wir weisen
        Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht
        sämtliche Funktionen dieser Website vollumfänglich werden nutzen können.{" "}
      </p>
      <p>
        Sie können darüber hinaus die Erfassung der durch das Cookie erzeugten
        und auf Ihre Nutzung der Webseite bezogenen Daten (inkl. Ihrer
        IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google
        verhindern, indem sie das unter dem folgenden Link verfügbare
        Browser-Plugin herunterladen und installieren:{" "}
        <a
          href="http://tools.google.com/dlpage/gaoptout?hl=de"
          rel="noreferrer noopener"
          target="_blank"
        >
          Browser Add On zur Deaktivierung von Google Analytics
        </a>
        .
      </p>
      <p></p>
      <h2>Registrierung und Anmeldung</h2>
      <p>
        Mit der Registrierung oder Anmeldung berechtigen Nutzer diese Anwendung,
        sie zu identifizieren und ihnen Zugriff auf spezifische Dienste zu
        gewähren. Je nachdem, was im Folgenden angegeben ist, können
        Drittanbieter Registrierungs- und Anmeldedienste bereitstellen. In
        diesem Fall kann diese Anwendung auf einige von diesen Drittanbietern
        gespeicherten Daten zu Registrierungs- oder Identifizierungszwecken
        zugreifen. Einige der unten aufgeführten Dienste erheben möglicherweise
        personenbezogene Daten zu Targeting- und Profilingszwecken. Weitere
        Informationen dazu entnehmen Sie der Beschreibung eines jeden Dienstes.
      </p>
      <h2>Verwendung von Scriptbibliotheken (Google Webfonts)</h2>
      <p>
        Um unsere Inhalte browserübergreifend korrekt und grafisch ansprechend
        darzustellen, verwenden wir auf dieser Website „Google Web Fonts“ der
        Google LLC (1600 Amphitheatre Parkway, Mountain View, CA 94043, USA;
        nachfolgend „Google“) zur Darstellung von Schriften.
      </p>
      <p>
        Weitere Informationen zu Google Web Fonts finden Sie unter{" "}
        <a
          href="https://developers.google.com/fonts/faq"
          rel="noopener nofollow noreferrer"
          target="_blank"
        >
          https://developers.google.com/fonts/faq
        </a>{" "}
        und in der Datenschutzerklärung von Google:{" "}
        <a
          href="https://www.google.com/policies/privacy/"
          rel="noopener nofollow noreferrer"
          target="_blank"
        >
          https://www.google.com/policies/privacy/
        </a>
        .
      </p>
      <p></p>
      <h2>SSL-Verschlüsselung</h2>
      <p>
        Um die Sicherheit Ihrer Daten bei der Übertragung zu schützen, verwenden
        wir dem aktuellen Stand der Technik entsprechende
        Verschlüsselungsverfahren (z. B. SSL) über HTTPS.
      </p>
      <p></p>
      <hr />
      <p>Firebase Authentication (Google LLC)</p>
      <p>
        Firebase Authentication ist ein von Google LLC bereitgestellter
        Registrierungs- und Anmeldedienst. Um den Anmelde- und Anmeldeprozess zu
        vereinfachen, kann Firebase Authentication Identitätsdienste von
        Drittparteien benutzen und die Informationen auf ihrer Plattform
        speichern. Verarbeitete personenbezogene Daten: E-Mail; Konten in den
        sozialen Netzwerken; Passwort.
        <br />
        Verarbeitungsort: Deutschland –
        <a href="https://policies.google.com/privacy">Datenschutzerklärung</a>
      </p>
      <hr />
      <h2>Information über Ihr Widerspruchsrecht nach Art. 21 DSGVO</h2>
      <h3>Einzelfallbezogenes Widerspruchsrecht</h3>
      <p>
        Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen
        Situation ergeben, jederzeit gegen die Verarbeitung Sie betreffender
        personenbezogener Daten, die aufgrund Art. 6 Abs. 1 lit. f DSGVO
        (Datenverarbeitung auf der Grundlage einer Interessenabwägung) erfolgt,
        Widerspruch einzulegen; dies gilt auch für ein auf diese Bestimmung
        gestütztes Profiling im Sinne von Art. 4 Nr. 4 DSGVO.
      </p>
      <p>
        Legen Sie Widerspruch ein, werden wir Ihre personenbezogenen Daten nicht
        mehr verarbeiten, es sei denn, wir können zwingende schutzwürdige Gründe
        für die Verarbeitung nachweisen, die Ihre Interessen, Rechte und
        Freiheiten überwiegen, oder die Verarbeitung dient der Geltendmachung,
        Ausübung oder Verteidigung von Rechtsansprüchen.
      </p>
      <h3>Empfänger eines Widerspruchs</h3>
      <p>
        nudge GmbH
        <br />
        Grindelberg 15a
        <br />
        20144 Hamburg
        <br />
        Tel: +49 (0) 40 87 09 94 87
        <br />
        E-Mail: info@nudge.media
      </p>
      <hr />
      <h2>Änderung unserer Datenschutzbestimmungen</h2>
      <p>
        Wir behalten uns vor, diese Datenschutzerklärung anzupassen, damit sie
        stets den aktuellen rechtlichen Anforderungen entspricht oder um
        Änderungen unserer Leistungen in der Datenschutzerklärung umzusetzen,
        z.B. bei der Einführung neuer Services. Für Ihren erneuten Besuch gilt
        dann die neue Datenschutzerklärung.
      </p>
      <h2>Fragen an den Datenschutzbeauftragten</h2>
      <p>
        Wenn Sie Fragen zum Datenschutz haben, schreiben Sie uns bitte eine
        E-Mail oder wenden Sie sich direkt an die für den Datenschutz
        verantwortliche Person in unserer Organisation:
      </p>
      <p>
        nudge GmbH
        <br />
        Grindelberg 15a
        <br />
        20144 Hamburg
        <br />
        Tel: +49 (0) 40 87 09 94 87
        <br />
        E-Mail: info@nudge.media
      </p>
      <p>
        <em>
          Die Datenschutzerklärung wurde mithilfe der activeMind AG erstellt,
          den Experten für{" "}
          <a
            href="https://www.activemind.de/datenschutz/datenschutzbeauftragter/"
            target="_blank"
            rel="noreferrer noopener"
          >
            externe Datenschutzbeauftragte
          </a>{" "}
          (Version #2020-09-30).
        </em>
      </p>
    </div>
  );
}

export default DataProtectionPage;
