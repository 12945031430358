import React, { useState, useEffect } from "react";
import {
  Container,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import Navigation from "../../navigation/Navigation";
import { useRouteLoaderData } from "react-router-dom";
import ChatWithHistory from "./ChatWithHistory";
import OptionSelector from "../../navigation/OptionSelector";
import useApi from "../../../utils/useApi";
import { 
  type IApiBasePathProps, 
  type IAuthData,  
  type ITypeOptions,
  type IToneOptions,
  type IActionOptions,
  type IGptOptions,
} from "../../../utils/interfaces";

const useStyles = makeStyles()((theme) => ({
  container: {
    padding: theme.spacing(4),
    maxWidth: "1400px",
  },
  layoutContainer: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap", // Allow the containers to wrap
    justifyContent: "space-between",
    marginBottom: theme.spacing(0), // Space before the bottomContainer
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(2),
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  optionSelectorsContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "center",
    alignItems: "flex-start",
    flexBasis: "100%",
    [theme.breakpoints.down("lg")]: {
      flexDirection: "column",
      flexBasis: "100%",
      flexWrap: "wrap",
    },
    margin: theme.spacing(0),
  },
}));

function ChatPage({apiBasePath} : IApiBasePathProps) {
  const customFetch = useApi();
  const loaderData = useRouteLoaderData("root") as IAuthData;
  const token = loaderData.token;
  const userData = loaderData.userData;
  const { t } = useTranslation();
  const { classes } = useStyles();
  const [typeOptions, setTypeOptions] = useState<ITypeOptions[]>([]);
  const [toneOptions, setToneOptions] = useState<IToneOptions[]>([]);
  const [actionOptions, setActionOptions] = useState<IActionOptions[]>([]);
  const [gptOptions, setGptOptions] = useState<IGptOptions[]>([]);
  const [action, setAction] = useState(-1);
  const [type, setType] = useState(-1);
  const [tone, setTone] = useState(-1);
  const [gpt, setGpt] = useState<IGptOptions | null>(null);
  const [showGptConfig, setShowGptConfig] = useState(userData.showGptSelector);

  useEffect(() => {
    setShowGptConfig(userData.showGptSelector);
  }, [userData]);

  useEffect(() => {
    const fetchData = async () => {
      const payload = { token: token };
      const body = JSON.stringify(payload);
      const options = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: body,
      };

      try {
        const typeData = await customFetch(
          apiBasePath + `/api/get_types`,
          options
        );
        if (typeData) {
          setTypeOptions(typeData);
        }
      } catch (error) {
        console.log("Error fetching types:", error);
      }
      try {
        const toneData = await customFetch(
          apiBasePath + `/api/get_tones`,
          options
        );
        if (toneData) {
          setToneOptions(toneData);
        }
      } catch (error) {
        console.log("Error fetching tones:", error);
      }
      try {
        const actionData = await customFetch(
          apiBasePath + `/api/get_chat_actions`,
          options
        );
        if (actionData) {
          setActionOptions(actionData);
        }
      } catch (error) {
        console.log("Error fetching chat actions:", error);
      }
      try {
        const gptData = await customFetch(
          apiBasePath + `/api/get_gpt_models`,
          options
        );
        if (gptData) {
          setGptOptions(gptData);
        }
      } catch (error) {
        console.log("Error fetching gpt options:", error);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiBasePath, token]);

  useEffect(() => {
    for (let i = 0; i < actionOptions.length; i++) {
      setAction(actionOptions[i].id);
      break;
    }
  }, [actionOptions]);

  useEffect(() => {
    for (let i = 0; i < typeOptions.length; i++) {
      setType(typeOptions[i].id);
      break;
    }
  }, [typeOptions]);

  useEffect(() => {
    for (let i = 0; i < toneOptions.length; i++) {
      setTone(toneOptions[i].id);
      break;
    }
  }, [toneOptions]);

  useEffect(() => {
    for (let i = 0; i < gptOptions.length; i++) {
      setGpt(gptOptions[i]);
      break;
    }
  }, [gptOptions]);

  const setGptVersion = (id: number) => {
    for (let i = 0; i < gptOptions.length; i++) {
      if (gptOptions[i].id === id) {
        setGpt(gptOptions[i]);
        break;
      }
    }
  }

  const optionSelectors = (
    <div className={classes.optionSelectorsContainer}>
      <OptionSelector
        title={t("actionSelect")}
        options={actionOptions}
        selectedOption={action}
        onOptionClick={setAction}
        colorKey="blueSelect"
        hoverDisplay={true}
      />
      <OptionSelector
        title={t("typeSelect")}
        options={typeOptions}
        selectedOption={type}
        onOptionClick={setType}
        colorKey="blueSelect"
        hoverDisplay={true}
      />
      <OptionSelector
        title={t("toneSelect")}
        options={toneOptions}
        selectedOption={tone}
        onOptionClick={setTone}
        colorKey="blueSelect"
        hoverDisplay={true}
      />
      { showGptConfig ?
      <OptionSelector
        title={t("gptSelect")}
        options={gptOptions}
        selectedOption={gpt === null ? -1 : gpt.id}
        onOptionClick={setGptVersion}
        colorKey="blueSelect"
        hoverDisplay={true}
      />
      : null
      }
    </div>
  );

  return (
    <div>
      <Container className={classes.container} maxWidth={false}>
        <Navigation
          apiBasePath={apiBasePath}
          personalizeElement={<></>}
          chatElement={optionSelectors}
          fileElement={<></>}
          audioElement={<></>}
        />
        <div className={classes.layoutContainer}>
          <ChatWithHistory apiBasePath={apiBasePath} action={action} tone={tone} type={type} gptVersion={gpt} setAction={setAction} setTone={setTone} setType={setType} setGpt={setGptVersion}/>
        </div>

      </Container>
    </div>
  );
}

export default ChatPage;
