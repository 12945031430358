import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Card,
  Typography,
  TextField,
  Button,
  Grid,
  Divider,
} from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { makeStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { useRouteLoaderData, useNavigate } from "react-router-dom";
import profileIcon from "../../../images/profile.png";
import useApi from "../../../utils/useApi";
import { 
  type IAuthData,
  type IProfile,
  type IInvoice,
  type ICard1Props,
  type ICard2Props,
  type ICard3Props,
  type IApiBasePathProps,

} from "../../../utils/interfaces";

const formatDate = (dateString : string) => {
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-indexed
  const year = date.getFullYear();
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");

  return `${day}.${month}.${year} ${hours}:${minutes}`;
};

const useStyles = makeStyles()((theme) => ({
  container: {
    width: "75%", // Set the width to 75%
    margin: "0 auto", // Center the container
    display: "flex",
    flexDirection: "column",
    alignItems: "center", // Center the items (cards) inside the container
  },
  card: {
    width: "100%",
    margin: theme.spacing(2),
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    boxShadow: `0 3px 5px 2px ${theme.palette.profile.shadow}`,
  },
  button: {
    textTransform: "none",
    marginTop: theme.spacing(1),
    backgroundColor: theme.palette.blue.main,
    color: "white",
    height: "40px",
  },
  img: {
    width: "120px",
    height: "auto",
    borderRadius: "50%",
    marginBottom: theme.spacing(1),
    boxShadow: `0 3px 5px 2px ${theme.palette.profile.shadow}`,
  },
  textField: {
    width: "400px",
    [theme.breakpoints.down("sm")]: {
      width: "300px",
    },
  },
  gridContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "50%",
  },
  label: {
    fontWeight: "bold",
    textAlign: "left",
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: "100%",
  },
}));

const Card1 = ({ profile, photoUrl, onSave } : ICard1Props) => {
  const { classes } = useStyles();
  const [editableProfile, setEditableProfile] = useState({ ...profile });
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    setEditableProfile({ ...profile });
  }, [profile]);

  const handleChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setEditableProfile({
      ...editableProfile,
      [event.target.name]: event.target.value,
    });
  };

  const handleSave = () => {
    onSave(editableProfile);
  };

  const handleChangePassword = () => {
    navigate("/changePassword");
  };

  return (
    <Card className={classes.card}>
      <img
        className={classes.img}
        src={photoUrl.replace('"', "").replace("&quot;", "")}
        alt={`${editableProfile.first_name}'s profile`}
      />
      <TextField
        className={classes.textField}
        label={t("firstName")}
        name="first_name"
        value={editableProfile.first_name}
        onChange={(e) => handleChange(e)}
        margin="normal"
      />
      <TextField
        className={classes.textField}
        label={t("lastName")}
        name="last_name"
        value={editableProfile.last_name}
        onChange={(e) => handleChange(e)}
        margin="normal"
      />
      <TextField
        className={classes.textField}
        label={t("email")}
        name="email"
        value={editableProfile.email}
        margin="normal"
        disabled // This makes the field non-editable
        InputProps={{
          readOnly: true, // This ensures the field looks consistent with others
        }}
      />
      <TextField
        className={classes.textField}
        label={t("organisation")}
        name="organisation"
        value={editableProfile.organisation}
        onChange={(e) => handleChange(e)}
        margin="normal"
      />
      <TextField
        className={classes.textField}
        label={t("industry")}
        name="industry"
        value={editableProfile.industry}
        onChange={(e) => handleChange(e)}
        margin="normal"
      />
      <Button
        className={classes.button}
        variant="contained"
        color="primary"
        onClick={handleSave}
      >
        {t("saveChanges")}
      </Button>
      <Button
        className={classes.button}
        variant="contained"
        color="primary"
        onClick={handleChangePassword}
      >
        {t("changePassword")}
      </Button>
    </Card>
  );
};

Card1.propTypes = {
  profile: PropTypes.shape({
    first_name: PropTypes.string.isRequired,
    last_name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    organisation: PropTypes.string,
    industry: PropTypes.string,
  }).isRequired,
  photoUrl: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
};

const Card2 = ({ subscriptions, apiBasePath } : ICard2Props) => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  const renderDetail = (label : string, value : string, isDate = false) => (
    <Grid container spacing={2} alignItems="center" justifyContent="center">
      <Grid item xs={12} sm={6} md={5} className={classes.label}>
        <Typography>{label}</Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={7}>
        <Typography>{isDate ? formatDate(value) : value}</Typography>
      </Grid>
    </Grid>
  );

  return (
    <Card className={classes.card}>
      <Typography variant="h6" gutterBottom>
        {t("subscriptions")}
      </Typography>
      <div className={classes.gridContainer}>
        {subscriptions.map((subscription, index) => (
          <React.Fragment key={subscription.stripe_subscription_id}>
            {index !== 0 && <Divider className={classes.divider} />}
            {renderDetail(
              t("status") + ":",
              t(subscription.subscription_status)
            )}
            {renderDetail(t("orderTime") + ":", subscription.order_time, true)}
            {renderDetail(
              t("currentPeriodStart") + ":",
              subscription.subscription_current_period_start,
              true
            )}
            {renderDetail(
              t("currentPeriodEnd") + ":",
              subscription.subscription_current_period_end,
              true
            )}
            <form
              action={`${apiBasePath}/api/create-portal-session`}
              method="POST"
            >
              <input
                type="hidden"
                id="session-id"
                name="session_id"
                value={
                  subscription.stripe_checkout_session_id != null
                    ? subscription.stripe_checkout_session_id
                    : ""
                }
              />
              <input
                type="hidden"
                id="return-url"
                name="return_url"
                value={window.location.href ? window.location.href : ""}
              />
              <Button
                className={classes.button}
                variant="contained"
                color="primary"
                id="portal-button"
                type="submit"
              >
                {t("manageSubscription")}
              </Button>
            </form>
          </React.Fragment>
        ))}
      </div>
    </Card>
  );
};

Card2.propTypes = {
  subscriptions: PropTypes.arrayOf(
    PropTypes.shape({
      stripe_subscription_id: PropTypes.string.isRequired,
      subscription_status: PropTypes.string.isRequired,
      order_time: PropTypes.string.isRequired,
      subscription_current_period_start: PropTypes.string.isRequired,
      subscription_current_period_end: PropTypes.string.isRequired,
      stripe_checkout_session_id: PropTypes.string,
    })
  ),
  apiBasePath: PropTypes.string.isRequired,
};

const Card3 = ({ invoices } : ICard3Props) => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  const renderInvoiceDetail = (invoice : IInvoice) => (
    <React.Fragment key={invoice.invoice_number}>
      <Grid container spacing={2} alignItems="center" justifyContent="center">
        <Grid item xs={12} sm={6} md={5} className={classes.label}>
          <Typography>{t("paidAt") + ":"}</Typography>
          <Typography>{t("invoiceNumber") + ":"}</Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={7}>
          <Typography>{formatDate(invoice.paid_at)}</Typography>
          <Typography>{invoice.invoice_number}</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            startIcon={<FileDownloadIcon />}
            onClick={() => window.open(invoice.invoice_pdf_url, "_blank")}
          >
            {t("downloadPDf")}
          </Button>
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            onClick={() => window.open(invoice.hosted_invoice_url, "_blank")}
          >
            {t("manageInvoices")}
          </Button>
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
    </React.Fragment>
  );

  return (
    <Card className={classes.card}>
      <Typography variant="h6" gutterBottom>
        {t("invoices")}
      </Typography>
      <div className={classes.gridContainer}>
        {invoices.map(renderInvoiceDetail)}
      </div>
    </Card>
  );
};

Card3.propTypes = {
  invoices: PropTypes.arrayOf(
    PropTypes.shape({
      paid_at: PropTypes.string.isRequired,
      invoice_number: PropTypes.string.isRequired,
      invoice_pdf_url: PropTypes.string.isRequired,
      hosted_invoice_url: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default function ProfilePage({ apiBasePath } : IApiBasePathProps) {
  const customFetch = useApi();
  const loaderData = useRouteLoaderData("root") as IAuthData;
  const token = loaderData.token;
  const photoUrl =
    loaderData.photoURL !== null
      ? loaderData.photoURL.replace('"', "").replace("&quot;", "")
      : profileIcon;
  const { classes } = useStyles();

  const [profileData, setProfileData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    organisation: "",
    industry: "",
  });
  const [subscriptionData, setSubscriptionData] = useState([]);
  const [invoicesData, setInvoicesData] = useState([]);

  useEffect(() => {
    const fetchSubscriptionData = async () => {
      try {
        const payload = {
          token: token,
        };

        const body = JSON.stringify(payload);
        const options = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: body,
        };

        const url = `${apiBasePath}/api/get_user_subscriptions`;
        //console.log(url);

        const userSubscriptionData = await customFetch(url, options);

        if (
          userSubscriptionData.length === 0 ||
          (userSubscriptionData[0].stripe_checkout_session_id === null &&
            userSubscriptionData[0].stripe_subscription_id === null)
        ) {
          //console.log('No subscription data found');
          setSubscriptionData([]);
        } else {
          //console.log(userSubscriptionData);
          setSubscriptionData(userSubscriptionData);
        }
      } catch (error) {
        console.log("Error fetching options:", error);
      }
    };

    fetchSubscriptionData();

    const fetchProfileData = async () => {
      try {
        const payload = {
          token: token,
        };

        const body = JSON.stringify(payload);
        const options = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: body,
        };
        const url = apiBasePath + `/api/get_user_profile`;
        //console.log(url);
        const userProfileData = await customFetch(url, options);
        //console.log(userProfileData);

        setProfileData(userProfileData);
      } catch (error) {
        console.log("Error fetching options:", error);
      }
    };
    fetchProfileData();

    const fetchInvoicesData = async () => {
      try {
        const payload = {
          token: token,
        };

        const body = JSON.stringify(payload);
        const options = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: body,
        };
        const url = apiBasePath + `/api/get_user_invoices`;
        //console.log(url);

        const invoicesData = await customFetch(url, options);
        //console.log(invoicesData);

        if (invoicesData.length === 0 || invoicesData[0].paid_at === null) {
          setInvoicesData([]);
        } else {
          setInvoicesData(invoicesData);
        }
      } catch (error) {
        console.log("Error fetching options:", error);
      }
    };
    fetchInvoicesData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiBasePath, token]);

  const handleSaveProfile = async (updatedProfile : IProfile) => {
    //console.log('Saving profile:', updatedProfile);

    //call save_change in backend
    const payload = {
      token: token,
      first_name: updatedProfile.first_name,
      last_name: updatedProfile.last_name,
      organisation: updatedProfile.organisation,
      industry: updatedProfile.industry,
    };
    const body = JSON.stringify(payload);
    const options = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: body,
    };
    const url = apiBasePath + `/api/save_profile`;

    const saveProfileData = await customFetch(url, options);
    //console.log(saveProfileData);
    if (saveProfileData.success) {
      setProfileData(updatedProfile);
    }
  };

  return (
    <div className={classes.container}>
      <Card1
        profile={profileData}
        photoUrl={photoUrl}
        onSave={handleSaveProfile}
      />
      <Card2 subscriptions={subscriptionData} apiBasePath={apiBasePath} />
      <Card3 invoices={invoicesData} />
    </div>
  );
}

ProfilePage.propTypes = {
  apiBasePath: PropTypes.string.isRequired,
};
