import React, {useState, useEffect, useRef, useCallback} from 'react';
import {  
  Snackbar,
  Container,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useTranslation } from "react-i18next";
import { useRouteLoaderData } from "react-router-dom";
import ChatWithHistoryComponent from '../chat/ChatWithHistoryComponent';
import useApi from "../../../utils/useApi";
import { 
  type IChatFileProps, 
  type IAuthData, 
  type IChatData, 
  type IMessage, 
  type SnackbarColor, 
  type ITypeOptions, 
  type IToneOptions, 
  type IGptOptions, 
  type IActionOptions, 
  type IRenameDialogProps,
} from '../../../utils/interfaces';
import FileViewer from './FileViewer';
import ClearInputContext from '../chat/ClearInputContext';

const useStyles = makeStyles()((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(0),
    marginRight: theme.spacing(0),
    marginLeft: theme.spacing(0),
    // paddingRight: 0,
    width: "100%",
    display: "flex",
    flexDirection: "row", 
  },
  content: {
    width: "100%",
    display: "grid",
    gridTemplateColumns: "45% 55%",
    [theme.breakpoints.down("md")]: {
      display: "flex",
      flexDirection: "column", 
    },
  },
  chat: {
    width: "100%", // Chat takes up 33% of the container width
    paddingRight: theme.spacing(2), // Add some spacing between chat and file sections
    overflow: "auto", // In case messages overflow
    [theme.breakpoints.down("md")]: {
      width: "100%", 
      paddingRight: 0, 
    },
  },
  file: {
    width: "100%", // File takes up the remaining space
    overflow: "auto", // Handles overflow for large files
    [theme.breakpoints.down("md")]: {
      width: "100%", 
    },
  },
  iframe: {
    width: "100%",
    height: "800px",
  },
  message: {
    padding: theme.spacing(2),
    textAlign: "center",
  },
  cancelButton: {
    fontFamily: theme.typography.button.fontFamily,
    textTransform: "none",
    backgroundColor: theme.palette.blue.main,
    color: "white",
  },
  confirmDeletionButton: {
    fontFamily: theme.typography.button.fontFamily,
    textTransform: "none",
    backgroundColor: theme.palette.red.main,
    color: "white",
  },
}));

function ChatFile ({ apiBasePath, file } : IChatFileProps) {
  const customFetch = useApi();
  const loaderData = useRouteLoaderData("root") as IAuthData;
  const token = loaderData.token;
  const userData = loaderData.userData;
  const { t } = useTranslation();
  const { classes } = useStyles();
  const [isTyping, setIsTyping] = useState(false);
  const [history, setHistory] = useState<IChatData[]>([]);
  const [messages, setMessages] = useState<IMessage[]>([]);
  const [currentChatId, setCurrentChatId] = useState(-1);
  const isStreaming = userData.useStreaming;
  const [key, setKey] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [chatIdToDelete, setChatIdToDelete] = useState(-1);
  const [chatHistoryIndex, setChatHistoryIndex] = useState(-1);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarColor, setSnackbarColor] = useState<SnackbarColor>(""); 
  const [openRenameDialog, setOpenRenameDialog] = useState(false);
  const [initialName, setInitialName] = useState("");
  const [chatIdToRename, setChatIdToRename] = useState<number | null>(null);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const [startScrolling, setStartScrolling] = useState(false);
  const [typeOptions, setTypeOptions] = useState<ITypeOptions[]>([]);
  const [toneOptions, setToneOptions] = useState<IToneOptions[]>([]);
  const [actionOptions, setActionOptions] = useState<IActionOptions[]>([]);
  const [gptOptions, setGptOptions] = useState<IGptOptions[]>([]);
  const [action, setAction] = useState(-1);
  const [type, setType] = useState(-1);
  const [tone, setTone] = useState(-1);
  const [gpt, setGpt] = useState(-1);
  const [gptName, setGptName] = useState("");
  const [gptIsGRDPCompliant, setGptIsGRDPCompliant] = useState(true);

  const clearInput = useCallback(() => {
    // Increment key to force re-render and thus clear the input
    setKey(prevKey => prevKey + 1);
  }, []);

  const showSnackbar = (message: string, color: SnackbarColor) => {
    setSnackbarMessage(message);
    setSnackbarColor(color);
    setSnackbarOpen(true);
  };

  const scrollToBottom = () => {
    if (!startScrolling) {
      return;
    }
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setChatIdToDelete(-1);
    setOpenDialog(false);
  };

  const handleRenameDialogClose = () => {
    setInitialName("");
    setOpenRenameDialog(false);
  };

  const handleRenameDialogConfirm = (newName: string) => {
    if (chatIdToRename !== null) {
      renameChatHistoryItem(chatIdToRename, newName);
    }
    setOpenRenameDialog(false);
  };

  useEffect(() => {
    scrollToBottom();
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages, isTyping]); 


  const processChatHistory = async () => {
    if (file === null || file === undefined) {
      setHistory([]);
      return;
    }

    try {
      const payload = {
        token: token,
      };
      const body = JSON.stringify(payload);
      const options = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: body,
      };
      const url = apiBasePath + `/api/file/chat/history/${file?.id}`;
      const data = await customFetch(url, options);

      let newHistory : IChatData[] = [];
      const chats = data.result;

      if (chats === null || chats === undefined) {
        setHistory(newHistory);
        return;
      }

      if (chats.length > 0) {
        // console.log("chats length" + chats.length);
        for (let i = 0; i < chats.length; i++) {
          // console.log(chats[i]);
          let chat = chats[i];
          if (chat.messages.length > 0) {
            const chatId = chat.chatId;
            const summary = chat.summary;
            const name = chat.name;
            let newChat : IChatData = {
              chatId,
              summary,
              chatActionId: chat.chatActionId,
              typeId: chat.typeId,
              toneId: chat.toneId,
              name,
              messages: [],
              gptVersionId: chat.gptVersionId,
            };

            for (let j = 0; j < chat.messages.length; j++) {
              const message = chat.messages[j];
              if (message.role === "system") {
                continue;
              }
              //console.log(message);
              newChat.messages.push({
                role: message.role,
                content: message.content,
                id: message.id,
              });
            }

            newHistory.push(newChat);
          }
        }

        setHistory(newHistory); // Update the state once with the new history
      }
      else {
        setHistory([]);
      }
    } catch (error) {
      setHistory([]);
      console.log(error);
    }
  };

  const appendMessage = (text: string, id: number) => {
    setMessages((currentMessages) => {
      // Find the index of the message we want to update
      const messageIndex = currentMessages.findIndex((m) => m.id === id);
      if (messageIndex === -1) return currentMessages; // If not found, return the current state
  
      // Create a new message object with updated content
      const updatedMessage = {
        ...currentMessages[messageIndex],
        content: currentMessages[messageIndex].content + text,
      };
  
      // Create a new array with the updated message
      const updatedMessages = [
        ...currentMessages.slice(0, messageIndex),
        updatedMessage,
        ...currentMessages.slice(messageIndex + 1),
      ];
  
      return updatedMessages;
    });
  };

  const handleSubmit = async (input : string) => {
    const prompt = {
      role: "user",
      content: input,
    };

    const localMessages = [...messages, prompt];

    const body = JSON.stringify({
      chatId: currentChatId,
      token: token,
      chatActionId: action,
      typeId: type,
      toneId: tone,
      messages: localMessages,
      message: input,
      gptVersion: gptName,
      isStreaming: isStreaming
    });
    setStartScrolling(true);
    setIsTyping(true);
    showSnackbar(t("sendingChat"), "success");

    try {
      const url = apiBasePath + `/api/file/chat/${file?.id}/${file?.type}`;
      if (isStreaming) {
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: body,
        });
        const reader = response.body?.getReader();
        if (!reader) return;
        setIsTyping(false);
        const decoder = new TextDecoder();
      
        setMessages(localMessages);
      
        // add new message to messages
        const newId = Math.random() + 1000;
        const newMessage = { id: newId, role: "assistant", content: "" };
        setMessages((messages) => [...messages, newMessage]);
        processChatHistory();
        clearInput();
      
        let accumulatedText = '';
      
        // Read the stream
        while (true) {
          const { done, value } = await reader.read();
          if (done) break;
      
          const chunk = decoder.decode(value, { stream: true });
          accumulatedText += chunk.replace(/[\n\r]+/g, '\\n'); // Escape new lines and carriage returns
      
          // Split based on the pattern and ignore the last, possibly incomplete, chunk
          const completeChunks = accumulatedText.split(/(?<=\})/);
          accumulatedText = completeChunks.pop() || ''; // Keep the last incomplete part for accumulation
      
          completeChunks.forEach((chunk : string) => {
            try {
              const obj = JSON.parse(chunk);
              //console.log(obj); // Now we can safely log the parsed object
              const chatId = obj.chat_id;
              if (chatId !== currentChatId) {
                setCurrentChatId(chatId);
              }
              const token = obj.token;
              appendMessage(token, newId);
            } catch (e) {
              console.error('Error parsing chunk:', e, 'Chunk:', chunk);
            }
          });
        }
        processChatHistory();
        console.log('Stream complete');
      }
      else {
        const data = await customFetch(url, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: body,
        });
        if (!data) return; // early return if fetch was unsuccessful

        const content = data.result;
        setIsTyping(false);

        if (content === null || data.status === "failed") {
          showSnackbar(t("errorProcessingMessage"), "error");
        } else {
          const chatId = data.chatId;
          setCurrentChatId(chatId);
          setMessages(localMessages);
          setMessages((messages) => [
            ...messages,
            {
              role: "assistant",
              content: content,
            },
          ]);
          processChatHistory();
          clearInput();
        }
      }
    } catch (error) {
      setIsTyping(false);
      console.log(error);
      showSnackbar(t("errorProcessingMessage"), "error");
    }
  };

  const selectChatFromHistory = (selectedChat : IChatData) => {
    setStartScrolling(false);
    let chatIndex = history.findIndex(
      (chat) => chat.chatId === selectedChat.chatId
    );
    if (chatIndex !== -1) {
      let chat = history[chatIndex];
      //console.log(chat);
      setAction(chat.chatActionId);
      setType(chat.typeId);
      setTone(chat.toneId);
      setCurrentChatId(chat.chatId);
      setMessages(chat.messages);
      setGpt(chat.gptVersionId);
    }
  };

  const deleteChatFromHistory = async (chatId : number, index : number) => {
    setChatIdToDelete(chatId);
    setChatHistoryIndex(index);
    handleOpenDialog();
  };

  const onRenameChatFromHistory = (chatId : number, chatName : string) => {
    setChatIdToRename(chatId);
    setInitialName(chatName);
    setOpenRenameDialog(true);
  }

  const handleConfirmDelete = async () => {
    const payload = {
      token: token,
    };
    const body = JSON.stringify(payload);
    const url = apiBasePath + `/api/chat/${chatIdToDelete}`;

    try {
      const data = await customFetch(url, {
        method: "DELETE",
        headers: { "Content-Type": "application/json" },
        body: body,
      });

      if (!data) return; // early return if fetch was unsuccessful

      const newHistory = [...history];
      newHistory.splice(chatHistoryIndex, 1);
      setHistory(newHistory);
      if (currentChatId === chatIdToDelete) {
        setCurrentChatId(-1);
        setMessages([]);
      }

      // Process the successful response
      // console.log(data);
      showSnackbar(t("chatDeleted"), "success");
    } catch (error) {
      console.log(error);
      showSnackbar(t("errorDeletingChat"), "error");
    }
    handleCloseDialog();
  };

  const renameChatHistoryItem = async (chatId : number, newName : string) => {
    const payload = {
      token: token,
      name: newName,
    };
    const body = JSON.stringify(payload);

    const url = apiBasePath + `/api/rename/chat/${chatId}`;
    try {
      const data = await customFetch(url, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: body,
      });

      if (!data) return; // early return if fetch was unsuccessful

      const newHistory = [...history];
      for (let i = 0; i < newHistory.length; i++) {
        if (newHistory[i].chatId === chatId) {
          newHistory[i].name = newName;
          break;
        }
      }
      //newHistory[id].name = newName;
      setHistory(newHistory);

      // Process the successful response
      // console.log(data);
      showSnackbar(t("chatRenamed"), "success");
    } catch (error) {
      console.log(error);
      showSnackbar(t("errorRenamingChat"), "error");
    }
  };

  const newChat = () => {
    setMessages([]);
    setCurrentChatId(-1);
    clearInput();
  };

  const RenameDialog = React.memo(
    ({ open, onClose, onConfirm, initialName } : IRenameDialogProps) => {
      const [tempName, setTempName] = useState(initialName);

      useEffect(() => {
        setTempName(initialName); // Reset tempName when the dialog opens
      }, [initialName, open]);

      const handleConfirmClick = () => {
        onConfirm(tempName); // Pass the temporary name to the confirm handler
        onClose(); // Close the dialog
      };

      const handleKeyDown = (event : React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === "Enter") {
          event.preventDefault(); // Prevent form submission if within a form
          handleConfirmClick();
        }
      };

      return (
        <Dialog open={open} onClose={onClose}>
          <DialogTitle>{t("renamePrompt")}</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              label={t("newName")}
              type="text"
              fullWidth
              variant="standard"
              value={tempName}
              onChange={(e) => setTempName(e.target.value)}
              onKeyDown={handleKeyDown}
            />
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              color="primary"
              className={classes.cancelButton}
              onClick={onClose}
            >
              {t("cancel")}
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={classes.cancelButton}
              onClick={handleConfirmClick}
            >
              {t("rename")}
            </Button>
          </DialogActions>
        </Dialog>
      );
    }
  );

  const DeleteDialog = () => (
    <Dialog open={openDialog} onClose={handleCloseDialog}>
      <DialogTitle>{t("confirmDeletion")}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t("areYouReallySureYouWantToDeleteThisItem")}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          className={classes.cancelButton}
          onClick={handleCloseDialog}
        >
          {t("cancel")}
        </Button>
        <Button
          variant="contained"
          color="tertiary"
          className={classes.confirmDeletionButton}
          onClick={handleConfirmDelete}
        >
          {t("delete")}
        </Button>
      </DialogActions>
    </Dialog>
  );

  useEffect(() => {
    newChat();
    try {
        processChatHistory();
    } catch (error) {
      console.log("Error fetching history:", error);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  useEffect(() => {
    const fetchData = async () => {
      const payload = { token: token };
      const body = JSON.stringify(payload);
      const options = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: body,
      };

      try {
        const typeData = await customFetch(
          apiBasePath + `/api/get_types`,
          options
        );
        if (typeData) {
          setTypeOptions(typeData);
        }
      } catch (error) {
        console.log("Error fetching types:", error);
      }
      try {
        const toneData = await customFetch(
          apiBasePath + `/api/get_tones`,
          options
        );
        if (toneData) {
          setToneOptions(toneData);
        }
      } catch (error) {
        console.log("Error fetching tones:", error);
      }
      try {
        const actionData = await customFetch(
          apiBasePath + `/api/get_chat_actions`,
          options
        );
        if (actionData) {
          setActionOptions(actionData);
        }
      } catch (error) {
        console.log("Error fetching chat actions:", error);
      }
      try {
        const gptData = await customFetch(
          apiBasePath + `/api/get_gpt_models`,
          options
        );
        if (gptData) {
          setGptOptions(gptData);
        }
      } catch (error) {
        console.log("Error fetching gpt options:", error);
      }

      try {
        processChatHistory();
      } catch (error) {
        console.log("Error fetching history:", error);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiBasePath, token]);

  useEffect(() => {
    for (let i = 0; i < actionOptions.length; i++) {
      setAction(actionOptions[i].id);
      break;
    }
  }, [actionOptions]);

  useEffect(() => {
    for (let i = 0; i < typeOptions.length; i++) {
      setType(typeOptions[i].id);
      break;
    }
  }, [typeOptions]);

  useEffect(() => {
    for (let i = 0; i < toneOptions.length; i++) {
      setTone(toneOptions[i].id);
      break;
    }
  }, [toneOptions]);

  useEffect(() => {
    for (let i = 0; i < gptOptions.length; i++) {
      setGpt(gptOptions[i].id);
      setGptName(gptOptions[i].name);
      setGptIsGRDPCompliant(gptOptions[i].isGRDPCompliant);
      break;
    }
  }, [gptOptions]);

  useEffect(() => {
    if (gptOptions.length > 0) {
        for (let i = 0; i < gptOptions.length; i++) {
          if (gptOptions[i].id === gpt) {
            setGptName(gptOptions[i].name);
            setGptIsGRDPCompliant(gptOptions[i].isGRDPCompliant);
            break;
          }
        }
    }
  }, [gpt, gptOptions]);

  return (
    <Container className={classes.container} maxWidth={false}>
      <div className={classes.content}>
        <div className={classes.chat}>
          <ClearInputContext.Provider value={{ clearInput }}>
            <ChatWithHistoryComponent 
              key={key} messages={messages} newChat={newChat} isTyping={isTyping} 
              handleSubmit={handleSubmit} gptIsGRDPCompliant={gptIsGRDPCompliant} 
              messagesEndRef={messagesEndRef} 
              history={history} selectChatFromHistory={selectChatFromHistory} deleteChatFromHistory={deleteChatFromHistory}
              onRenameChatFromHistory={onRenameChatFromHistory}
            />
          </ClearInputContext.Provider>
          <DeleteDialog />
          <RenameDialog
            open={openRenameDialog}
            onClose={handleRenameDialogClose}
            onConfirm={handleRenameDialogConfirm}
            initialName={initialName}
          />
        </div>
        <div className={classes.file}>
          { (file !== null && file.url !== null && file.url !== "") ? (
            <FileViewer fileUrl={file.url} fileType={file.type} />
          ) : <div className={classes.message}>{t('pleaseSelectAFile')}</div>
          }
        </div>
      </div>
      <Snackbar
          open={snackbarOpen}
          autoHideDuration={5000}
          onClose={() => setSnackbarOpen(false)}
          message={snackbarMessage}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          ContentProps={{
            style: {
              backgroundColor: snackbarColor === "success" ? "green" : "red",
            },
          }}
        />

    </Container>
  );
};

export default ChatFile;