import React, { useState } from "react";
import { makeStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import History from "./History";
import { 
  type IChatWithHistoryComponentProps,
  type IChatData,
  type IStyleProps,
} from "../../../utils/interfaces";
import ChatComponent from "./ChatComponent";
import { IconButton } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

//import "./chatpage.module.css";

const useStyles = makeStyles<IStyleProps>()((theme, { isExpanded }) => ({

  layoutContainer: {
    display: "grid",
    gridTemplateColumns: isExpanded ? "auto 200px" : "auto 20px ",
    gap: "20px",
    maxWidth: "1400px",
    margin: "0 auto",
    //minHeight: "100vh",
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(0),
    border: "0px",
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "auto",
      minHeight: 0,
      paddingLeft: theme.spacing(2),
    },
  },

  column: {
    color: "white",
    border: 0,
  },
  smallColumn: {
    color: "white",
    border: 0,
    width: "20px",
  },
  titleContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(0),
  },
  title: {
    padding: "0px",
    marginBottom: "20px",
    borderRadius: "10px",
    color: "black",
    backgroundColor: "white",
  },
  content: {
    maxHeight: "calc(100vh - 300px)",
    overflowY: "scroll",
    marginBottom: "20px",
    border: "0px",
  },
  optionSelectorsContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "center",
    alignItems: "flex-start",
    flexBasis: "100%",
    [theme.breakpoints.down("lg")]: {
      flexDirection: "column",
      flexBasis: "100%",
      flexWrap: "wrap",
    },
    margin: theme.spacing(0),
  },
  btnNew: {
    fontFamily: theme.typography.button.fontFamily,
    textTransform: "none",
    marginTop: theme.spacing(0),
    backgroundColor: theme.palette.blue.main,
  },
  cancelButton: {
    fontFamily: theme.typography.button.fontFamily,
    textTransform: "none",
    backgroundColor: theme.palette.blue.main,
    color: "white",
  },
  confirmDeletionButton: {
    fontFamily: theme.typography.button.fontFamily,
    textTransform: "none",
    backgroundColor: theme.palette.red.main,
    color: "white",
  },
  historyHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "left",
    alignItems: "center",
    marginBottom: theme.spacing(0),
  },
}));


function ChatWithHistoryComponent({messages, newChat, isTyping, handleSubmit, gptIsGRDPCompliant, messagesEndRef,
    history, selectChatFromHistory, deleteChatFromHistory, onRenameChatFromHistory} : IChatWithHistoryComponentProps) {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const { classes } = useStyles({ isExpanded: isOpen});

  const handleDrawerClose = () => {
    console.log("close");
    setIsOpen(false);
  };

  const handleDrawerOpen = () => {
    console.log("open");
    setIsOpen(true);
  };

  return (
    <div className={classes.layoutContainer}>
      <div className={classes.column}>
          <ChatComponent messages={messages} newChat={newChat} isTyping={isTyping} handleSubmit={handleSubmit} gptIsGRDPCompliant={gptIsGRDPCompliant} messagesEndRef={messagesEndRef} />
      </div>
      { isOpen ?
        <div className={classes.column}>
          <div className={classes.historyHeader}>
            {
              isOpen ? 
              <IconButton onClick={handleDrawerClose}>
                <ChevronLeftIcon />
              </IconButton> : null
            }
            <h3 className={classes.title}>{t("history")}</h3>
          </div>
          <div className={classes.content}>
            {history.map((el : IChatData, i : number) => {
              return (
                <History
                  key={i}
                  index={i}
                  chat={el}
                  onClick={() => selectChatFromHistory(el)}
                  onDelete={(index) => {
                    deleteChatFromHistory(el.chatId, index);
                  }}
                  onRename={(index) => onRenameChatFromHistory(el.chatId, el.name)}
                />
              );
            })}
          </div>
        </div>
        :  
        <div className={classes.smallColumn}>          
          <IconButton onClick={handleDrawerOpen}>
            <ChevronRightIcon />
          </IconButton> 
        </div>
      }
    </div>
  );
}

export default ChatWithHistoryComponent;
