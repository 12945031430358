import { auth } from "../../../utils/FirebaseAuth";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth";
import { json, redirect } from "react-router-dom";
import { apiBasePath } from "../../../utils/constants";
import AuthForm from "./AuthForm";
import { storeTokenEncrypted, storeUserDataEncrypted } from "../../../utils/auth";
import { isResponseWithMessage, isErrorWithCode } from "../../../utils/generalFunctions";

function AuthPage() {
  return <AuthForm />;
}

export default AuthPage;

// Enhanced action function with async/await and proper redirect handling
export async function action({ request } : { request: Request}) {
  const searchParams = new URL(request.url).searchParams;
  const mode = searchParams.get("mode") || "login";

  // Guard clause for unsupported modes
  if (!["login", "signup"].includes(mode)) {
    return json({ message: "Unsupported mode." }, { status: 422 });
  }

  const formData = await request.formData();
  // Handling signup and login with a single flow
  const page = mode === "signup" ? await createUser(formData) : await signInUser(formData);

  if (typeof page === "string" && page.startsWith("/")) {
    return redirect(page); // Properly returning the redirect
  }

  return json({ message: page }, { status: 422 });
}

// Refactored signInUser function for clarity and error handling
async function signInUser(formData: FormData) {
  const email = formData.get("email") as string;
  const password = formData.get("password") as string;

  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;
    const token = await user.getIdToken(false);

    // Simplifying API call logic and error handling
    const response = await fetch(`${apiBasePath}/api/login`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ email, token }),
    });

    if (response.status === 400 || response.status === 401) {
      console.log(response);
      return response.statusText;
    }

    if (!response.ok) {
      console.log(`API call failed with status: ${response.status}`, response);
      return response.statusText || "API call failed";
    }

    const { user: dbUser } = await response.json();
    storeUserDataEncrypted(dbUser);
    storeTokenEncrypted(token);

    // Additional checks and localStorage handling
    if (user.photoURL) localStorage.setItem("photoURL", JSON.stringify(user.photoURL));
    const expiration = new Date();
    expiration.setHours(expiration.getHours() + 1);
    localStorage.setItem("expiration", expiration.toISOString());

    return "/personalize";
  } catch (error) {
    console.error("Error during signInUser:", error);
    if (isErrorWithCode(error)) {
      // Handling specific Firebase auth errors
      return handleFirebaseAuthError(error.code);
    }
    return "Unexpected error during sign-in";
  }
}

// Enhanced createUser function with async/await and comprehensive error handling
async function createUser(formData: FormData) {
  const email = formData.get("email") as string;
  const password = formData.get("password") as string;
  const firstName = formData.get("firstName") as string;
  const lastName = formData.get("lastName") as string;
  const organisation = formData.get("organisation") as string;
  const industry = formData.get("industry") as string;
  try {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    const user = userCredential.user;
    const token = await user.getIdToken(false);

    // Constructing user data for backend registration
    const authData = { email, token, firstName, lastName, organisation, industry };

    // Attempt to register user in backend
    const response = await fetch(`${apiBasePath}/api/register`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(authData),
    });

    if (response.status === 400 || response.status === 401) {
      if (isResponseWithMessage(response)) {
        return response.message;
      }
      return response.statusText;
    }

    if (!response.ok) {
      console.log(`Registration API call failed with status: ${response.status}`, response);
      if (isResponseWithMessage(response)) {
        return "Could not create user in the backend. " + response.message;
      }
      return "Could not create user in the backend. " + response.statusText;
    }

    const { user: dbUser } = await response.json();
    storeUserDataEncrypted(dbUser);
    storeTokenEncrypted(token);

    // Handling user photoURL and setting expiration in localStorage
    if (user.photoURL) localStorage.setItem("photoURL", JSON.stringify(user.photoURL));
    const expiration = new Date();
    expiration.setHours(expiration.getHours() + 1);
    localStorage.setItem("expiration", expiration.toISOString());

    // Sending email verification
    if (user) {
      await sendEmailVerification(user);
    } else {
      console.error("Error sending email verification: currentUser is null");
    }

    return "/personalize";
  } catch (error) {
    console.error("Error during createUser:", error);
    if (isErrorWithCode(error)) {
      return handleFirebaseAuthError(error.code);
    }
  }
}

// Helper function for handling Firebase auth error codes
function handleFirebaseAuthError(errorCode: string): string {
  switch (errorCode) {
    case "auth/email-already-in-use":
      return "emailAlreadyInUse";
    case "auth/invalid-credential":
      return "invalidLoginCredentials";
    case "auth/user-not-found":
      return "userNotFound";
    default:
      return "errorCreatingAccount";
  }
}

export const sendPasswordResetEmail = async (email : string) => {
  sendPasswordResetEmail(email)
    .then(() => {
      // Password reset email sent!
      // ..
    })
    .catch((error) => {
      const errorCode = error.code;
      console.log(
        "Error sending password reset email: ErrorCode: " + errorCode
      );
      if (errorCode === "auth/invalid-email") {
        console.log("Invalid email");
      } else if (errorCode === "auth/user-not-found") {
        console.log("User not found");
      }
      const errorMessage = error.message;
      console.log(
        "Error sending password reset email: ErrorMessage: " + errorMessage
      );
      console.error("Error sending password reset email: ", error);
    });
};