import React, { useState, useEffect } from "react";
import { marked } from "marked";
import DOMPurify from "dompurify";
import { type IMarkdownRendererProps } from "../../../utils/interfaces";

const MarkdownRenderer = ({ text }: IMarkdownRendererProps) => {
  const [htmlContent, setHtmlContent] = useState("");

  useEffect(() => {
    const convertMarkdownToHtml = async () => {
      const rawMarkup = await marked(text); // Assuming marked isn't actually async in this example
      const sanitizedMarkup = DOMPurify.sanitize(rawMarkup);
      setHtmlContent(sanitizedMarkup);
    };

    convertMarkdownToHtml();
  }, [text]);

  return <div dangerouslySetInnerHTML={{ __html: htmlContent }} />;
};

export default MarkdownRenderer;
