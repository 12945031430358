import { auth, googleProvider } from "../../../utils/FirebaseAuth";
import { signInWithPopup, getAdditionalUserInfo } from "firebase/auth";
import { redirect } from "react-router-dom";
import { apiBasePath } from "../../../utils/constants";
import { storeTokenEncrypted, storeUserDataEncrypted } from "../../../utils/auth";

export async function action() {
  const result = await signInWithGoogle();
  if (result) {
    return redirect("/personalize");
  }
  return redirect("/auth?mode=login");
}

const signInWithGoogle = async () => {

  try {
    const result = await signInWithPopup(auth, googleProvider);

    // This gives you a Google Access Token. You can use it to access the Google API.
    //const credential = GoogleAuthProvider.credentialFromResult(result);
    //const token = credential.accessToken;
    //const idToken = credential.idToken;
    const token = result.user.accessToken;
    // The signed-in user info.
    const user = result.user;
    //console.log(result);
    const addInfo = getAdditionalUserInfo(result);
    //console.log(addInfo);

    const authData = {
      email: user.email,
      token: token,
      firstName: addInfo.profile.given_name,
      lastName: addInfo.profile.family_name,
      organisation: "",
      industry: "",
    };
    var path = "/api/register";
    if (addInfo.isNewUser) {
      path = "/api/register";
    } else {
      path = "/api/login";
    }
    const response = await fetch(`${apiBasePath}${path}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(authData),
    });

    if (response.status === 400 || response.status === 401) {
      return response.message;
    }

    if (!response.ok) {
      //throw json({ message: 'Could not create user. ' + response.message }, { status: 500 });
      return "Could not create user in the backend. " + response.message;
    }

    const resData = await response.json();
    //console.log(resData);
    const dbUser = resData.user;
    storeUserDataEncrypted(dbUser);

    //console.log(user);
    //localStorage.setItem("token", token);
    storeTokenEncrypted(token);
    //localStorage.setItem("userName", JSON.stringify(user.displayName));
    localStorage.setItem("photoURL", JSON.stringify(user.photoURL));

    const expiration = new Date();
    expiration.setHours(expiration.getHours() + 1);
    localStorage.setItem("expiration", expiration.toISOString());

    // You might want to return something or redirect the user
    return true;
  } catch (error) {
    console.error("Error signing in: ", error);
    // Handle error (e.g., show message to the user)
    return false;
  }
};
