import { redirect, json } from "react-router-dom";
import CryptoJS from "crypto-js";
import { secretKey } from "./constants";
import { type IUserData, type IAuthData  } from "./interfaces";

export function storeUserDataEncrypted(userData : IUserData) {
  localStorage.setItem("userName", userData.firstName);
  
  const encryptedData = CryptoJS.AES.encrypt(
    JSON.stringify(userData),
    secretKey
  ).toString();
  localStorage.setItem("userData", encryptedData);
  return encryptedData;
}

export function getFeatures() {
  const features = localStorage.getItem("features");
  if (!features) {
    return null;
  }
  return JSON.parse(features);
}

export function getUserDataDecrypted() {
  const encryptedData = localStorage.getItem("userData");
  if (!encryptedData) {
    return null;
  }
  const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
  const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  return decryptedData;
}

export function getTokenDuration() {
  const storedExpirationDate : string | null = localStorage.getItem("expiration");
  if (!storedExpirationDate) {
    return -1;
  }
  const expirationDate = new Date(storedExpirationDate);
  const now = new Date();
  const duration = expirationDate.getTime() - now.getTime();
  return duration;
}

export function storeTokenEncrypted(token : string) {
  const encryptedData = CryptoJS.AES.encrypt(token, secretKey).toString();
  localStorage.setItem("token", encryptedData);
  return encryptedData;
}

export function getAuthToken() {
  const encryptedData = localStorage.getItem("token");

  if (!encryptedData) {
    return null;
  }

  const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
  const token = bytes.toString(CryptoJS.enc.Utf8);

  const tokenDuration = getTokenDuration();

  if (tokenDuration < 0) {
    return "EXPIRED";
  }

  return token;
}

function getUserName() {
  const userName = localStorage.getItem("userName");
  return userName;
}

function getPhotoURL() {
  const photoURL = localStorage.getItem("photoURL");
  return photoURL;
}

export function tokenLoader() {
  const token = getAuthToken();
  const userName = getUserName();
  const photoURL = getPhotoURL();
  const userData = getUserDataDecrypted();
  //if (userData) {
  //  userData.useStreaming = false;
  //}
  //const features = getFeatures();
  return json({ token, userName, photoURL, userData });
}

export function getAuthData(): IAuthData {
  const token = getAuthToken();
  const userName = getUserName();
  const photoURL = getPhotoURL();
  const userData = getUserDataDecrypted();
  //if (userData) {
  //  userData.useStreaming = false;
  //}
  return { token, userName, photoURL, userData };
}

export function checkAuthLoader() {
  const token = getAuthToken();

  if (!token) {
    return redirect("/auth");
  }
}
