import { Palette } from '@mui/material/styles/createPalette';

export function isErrorWithCode(error: any): error is { code: string } {
    return error && typeof error.code === "string";
}

export function isResponseWithMessage(response: any): response is { message: string } {
    return response && typeof response.message === "string";
}

export function isKeyOfPalette(key: any, palette: Palette): key is keyof Palette {
    return key in palette;
}

export function formatDate(dateString : string) {
    if (dateString === "" || dateString == null) {
      return "";
    }

    const date = new Date(dateString);
    return (
      date.toLocaleDateString("de-DE") + " " + date.toLocaleTimeString("de-DE")
    );
  };

export async function getFeatures(apiBasePath: string, token:string | null) {
  const payload = { token: token };
  const body = JSON.stringify(payload);
  const options = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: body,
  };

  try {
    const featureData = await fetch(
      apiBasePath + `/api/features`,
      options
    );
    if (featureData) {
      const { result: features } = await featureData.json();
      return features;
    }
  } catch (error) {
    console.log("Error fetching features:", error);
  }
}