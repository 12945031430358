import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Snackbar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import ExpandButton from "../../navigation/ExpandButton";
import OptionSelector from "../../navigation/OptionSelector";
import prompting from "../../../images/prompting.png";
import { useLocation, useRouteLoaderData } from "react-router-dom";
import useApi from "../../../utils/useApi";
import { type IPromptConfigProps, type IAuthData, type ISelectedOption, type IPromptItems, type SnackbarColor } from "../../../utils/interfaces";

const useStyles = makeStyles()((theme) => ({
  container: {
    padding: theme.spacing(4),
    //maxWidth: "1200px",
    width: "100%",
    alignItems: "left",
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    justifyContent: "space-between",
    margin: 0,
  },
  configContainer: {
    width: "100%",
    flex: 1,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "top",
    justifyItems: "stretch",
  },
  drawer: {
    width: "100%",
    [theme.breakpoints.down("md")]: {
      width: "auto",
    },
  },
  content: {
    padding: theme.spacing(4),
    width: "100%",
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  expandButton: {
    width: "100%", // default width
    display: "flex",
    justifyContent: "flex-start",
    padding: 0,
    [theme.breakpoints.down("sm")]: {
      width: "90%", // reduced width on small screens
    },
  },

  optionSelectorsContainer: {
    display: "flex",
    flexDirection: "row", // Change to row for horizontal alignment
    flexWrap: "nowrap", // Keeps items in a row but allows them to wrap to a new line if needed
    justifyContent: "center", // Adjust this as needed for alignment (e.g., 'flex-start', 'space-between')
    alignItems: "flex-start",
    flexBasis: "80%", // Adjust or remove this as needed
    [theme.breakpoints.down("lg")]: {
      flexDirection: "column", // Stack vertically on smaller screens
      flexBasis: "80%",
      flexWrap: "wrap",
    },
    width: "100%",

    margin: theme.spacing(0), // Adds some margin around the container
    // ... any other styling you need ...
  },

  iconButton: {
    padding: 0,
    width: "40px",
    height: "40px",
  },

  layoutContainer: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap", // Allow the containers to wrap
    justifyContent: "space-between",
  },
  textFields: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap", // Allow the containers to wrap
    justifyContent: "space-between",
    width: "100%",
    paddingLeft: theme.spacing(2),
    gap: theme.spacing(2),
  },

  inputField: {
    //marginBottom: theme.spacing(2),
    //marginLeft: theme.spacing(2),
    border: `1px solid ${theme.palette.greenBorder.hover}`,
    borderRadius: theme.shape.borderRadius,
    width: "80%",
    flexBasis: "100%",
  },
  promptField: {
    width: "80%",
    minHeight: "300px",
    padding: theme.spacing(1),
    fontSize: "16px",
    border: `1px solid ${theme.palette.greenBorder.hover}`,
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(2),
    //marginLeft: theme.spacing(2),
    resize: "vertical",
    flexBasis: "100%",
  },
  button: {
    fontFamily: theme.typography.button.fontFamily,
    textTransform: "none",
    minWidth: 200,
    backgroundColor: theme.palette.blue.main,
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-start",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "space-around", // changes the alignment to space-around on small screens
    },
  },
  newButton: {
    fontFamily: theme.typography.button.fontFamily,
    textTransform: "none",
    marginLeft: theme.spacing(15),
    flex: "1",
    marginRight: theme.spacing(20),
    backgroundColor: theme.palette.green.main,
    [theme.breakpoints.down("md")]: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  },
  saveButton: {
    fontFamily: theme.typography.button.fontFamily,
    textTransform: "none",
    flex: "2",
    marginRight: theme.spacing(20),
    backgroundColor: theme.palette.blue.main,
    [theme.breakpoints.down("md")]: {
      flex: "1",
      marginRight: theme.spacing(1),
    },
  },
  deleteButton: {
    fontFamily: theme.typography.button.fontFamily,
    textTransform: "none",
    flex: "3",
    marginRight: theme.spacing(15),
    backgroundColor: theme.palette.red.main,
    color: "white",
    [theme.breakpoints.down("md")]: {
      flex: "1",
      marginRight: theme.spacing(1),
    },
  },
  cancelButton: {
    fontFamily: theme.typography.button.fontFamily,
    textTransform: "none",
    backgroundColor: theme.palette.blue.main,
    color: "white",
  },
  confirmDeletionButton: {
    fontFamily: theme.typography.button.fontFamily,
    textTransform: "none",
    backgroundColor: theme.palette.red.main,
    color: "white",
  },
  icon: {
    width: "40px",
    height: "40px",
  },
}));


function PromptConfig({apiBasePath, user} : IPromptConfigProps) {
  const customFetch = useApi();
  const loaderData = useRouteLoaderData("root") as IAuthData;
  const token = loaderData.token;
  const { t } = useTranslation();
  const { classes } = useStyles();

  const [lastUserId, setLastUserId] = useState(-1);
  const [options, setOptions] = useState<ISelectedOption[]>([]);
  const [selectedOption, setSelectedOption] = useState<ISelectedOption | null>(null);
  const [selectedCategory, setSelectedCategory] = useState(-1);
  const [selectedItem, setSelectedItem] = useState(-1);
  const [items, setItems] = useState<IPromptItems[]>([]);
  const [itemId, setItemId] = useState(-1); // Initialize with -1
  const [itemName, setItemName] = useState("");
  const [prompt, setPrompt] = useState("");
  const [saving, setSaving] = useState(false);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarColor, setSnackbarColor] = useState<SnackbarColor>(""); // 'success' or 'error'

  const [isNewButtonActive, setIsNewButtonActive] = useState(false);
  const [isNewButtonClicked, setIsNewButtonClicked] = useState(false);
  const [isDeleteButtonActive, setIsDeleteButtonActive] = useState(false);

  const location = useLocation(); // This gives you the location object

  const [openDialog, setOpenDialog] = useState(false);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  // This function checks if the button's path matches the current location
  const isRouteActive = (routePath : string) => {
    return location.pathname === routePath;
  };

  const handleNewButtonClick = async () => {
    if (!selectedOption) {
      showSnackbar(t("SelectACategoryFirst"), "error");
      return;
    }

    if (selectedOption.name === "General Prompt") {
      showSnackbar('You cannot create a new "General Prompt"', "error");
      return;
    }

    resetFormFields();
    setIsNewButtonClicked(true);
    setIsDeleteButtonActive(false);
  };

  useEffect(() => {
    //console.log('selectedCategory:', selectedCategory);
    const selectedOption1 = options.find(
      (option) => option.id === selectedCategory
    );
    if (selectedOption1 === undefined) {
      return;
    }

    setSelectedOption(selectedOption1);
    fetchItemsForOption();
    let isActive = false; 
    if (selectedCategory && selectedOption1.name !== "General Prompt")
      isActive = true

    setIsNewButtonActive(isActive);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategory, options]);

  const showSnackbar = (message : string, color : SnackbarColor) => {
    setSnackbarMessage(message);
    setSnackbarColor(color);
    setSnackbarOpen(true);
  };

  const resetFormFields = () => {
    setItemId(-1);
    setItemName("");
    setPrompt("");
    setSelectedItem(-1);
  };

  const fetchOptions = async () => {
    try {
      if (user === null) {
        return;
      }

      const payload = {
        token: token,
      };
      const body = JSON.stringify(payload);
      const fetchOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: body,
      };

      const data = await customFetch(
        apiBasePath + `/api/user/${user.id}/get_options`,
        fetchOptions
      );
      // console.log(data);
      resetFormFields();
      setItems([]);
      setOptions(data);
    } catch (error) {
      console.error("Error fetching options:", error);
    }
  };

  useEffect(() => {
    if (user === null) 
    {
      return;
    }
    
    const needsReload = selectedCategory !== -1 && user.id !== lastUserId;
    if (options.length === 0) {
      fetchOptions();
    }
    if (needsReload) {
      fetchItemsForOption();
      if (user !== null) {
        setLastUserId(user.id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, user]); // Only fetch options when options change

  useEffect(() => {
    for (let i = 0; i < options.length; i++) {
      setSelectedCategory(options[i].id);
      break;
    }
  }, [options]);

  const fetchItemsForOption = async () => {
    try {
      if (user === null) {
        return;
      }

      resetFormFields();

      const selectedOption = options.find(
        (option) => option.id === selectedCategory
      );
      if (!selectedOption) {
        return;
      }
      const payload = {
        token: token,
      };
      const body = JSON.stringify(payload);
      const fetchOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: body,
      };

      if (selectedOption.name === "General Prompt") {
        const url = `${apiBasePath}/api/user/${user.id}/get_general_prompt`;

        const data = await customFetch(url, fetchOptions);
        //console.log(data);
        setItems(data);
        const selectedItemObj = data[0];
        //const selectedItemObj = data.find((item) => item.id === 1);
        if (selectedItemObj) {
          setItemId(selectedItemObj.id);
          setItemName(selectedItemObj.name);
          setPrompt(selectedItemObj.prompt);
        }
      } else if (selectedOption.name === "Chat actions") {
        const url = `${apiBasePath}/api/user/${user.id}/get_chat_actions`;
        const itemData = await customFetch(url, fetchOptions);
        setItems(itemData);
      } else {
        const url = `${apiBasePath}/api/user/${user.id}/get_${selectedOption.name}s`;

        const itemData = await customFetch(url, fetchOptions);
        setItems(itemData);
      }
    } catch (error) {
      console.error("Error fetching categories:", error);
      showSnackbar(t("errorLoadingCategories"), "error");
    }
  };

  useEffect(() => {
    for (let i = 0; i < items.length; i++) {
      setSelectedItem(items[i].id);
      break;
    }
  }, [items]);

  useEffect(() => {
    if (selectedCategory) {
      fetchItemsForOption();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategory]);

  const fetchItemDetails = async () => {
    try {
      //resetFormFields();
      const selectedItemObj = items.find((item) => item.id === selectedItem);
      if (selectedItemObj) {
        setItemId(selectedItemObj.id);
        setItemName(selectedItemObj.name);
        setPrompt(selectedItemObj.prompt);
        setIsNewButtonClicked(false);
        setIsDeleteButtonActive(true);
      }

    } catch (error) {
      console.error("Error fetching item details:", error);
      showSnackbar(t("failedToFetchItemDetails"), "error");
    }
  };

  useEffect(() => {
    if (selectedItem) {
      fetchItemDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem]);

  const handleDelete = async () => {
    if (!itemId) {
      return;
    }
    handleOpenDialog();
  };

  const handleConfirmDelete = async () => {
    // Call your API to delete the item
    // Close the dialog after the action
    handleCloseDialog();
    // Add your delete logic here

    if (user === null) {
      return;
    }

    const payload = {
      token: token,
    };
    const body = JSON.stringify(payload);
    const fetchOptions = {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
      body: body,
    };

    try {
      if (selectedOption === null) {  
        return;
      }
      var url = `${apiBasePath}/api/user/${user.id}/delete_${selectedOption.name}/${itemId}`;
      if (selectedOption.name === "Chat actions") {
        url = `${apiBasePath}/api/delete_chat_action/${itemId}`;
      }
      const data = await customFetch(url, fetchOptions);

      if (data) {
        //console.log("Item deleted successfully");
        showSnackbar(t("itemDeletedSuccessfully"), "success");
        resetFormFields();
        setItems((prevItems) => prevItems.filter((item) => item.id !== itemId));
      } else {
        console.log("Deletion failed");
        showSnackbar(t("failedToDeleteItem"), data.message);
      }
    } catch (error) {
      console.error("Error deleting item:", error);
      showSnackbar(t("failedToDeleteItem"), "error");
    }
  };

  const create = async () => {

    if (user === null) {
      return;
    }

    const newItem = {
      name: itemName,
      prompt: prompt,
      token: token,
    };
    const body = JSON.stringify(newItem);
    const fetchOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: body,
    };

    try {
      if (selectedOption === null) {
        return;
      }
      var url = `${apiBasePath}/api/user/${user.id}/create_${selectedOption.name}`;
      if (selectedOption.name === "Chat actions") {
        url = `${apiBasePath}/api/user/${user.id}/create_chat_action`;
      }
      const responseData = await customFetch(url, fetchOptions);

      if (responseData) {
        //console.log("New entry created successfully");
        showSnackbar(t("newEntryCreatedSuccessfully"), "success");

        const data = responseData.data;
        //console.log(data);

        setItems(data);
        setItemId(responseData.id);
        setSelectedItem(responseData.id);
      } else {
        //console.log("New entry creation failed");
        showSnackbar(t("failedToCreateANewEntry"), "error");

        console.log("Status:", responseData.status);
        console.log("Message:", responseData.message);
        console.log("Error:", responseData.error);
      }
    } catch (error) {
      console.error("Error creating a new entry:", error);
      showSnackbar(t("failedToCreateANewEntry"), "error");
    }
  };

  const save = async () => {

    if (user === null) {
      return;
    }

    const updatedItem = {
      id: itemId,
      name: itemName,
      prompt: prompt,
      token: token,
    };
    const body = JSON.stringify(updatedItem);
    const fetchOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: body,
    };

    try {
      if (selectedOption === null) {
        return;
      }
      let url = `${apiBasePath}/api/user/${user.id}/update_${selectedOption.name}/${itemId}`;
      if (selectedOption.name === "General Prompt") {
        url = `${apiBasePath}/api/user/${user.id}/update_general_prompt/${itemId}`;
      } else if (selectedOption.name === "Chat actions") {
        url = `${apiBasePath}/api/user/${user.id}/update_chat_action/${itemId}`;
      }
      const data = await customFetch(url, fetchOptions);

      if (data) {
        console.log("Update done successfully");
        showSnackbar(t("itemSavedSucessfully"), "success");
        setItems((prevItems) =>
          prevItems.map((item) =>
            item.id === itemId
              ? { ...item, name: itemName, prompt: prompt }
              : item
          )
        );
      } else {
        console.log("Update done failed");
        showSnackbar(t("failedToSaveTheItem"), "error");

        console.log("Status:", data.status);
        console.log("Message:", data.message);
        console.log("Error:", data.error);
      }
    } catch (error) {
      console.error("Error saving changes:", error);
      showSnackbar(t("failedToSaveTheItem"), "error");
    }
  };

  const handleSave = async () => {
    setSaving(true);
    if (isNewButtonClicked) {
      await create();
    } else {
      await save();
    }
    setSaving(false);
  };

  const optionSelectors = (

      <div className={classes.optionSelectorsContainer}>
        <OptionSelector
          title={t("selectOption")}
          options={options}
          selectedOption={selectedCategory}
          onOptionClick={setSelectedCategory}
          colorKey="blueSelect"
          hoverDisplay={false}
        />
        {selectedOption && selectedOption.name !== "General Prompt" ? (
          <OptionSelector
            title={t("selectElement")}
            options={items}
            selectedOption={selectedItem}
            onOptionClick={setSelectedItem}
            colorKey="blueSelect"
            hoverDisplay={false}
          />
        ) : null}
      </div>

  );

  return (

        <div className={classes.configContainer}>
          <div className={classes.content}>

            <div className={classes.expandButton}>
              <ExpandButton
                isExpanded={isRouteActive("/configuration") || isRouteActive("/promptConfigForAllUsers")}
                icon={
                  <img src={prompting} alt="prompting" className={classes.icon} />
                }
                text={t("prompting")}
                expandComponent={<div>{optionSelectors}</div>}
                redirectTo={location.pathname}
                colorKey={"blue"}
              />
            </div>
            <div className={classes.layoutContainer}>
              <div className={classes.textFields}>
              {selectedOption && selectedOption.name !== "General Prompt" ? (
                <TextField
                  label="Name"
                  value={itemName}
                  onChange={(event) => setItemName(event.target.value)}
                  className={classes.inputField}
                  disabled={selectedOption.name === "General Prompt"}
                />
              ) : null}
              <textarea
                placeholder="Prompt"
                value={prompt}
                onChange={(event) => setPrompt(event.target.value)}
                className={classes.promptField}
              />
              </div>
            </div>
            <div className={classes.buttonContainer}>
              <Button
                variant="contained"
                color="secondary"
                onClick={handleNewButtonClick}
                className={classes.newButton}
                disabled={!isNewButtonActive}
              >
                {t("new")}
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSave}
                className={classes.saveButton}
                disabled={saving}
              >
                {t("save")}
              </Button>
              <Button
                variant="contained"
                color="tertiary"
                onClick={handleDelete}
                className={classes.deleteButton}
                disabled={!isDeleteButtonActive || saving}
              >
                {t("delete")}
              </Button>
            </div>
            {saving && <p>Saving...</p>}
            {/* Confirmation Dialog */}
            <Dialog open={openDialog} onClose={handleCloseDialog}>
              <DialogTitle>{t("confirmDeletion")}</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  {t("areYouReallySureYouWantToDeleteThisItem")}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.cancelButton}
                  onClick={handleCloseDialog}
                >
                  {t("cancel")}
                </Button>
                <Button
                  variant="contained"
                  color="tertiary"
                  className={classes.confirmDeletionButton}
                  onClick={handleConfirmDelete}
                >
                  {t("delete")}
                </Button>
              </DialogActions>
            </Dialog>
            <Snackbar
              open={snackbarOpen}
              autoHideDuration={5000}
              onClose={() => setSnackbarOpen(false)}
              message={snackbarMessage}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              ContentProps={{
                style: {
                  backgroundColor: snackbarColor === "success" ? "green" : "red",
                },
              }}
            />
          </div>
        </div>
  );
}

export default PromptConfig;
