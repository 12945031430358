import React from "react";
import { makeStyles } from "tss-react/mui";
import { styled } from "@mui/material/styles";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Divider,
  IconButton,
} from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useNavigate } from "react-router-dom";
import { type IPage, type ISideBarProps } from "../../utils/interfaces";
import { useLocation } from "react-router-dom";

const drawerWidth = 240;

// Use `makeStyles` to create a hook for styles. The function doesn't need explicit types for its return value as it's inferred.
const useStyles = makeStyles()((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerClosed: {
    width: 0,
  },
  selected: {
    backgroundColor: theme.palette.blue.hoverAlpha,
  },
}));

function SideBar({ pages, isOpen, onClose }: ISideBarProps) {
  const { classes } = useStyles();
  const navigate = useNavigate();
  const location = useLocation();

  const isRouteActive = (routePath : string) => {
    return location.pathname === routePath;
  };

  const handleDrawerClose = () => {
    onClose();
  };

  const onMenuItemClick = (page : IPage) => {
    navigate(page.route);
  };

  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  }));

  return (
    <div>
      <div className={ isOpen ? classes.drawer : classes.drawerClosed}>
        { isOpen ? 
        <>
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </DrawerHeader>
          <Divider />
          <List>
            {pages.map(( page, index) => (
              <ListItem key={page.text} disablePadding className={isRouteActive(page.route) ? classes.selected : ''}>
                <ListItemButton onClick={() => onMenuItemClick(page)}>
                  <ListItemText primary={page.text} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </>
         : null   }
      </div>
      
    </div>
  );
}

export default SideBar;
