import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { useRouteLoaderData, Link } from "react-router-dom";
import { storeUserDataEncrypted } from "../../../utils/auth";
import { apiBasePath } from "../../../utils/constants";
import { type IAuthData, type IApiBasePathProps, type IProductDisplayProps, type ISuccessDisplayProps, type IPaymentMessageProps } from "../../../utils/interfaces";

const useStyles = makeStyles()((theme) => ({
  body: {
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  product: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
    textAlign: "center",
    marginBottom: theme.spacing(2),
  },
  description: {
    marginBottom: theme.spacing(2),
  },
  h3: {
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightMedium,
  },
  button: {
    textTransform: "none",
    marginTop: theme.spacing(0),
    backgroundColor: theme.palette.blue.main,
  },
  p: {
    color: theme.palette.text.secondary,
  },
  link: {
    fontSize: "0.875rem",
    textTransform: "none",
    marginTop: theme.spacing(1),
    backgroundColor: theme.palette.blue.main,
    color: "white",
    height: "40px",
    cursor: "pointer",
    textDecoration: "none",
    paddingLeft: "0.5rem",
    paddingRight: "0.5rem",
    borderRadius: "4px",
    textAlign: "center",
    display: "inline-flex", // Makes it a flex container
    alignItems: "center", // Vertically aligns the content
    justifyContent: "center", // Horizontally aligns the content
    lineHeight: "normal", // Adjust line height to ensure vertical centering
    [theme.breakpoints.down("sm")]: {
      height: "60px",
    },
    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)",
  },
}));

const ProductDisplay = ({ priceLookup, token } : IProductDisplayProps) => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  //var price = 0;
  var stripe_price_id = "";

  //console.log(priceLookup);

  if (priceLookup !== undefined && priceLookup.length > 0) {
    //price = priceLookup[0].price;
    stripe_price_id = priceLookup[0].stripe_price_id;
  }
  return (
    <section className={classes.body}>
      <div className={classes.product}>
        <div className={classes.description}>
          <h3 className={classes.h3}>{t("thankYouForRegistering")}</h3>

        </div>
        <form
          action={`${apiBasePath}/api/create-checkout-session`}
          method="POST"
        >
          <input type="hidden" name="lookup_key" value={stripe_price_id} />
          <input type="hidden" name="token" value={token} />
          <Button
            variant="contained"
            className={classes.button}
            id="checkout-and-portal-button"
            type="submit"
          >
            {t("checkout")}
          </Button>
        </form>
      </div>
    </section>
  );
};

const SuccessDisplay = ({ sessionId } : ISuccessDisplayProps) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  return (
    <section className={classes.body}>
      <div className={classes.product}>
        <div className={classes.description}>
          <h3 className={classes.h3}>{t("testPeriodStarted")}</h3>
        </div>
      </div>
      <form action={`${apiBasePath}/api/create-portal-session`} method="POST">
        <input
          type="hidden"
          id="session-id"
          name="session_id"
          value={sessionId}
        />
        <input
          type="hidden"
          id="return-url"
          name="return_url"
          value={window.location.href}
        />
        <Button
          variant="contained"
          className={classes.button}
          id="portal-button"
          type="submit"
        >
          {t("manageSubscriptionAndPayment")}
        </Button>
      </form>
    </section>
  );
};

const PaymentMessage = ({ message } : IPaymentMessageProps) => {
  const { classes } = useStyles();
  const { t } = useTranslation();
  return (
    <section className={classes.body}>
      <div className={classes.product}>
        <div className={classes.description}>
          <p className={classes.p}>{message}</p>
          <Link className={classes.link} to="/">
            {t("backToStartPage")}
          </Link>
        </div>
      </div>
    </section>
  );
};

export default function PaymentPage({ apiBasePath } : IApiBasePathProps) {
  const loaderData = useRouteLoaderData("root") as IAuthData;
  const token = loaderData.token;

  let [message, setMessage] = useState("");
  let [success, setSuccess] = useState(false);
  let [sessionId, setSessionId] = useState<string | null>("");
  let [priceLookups, setPriceLookups] = useState([]);

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get("success")) {
      setSuccess(true);
      setSessionId(query.get("session_id"));
    }

    if (query.get("canceled")) {
      setSuccess(false);
      setMessage("Vorgang abgebrochen.");
    }

    const fetchData = async () => {
      try {
        const payload = {
          token: token,
        };

        const body = JSON.stringify(payload);
        const priceLookupResponse = await fetch(
          apiBasePath + `/api/get_price_lookups`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: body,
          }
        );
        const priceLookupData = await priceLookupResponse.json();
        //console.log(priceLookupData);
        setPriceLookups(priceLookupData);
      } catch (error) {
        console.log("Error fetching options:", error);
      }
    };

    fetchData();
  }, [sessionId, apiBasePath, token]);

  if (!success && message === "") {
    return <ProductDisplay token={token!} priceLookup={priceLookups} />;
  } else if (success && sessionId !== "") {
    // call login again to get the new subscription status
    const fetchData = async () => {
      try {
        const payload = {
          token: token,
        };

        const body = JSON.stringify(payload);
        const response = await fetch(apiBasePath + `/api/login`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: body,
        });
        const resData = await response.json();
        const dbUser = resData.user;
        storeUserDataEncrypted(dbUser);
        //console.log(resData);
        // if (resData.is_valid_subscription === 'valid') {
        //   setMessage("Abo erfolgreich erstellt.");
        // } else {
        //   setMessage("Abo erfolgreich erstellt. Bitte warten Sie einen Moment, bis das Abo aktiviert wurde.");
        // }
      } catch (error) {
        console.log("Error fetching options:", error);
      }
    };
    fetchData();

    return <SuccessDisplay sessionId={sessionId!} />;
  } else {
    return <PaymentMessage message={message} />;
  }
}
