import React, { useState, useCallback, ChangeEvent, DragEvent } from 'react';
import { Button, Box, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { IFileUploaderProps } from '../../../utils/interfaces';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles()((theme) => ({
  fileUploader: {
    border: `2px dashed ${theme.palette.primary.main}`,
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
    '&.active': {
      backgroundColor: theme.palette.action.selected,
    }
  },
  input: {
    display: 'none',
  },
  label: {
    marginTop: theme.spacing(4),
  },
  button: {
    textTransform: "none",
    marginTop: theme.spacing(0),
    backgroundColor: theme.palette.blue.main,
  },
}));

const FileUploader: React.FC<IFileUploaderProps> = ({ onFileSelectSuccess, onFileSelectError }) => {
  const { classes } = useStyles();
  const [dragActive, setDragActive] = useState<boolean>(false);
  const { t } = useTranslation();

  const handleDrag = useCallback((event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    if (event.type === "dragenter" || event.type === "dragover") {
      setDragActive(true);
    } else if (event.type === "dragleave") {
      setDragActive(false);
    }
  }, []);

  const handleDrop = useCallback((event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setDragActive(false);
    const files = event.dataTransfer.files;
    if (files && files.length) {
      const file = files[0];
    
      // accept only .txt, .pdf, .doc, .docx files
      if (file.type !== "text/plain" && file.type !== "application/pdf" && file.type !== "application/msword" && file.type !== "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
        onFileSelectError(t('acceptedFileTypes'));
        return;
      }


      // if (!file.type.match('image/*')) {
      //   onFileSelectError('');
      //   return;
      // }
      onFileSelectSuccess(file);
    }
  }, [onFileSelectSuccess]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    console.log('File selected:', event.target.files);
    if (event.target.files && event.target.files.length) {
      const file = event.target.files[0];
      //console.log('File selected:', file);
      // if (!file.type.match('image/*')) {
      //   onFileSelectError('This DJ only plays image files. 🖼️');
      //   return;
      // }
      onFileSelectSuccess(file);
    }
  };

  return (
    <Box
      className={`${classes.fileUploader} ${dragActive ? 'active' : ''}`}
      onDragEnter={handleDrag}
      onDragOver={handleDrag}
      onDragLeave={handleDrag}
      onDrop={handleDrop}
    >
      <input
        accept=".pdf,.doc,.docx,.txt"
        className={classes.input}
        id="file-input"
        type="file"
        onChange={handleChange}
      />

      <Typography className={classes.label}>{t('dragndropSomeFilesHereOrClickToUpload') + " " + t('acceptedFileTypes')} </Typography>
      {dragActive && <Typography>{t('dropTheFilesHere')}</Typography>}
      <label className={classes.label} htmlFor="file-input">
        <Button className={classes.button} variant="contained" color="primary">
          {t('upload')}
        </Button>
      </label>
    </Box>
  );
};

export default FileUploader;



/*
<input
type="file"
accept=".pdf,.doc,.docx"
id="file-input"
style={{ display: 'none' }}
onChange={handleFileChange}
/>
*/