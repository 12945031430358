import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enUS from "../locales/en-US.json";
import deDE from "../locales/de-DE.json";

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: enUS,
    },
    de: {
      translation: deDE,
    },
  },
  lng: "de", // If you want to get the language from the browser, use 'navigator.language'
  fallbackLng: "en",

  interpolation: {
    escapeValue: false, // React already safes from XSS
  },
});

export default i18n;