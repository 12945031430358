import {
  Form,
  Link,
  useSearchParams,
  useActionData,
  useNavigation,
  useSubmit,
} from "react-router-dom";
import { useTranslation } from "react-i18next";
import { TextField, Button } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import AuthFormGoogleButton from "./AuthFormGoogleButton";
import { useState } from "react";

import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { type ILoginActionData } from "../../../utils/interfaces";

const useStyles = makeStyles()((theme) => ({
  form: {
    maxWidth: "800px",
    margin: "2rem auto",
  },
  formContainer: {
    display: "flex",
    flexDirection: "column", // Stacks children vertically
    alignItems: "center", // Aligns children centrally
    // ... other styles ...
  },
  label: {
    display: "block",
    width: "100%",
  },
  textField: {
    display: "block", // Ensures each TextField is a block-level element
    //maxWidth: '100%',
    margin: "0.5rem auto", // Centers the field and adds vertical margin
    //width: '100%', // Sets width to fill its container
    [theme.breakpoints.down("sm")]: {
      maxWidth: "90%",
    },
  },
  actions: {
    display: "flex",
    gap: "1rem",
    justifyContent: "center",
    alignItems: "center",
  },
  button: {
    textTransform: "none",
    marginTop: theme.spacing(1),
    backgroundColor: theme.palette.blue.main,
    color: "white",
    height: "40px",
  },
  link: {
    fontSize: "0.875rem",
    textTransform: "none",
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(4),
    backgroundColor: theme.palette.blue.main,
    color: "white",
    height: "40px",
    cursor: "pointer",
    textDecoration: "none",
    paddingLeft: "0.5rem",
    paddingRight: "0.5rem",
    borderRadius: "4px",
    textAlign: "center",
    display: "inline-flex", // Makes it a flex container
    alignItems: "center", // Vertically aligns the content
    justifyContent: "center", // Horizontally aligns the content
    lineHeight: "normal", // Adjust line height to ensure vertical centering
    [theme.breakpoints.down("sm")]: {
      height: "60px",
    },
    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: theme.palette.blue.hover,
    },
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "1rem",
  },
  errorMessage: {
    color: "red",
  },
}));

function AuthForm() {
  const { classes } = useStyles();
  const submit = useSubmit();
  const { t } = useTranslation();
  const data = useActionData() as ILoginActionData;
  const navigation = useNavigation();

  const [searchParams] = useSearchParams();
  const isLogin = searchParams.get("mode") === "login";
  const isSubmitting = navigation.state === "submitting";

  const [isTermsAccepted, setIsTermsAccepted] = useState(false);

  const [password, setPassword] = useState('');
  const [isPasswordValid, setIsPasswordValid] = useState(true);

  const validatePassword = (password : string) => {
    const hasMinLength = password.length >= 8;
    const hasNumbers = /\d/.test(password);
    const hasLowercase = /[a-z]/.test(password);
    const hasUppercase = /[A-Z]/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    return hasMinLength && hasNumbers && hasLowercase && hasUppercase && hasSpecialChar;
  };

  const handlePasswordChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const newPassword = event.target.value;
    setPassword(newPassword);
    if (isLogin) {
      setIsPasswordValid(true);
    }
    else {
      setIsPasswordValid(validatePassword(newPassword));
    }
  };

  const handleCheckboxChange = (event : React.ChangeEvent<HTMLInputElement>) => {
    setIsTermsAccepted(event.target.checked);
  };

  const google = async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    // Stop that form submission!
    event.preventDefault();

    submit(null, { action: "/signinwithgoogle", method: "post" });
  };

  return (
    <>
      <Form
        method="post"
        className={`${classes.form} ${classes.formContainer}`}
      >
        <div className={classes.header}>
          <h1>{isLogin ? t("login") : t("createNewUser")}</h1>
          <Link
            className={classes.link}
            to={`?mode=${isLogin ? "signup" : "login"}`}
          >
            {isLogin ? t("createNewUser") : t("loginExistingUser")}
          </Link>
        </div>
        {data && data.errors && (
          <ul>
            {Object.values(data.errors).map((err) => (
              <li key={err}>{err}</li>
            ))}
          </ul>
        )}
        {data && data.message && (
          <p className={classes.errorMessage}>{t(data.message)}</p>
        )}
        {isLogin ? null : (
          <>
            <TextField
              className={classes.textField}
              label={t("firstName")}
              name="firstName"
              type="text"
              margin="normal"
              fullWidth
            />
            <TextField
              className={classes.textField}
              label={t("lastName")}
              name="lastName"
              type="text"
              margin="normal"
              fullWidth
            />
            <TextField
              className={classes.textField}
              label={t("organisation")}
              name="organisation"
              type="text"
              margin="normal"
              fullWidth
            />
            <TextField
              className={classes.textField}
              label={t("industry")}
              name="industry"
              type="text"
              margin="normal"
              fullWidth
            />
          </>
        )}
        <TextField
          className={classes.textField}
          label={t("email")}
          name="email"
          type="email"
          margin="normal"
          fullWidth
        />
        <TextField
          className={classes.textField}
          label={t("password")}
          name="password"
          type="password"
          value={password}
          onChange={(e) => handlePasswordChange(e)}
          margin="normal"
          fullWidth
          error={!isPasswordValid}
          helperText={!isPasswordValid && t("passwordRequirements")}
        />
        {!isLogin && (
          <FormControlLabel
            control={
              <Checkbox
                checked={isTermsAccepted}
                onChange={handleCheckboxChange}
                name="termsAndConditions"
                color="primary"
              />
            }
            label={t("acceptTermsAndConditions")} // Make sure you have this translation key in your i18n files
          />
        )}
        <div className={classes.actions}>
          <Button
            className={classes.button}
            variant="contained"
            type="submit"
            color="primary"
            disabled={isSubmitting || (!isLogin && !isTermsAccepted)}
          >
            {isSubmitting
              ? t("submitting")
              : isLogin
              ? t("login")
              : t("create")}
          </Button>
          <Link className={classes.link} to="/passwordForgotten">
            {t("forgotPassword")}
          </Link>
        </div>
      </Form>
      <div className={classes.actions}>
        <AuthFormGoogleButton onClick={(e) => google(e)} />
      </div>
    </>
  );
}

export default AuthForm;
