import { Button } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import Message from "./Message";
import Input from "./Input";
import { 
  type IMessage, 
  type IChatComponentProps,
} from "../../../utils/interfaces";

const useStyles = makeStyles()((theme) => ({
  container: {
    padding: theme.spacing(4),
    maxWidth: "1400px",
  },

  titleContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(0),
  },
  title: {
    padding: "0px",
    marginBottom: "20px",
    borderRadius: "10px",
    color: "black",
    backgroundColor: "white",
  },
  content: {
    maxHeight: "calc(100vh - 300px)",
    overflowY: "scroll",
    marginBottom: "20px",
    border: "0px",
  },
  btnNew: {
    fontFamily: theme.typography.button.fontFamily,
    textTransform: "none",
    marginTop: theme.spacing(0),
    backgroundColor: theme.palette.blue.main,
  },
  cancelButton: {
    fontFamily: theme.typography.button.fontFamily,
    textTransform: "none",
    backgroundColor: theme.palette.blue.main,
    color: "white",
  },
  confirmDeletionButton: {
    fontFamily: theme.typography.button.fontFamily,
    textTransform: "none",
    backgroundColor: theme.palette.red.main,
    color: "white",
  },
  disclaimerOpenAI: {
    color: theme.palette.red.main,
    fontSize: "10px",
    marginBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    width: "90%",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  disclaimerDesktop: {
    color: "black",
    fontSize: "10px",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    width: "90%",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  disclaimerMobile: {
    color: "black",
    fontSize: "10px",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    width: "90%",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));


function ChatComponent({messages, newChat, isTyping, handleSubmit, gptIsGRDPCompliant, messagesEndRef} : IChatComponentProps) {
  //const loaderData = useRouteLoaderData("root") as IAuthData;
  const { t } = useTranslation();
  const { classes } = useStyles();

  return (
    <div>
      <div className={classes.titleContainer}>
        <h3 className={classes.title}>{t("chatmessages")}</h3>
        <Button
          variant="contained"
          color="primary"
          className={classes.btnNew}
          onClick={() => {
            newChat();
          }}
        >
          {t("newChat")}
        </Button>
      </div>
      <div className={classes.content}>
        {messages.map((el : IMessage, i : number) => {
          return <Message key={i} role={el.role} content={el.content} />;
        })}
        {isTyping && (
          <Message role="assistant" content={<></>} isTyping={true} />
        )}
        <div ref={messagesEndRef} /> {/* This is the div that is scrolled to the bottom */}
      </div>
      <Input
        onClick={(output) => {
            handleSubmit(output)
          
        }}
      />
        <div className={classes.disclaimerDesktop}>
          {t("disclaimerDesktop")}
        </div>
        <div className={classes.disclaimerMobile}>
          {t("disclaimerMobile")}
        </div>
        <div className={classes.disclaimerOpenAI}>
          {
              (!gptIsGRDPCompliant) ? t("disclaimerOpenAI"): ""
          }
        </div> 
    </div>
  );
}

export default ChatComponent;
