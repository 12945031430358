import React, { useEffect, useState } from "react";
import { makeStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { useRouteLoaderData } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
} from "@mui/material";
import { type IAuthData, type IPersonalizeHistoryProps, type IPersonalizeHistoryGridRow } from "../../../utils/interfaces";

const useStyles = makeStyles()((theme) => ({
  table: {
    minWidth: 650,
    [theme.breakpoints.down("md")]: {
      minWidth: 300, // Adjusts table for smaller screens
    },
  },
  headCell: {
    backgroundColor: theme.palette.violet.main,
    color: "white",
  },
  bodyCell: {
    fontSize: 14,
  },
  evenRow: {
    backgroundColor: theme.palette.violet.mainAlpha,
    "&:hover": {
      // Apply the hover effect only when hovering
      backgroundColor: "white", //theme.palette.blue.hoverAlpha,
    },
  },
  oddRow: {
    backgroundColor: theme.palette.blue.mainAlpha,
    "&:hover": {
      // Apply the hover effect only when hovering
      backgroundColor: "white", //theme.palette.blue.hoverAlpha,
    },
  },
  desktopGrid: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "block",
    }, 
  },
  mobileGrid: {
    display: "block",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));


const PersonalizeHistoryGrid = ({ apiBasePath, handleRowSelection } : IPersonalizeHistoryProps) => {
  const { classes } = useStyles();
  const loaderData = useRouteLoaderData("root") as IAuthData;
  const token = loaderData.token;
  const { t } = useTranslation();
  const [data, setData] = useState<IPersonalizeHistoryGridRow[]>([]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event : React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage : number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to the first page when rows per page change
  };

  const rowsToDisplay = data.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  useEffect(() => {
    const fetchData = async () => {
      const payload = {
        token: token,
      };
      const body = JSON.stringify(payload);

      try {
        const typeResponse = await fetch(
          apiBasePath + `/api/simplify/get_history`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: body,
          }
        );
        const typeData = await typeResponse.json();
        setData(typeData);
      } catch (error) {
        console.log("Error fetching options:", error);
      }
    };

    fetchData();
  }, [apiBasePath, token]);

  const truncateString = (str: string, num: number) => {
    if (
      str == null ||
      str === "" ||
      str === undefined ||
      num == null ||
      num === 0
    ) {
      return "";
    }
    if (str.length <= num) {
      return str;
    }
    return str.slice(0, num) + "...";
  };

  const formatDate = (dateString: string) => {
    if (dateString === "" || dateString == null) {
      return "";
    }

    const date = new Date(dateString);
    return (
      date.toLocaleDateString("de-DE") + " " + date.toLocaleTimeString("de-DE")
    );
  };

  const desktopGrid = (
    <TableContainer className={classes.desktopGrid} component={Paper}>
        <Table className={classes.table} aria-label="Personalize History">
          <TableHead>
            <TableRow>
              {["action", "type", "tone", "text", "aiContent", "time", "GPT"].map(
                (header) => (
                  <TableCell key={header} className={classes.headCell}>
                    {t(header)}
                  </TableCell>
                )
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {rowsToDisplay.map((row, index) => (
              <TableRow
                key={row.id}
                onClick={() => handleRowSelection(row)}
                className={`${
                  index % 2 === 0 ? classes.evenRow : classes.oddRow
                }`}
              >
                <TableCell className={classes.bodyCell}>{row.action}</TableCell>
                <TableCell className={classes.bodyCell}>{row.type}</TableCell>
                <TableCell className={classes.bodyCell}>{row.tone}</TableCell>
                <TableCell className={classes.bodyCell}>
                  {truncateString(row.text, 30)}
                </TableCell>
                <TableCell className={classes.bodyCell}>
                  {truncateString(row.aiContent, 30)}
                </TableCell>
                <TableCell className={classes.bodyCell}>
                  {formatDate(row.lastChangeTime)}
                </TableCell>
                <TableCell className={classes.bodyCell}>
                  {truncateString(row.gptVersionName, 30)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
  );

  const mobileGrid = (
    <TableContainer className={classes.mobileGrid} component={Paper}>
        <Table className={classes.table} aria-label="Personalize History">
          <TableHead>
            <TableRow>
              {[ "text","time"].map(
                (header) => (
                  <TableCell key={header} className={classes.headCell}>
                    {t(header)}
                  </TableCell>
                )
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {rowsToDisplay.map((row, index) => (
              <TableRow
                key={row.id}
                onClick={() => handleRowSelection(row)}
                className={`${
                  index % 2 === 0 ? classes.evenRow : classes.oddRow
                }`}
              >
                <TableCell className={classes.bodyCell}>
                  {truncateString(row.text, 30)}
                </TableCell>
                <TableCell className={classes.bodyCell}>
                  {formatDate(row.lastChangeTime)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
  );

  return (
    <>
      {desktopGrid}
      {mobileGrid}
      <TablePagination
        rowsPerPageOptions={[5, 10, 20, 50, 100]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={(event) => handleChangeRowsPerPage(event)}
        labelRowsPerPage={t("rowsPerPage")} // Localized label
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to} ${t("of")} ${
            count !== -1 ? count : `${t("moreThan")} ${to}`
          }`
        }
      />
    </>
  );
};

export default PersonalizeHistoryGrid;
