import React from "react";
import { makeStyles } from "tss-react/mui";
import bot from "../../../images/logo-2.png";
import user from "../../../images/profile.png";
import TypingIndicator from "./TypingIndicator";
import { type IMessageProps } from "../../../utils/interfaces";

const useStyles = makeStyles()((theme) => ({
  wrapperbot: {
    display: "grid",
    gridTemplateColumns: "60px auto",
    minHeight: "40px",
    padding: "0px",
    marginBottom: "20px",
    marginRight: "60px",
    borderRadius: "10px",
    backgroundColor: theme.palette.blue.mainAlpha,
    color: "black",
  },
  wrapperhuman: {
    display: "grid",
    gridTemplateColumns: "auto 60px",
    minHeight: "40px",
    padding: "0px",
    marginBottom: "20px",
    marginLeft: "60px",
    borderRadius: "10px",
    backgroundColor: theme.palette.blue.hoverAlpha,
    color: "black",
    textAlign: "right",
  },
  avatar: {
    width: "40px",
    height: "40px",
  },
  preformattedText: {
    whiteSpace: "pre-wrap",
    // Additional styles for regular text
  },
  code: {
    backgroundColor: theme.palette.grey[100], // Light grey background for code
    border: `1px solid ${theme.palette.grey[300]}`, // Light border
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    fontFamily: "monospace", // Monospace font for code
    overflowX: "auto", // Horizontal scrolling for long lines of code
  },
}));

// The Message component is used to display messages in the chat window
export default function Message({ role, content, isTyping = false } : IMessageProps) {
  const { classes } = useStyles();

  const formatContent = (text : string ) => {
    if (text === null) {
      return null;
    }

    // Split the text into sections based on triple backticks
    const sections = text.split(/```/);
    return sections.map((section, index) => {
      // Check if the section is code (odd indexes are code if using triple backticks)
      const isCode = index % 2 !== 0;

      if (isCode) {
        // Format the code section
        return (
          <pre key={index} className={classes.preformattedText}>
            <code>{section}</code>
          </pre>
        );
      } else {
        // Format the regular text section
        return section.split("\n").map((line, lineIndex) => {
          if (line.match(/^\d+\./)) {
            return (
              <li
                key={`${index}-${lineIndex}`}
                className={classes.preformattedText}
              >
                {line}
              </li>
            );
          }
          return (
            <p
              key={`${index}-${lineIndex}`}
              className={classes.preformattedText}
            >
              {line}
            </p>
          );
        });
      }
    });
  };

  const image = (
    <div>
      <img
        src={role === "assistant" ? bot : user}
        className={classes.avatar}
        alt="profile avatar"
      />
    </div>
  );

  return (
    <div
      className={
        role === "assistant" ? classes.wrapperbot : classes.wrapperhuman
      }
    >
      {role === "assistant" ? image : null}
      <div>
        {isTyping ? (
          <>
            <br />
            <TypingIndicator />
            <br />
            <br />
          </>
        ) : (
          formatContent(content as string)
        )}
      </div>
      {role === "assistant" ? null : image}
    </div>
  );
}
