import React from "react";
import { makeStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { type IInputProps } from "../../../utils/interfaces";

const useStyles = makeStyles()((theme) => ({
  wrapper: {
    display: "grid",
    gridTemplateColumns: "auto 100px",
    height: "60px",
    borderRadius: "10px",
    backgroundColor: theme.palette.body.main,
  },
  text: {
    border: "none",
    outline: "none",
    background: "none",
    padding: "20px",
    color: "black",
    fontSize: "16px",
  },
  btn: {
    border: "none",
    borderRadius: "0 10px 10px 0",
    fontFamily: theme.typography.button.fontFamily,
    textTransform: "none",
    fontSize: "16px",
    color: "white",
    backgroundColor: theme.palette.blue.main,
    "&:hover": {
      cursor: "pointer",
      backgroundColor: theme.palette.blue.hover,
    },
  },
}));

export default function Input({ onClick } : IInputProps) {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const [input, setInput] = React.useState("");

  const handleKeyPress = (e : React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      //console.log("Enter key pressed");
      onClick(input);
    }
  };

  const onChange = (e : React.ChangeEvent<HTMLInputElement>) => {
    //console.log("onChange");
    setInput(e.target.value)
  }

  return (
    <div className={classes.wrapper}>
      <input
        className={classes.text}
        placeholder={t("yourPromptHere")}
        value={input}
        onChange={onChange}
        onKeyPress={(e) => handleKeyPress(e)}
      />
      <button className={classes.btn} onClick={() => onClick(input)}>
        {t("go")}
      </button>
    </div>
  );
}
