import React, { useState, useEffect } from "react";
import { Snackbar, Container } from "@mui/material";
import MarkdownRenderer from "./MarkdownRenderer";
import { makeStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { type IApiBasePathProps, type SnackbarColor } from "../../../utils/interfaces";

const useStyles = makeStyles()((theme) => ({
  container: {
    padding: theme.spacing(4),
    maxWidth: "1000px",
  },

  layoutContainer: {
    display: "grid",
    gridTemplateColumns: "auto 200px",
    gap: "20px",
    maxWidth: "1000px",
    margin: "0 auto",
    minHeight: "100vh",
    padding: "20px",
    border: "0px",
  },

  column: {
    color: "white",
    border: 0,
  },
  title: {
    padding: "0px",
    marginBottom: "20px",
    borderRadius: "10px",
    color: "black",
    backgroundColor: "white",
  },
  content: {
    maxHeight: "calc(100vh - 300px)",
    overflowY: "scroll",
    marginBottom: "20px",
    border: "0px",
  },
}));

function FAQPage({ apiBasePath } : IApiBasePathProps) {
  const { t } = useTranslation();
  const { classes } = useStyles();

  const [faq, setFaq] = useState("");

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarColor, setSnackbarColor] = useState<SnackbarColor>(""); // 'success' or 'error'

  const showSnackbar = (message: string, color: SnackbarColor) => {
    setSnackbarMessage(message);
    setSnackbarColor(color);
    setSnackbarOpen(true);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const faqResponse = await fetch(apiBasePath + "/api/get_faq");
        const faqData = await faqResponse.json();
        //console.log(faqData[0]);
        setFaq(faqData[0].text);
      } catch (e) {
        showSnackbar(t("errorLoadingFAQ"), "error");
      }
    };
    fetchData();
  }, [apiBasePath, t]);

  return (
    <div>
      <Container className={classes.container} maxWidth={false}>
        <div className={classes.layoutContainer}>
          <MarkdownRenderer text={faq}></MarkdownRenderer>
        </div>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={5000}
          onClose={() => setSnackbarOpen(false)}
          message={snackbarMessage}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          ContentProps={{
            style: {
              backgroundColor: snackbarColor === "success" ? "green" : "red",
            },
          }}
        />
      </Container>
    </div>
  );
}

export default FAQPage;
