import React, { useEffect, useState } from "react";
import { useRouteError,  useSubmit } from "react-router-dom";
import { useTranslation } from "react-i18next";
import NavigationBar from "../navigation/NavigationBar";
import Footer from "../navigation/Footer";
import { getTokenDuration, getAuthData} from "../../utils/auth";

import PageContent from "./PageContent";

function ErrorPage() {
  const error = useRouteError();
  const { t } = useTranslation();

  //console.log(error);
  function isErrorWithStatus(error: any): error is { status: number, data: { message: string } } {
    return error && typeof error.status === "number";
  }

  let title = t("anErrorOccurred");
  let message = t("somethingWentWrong");

  if (isErrorWithStatus(error)) {
    if (error.status === 500) {
      message = error.data.message;
    }

    if (error.status === 404) {
      title = t("notFound");
      message = t("couldNotFindResourceOrPage");
    }
  }

  const submit = useSubmit();
  const [localUserName, setLocalUserName] = useState<string | null>(null);
  const [localPhotoURL, setLocalPhotoURL] = useState<string | null>(null);

  useEffect(() => {
    const { token, userName, photoURL } = getAuthData();
    if (token === "EXPIRED") {
      submit(null, { action: "/logout", method: "post" });
      return;
    }

    const tokenDuration = getTokenDuration();
    if (tokenDuration >= 0) {
      setTimeout(() => {
        submit(null, { action: "/logout", method: "post" });
      }, tokenDuration);
    }

    if (userName) {
      setLocalUserName(userName.replace(/['"]+/g, ""));
    } else {
      setLocalUserName(null);
    }
    if (photoURL) {
      setLocalPhotoURL(photoURL.replace(/['"]+/g, ""));
    } else {
      setLocalPhotoURL(null);
    }
  }, [ submit]);

  return (
    <>
      <NavigationBar
        name={localUserName ? localUserName : null}
        photoUrl={localPhotoURL ? localPhotoURL : undefined}
      />
      <PageContent title={title}>
        <p>{message}</p>
      </PageContent>
      <Footer />
    </>
  );
}

export default ErrorPage;
