import React from "react";
import { makeStyles } from "tss-react/mui";
import "./imprintpage.module.css";

const useStyles = makeStyles()((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    maxWidth: "960px",
    marginLeft: "auto", // Center the container
    marginRight: "auto", // Center the container
        //width: "100%", 
        whiteSpace: "pre-wrap",
        wordWrap: "break-word",
        wordBreak: "break-word",
  },
}));

function ImprintPage() {
  const { classes } = useStyles();

  return (
    <div className={classes.container}>
      <h2>Impressum</h2>
      <p>
        <b>nudge GmbH</b>
        <br />
        <b>Sitz:</b>
        <br />
        Grindelberg 15a
        <br />
        20144 Hamburg
        <br />
        Deutschland
      </p>
      <p>
        <b>Geschäftsführer:</b>
        <br />
        Jörg Salamon
      </p>
      <p>
        <b>Registergericht:</b>
        <br />
        Amtsgericht Hamburg HRB 116788
      </p>
      <p>
        <b>UstId:</b>
        <br /> DE-275229105
      </p>
      <p>
        E-Mail: <a href="mailto:info@nudge.media">info@nudge.media</a>
        <br />
        Web:{" "}
        <a href="http://www.nudge.media" target="_blank" rel="noreferrer">
          http://www.nudge.media
        </a>
        <br />
        Tel.:{" "}
        <a href="tel:+494087099487" target="_blank" rel="noreferrer">
          +49 (0) 40 87099487
        </a>
      </p>
    </div>
  );
}

export default ImprintPage;
