import React, { useState, useEffect } from "react";
import { makeStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { useRouteLoaderData } from "react-router-dom";
import useApi from "../../../utils/useApi";
import { type IApiBasePathProps, type IAuthData, type IUser, type IGptVersion, type IPage } from "../../../utils/interfaces";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  Typography,
  TableContainer,
  IconButton,
  Button,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import PropTypes from "prop-types";
import SideBar from "../../navigation/SideBar";
import UserSelectTable from "./UserSelectTable";

const useStyles = makeStyles()((theme) => ({
  container: {
    padding: theme.spacing(4),
    //maxWidth: "1200px",
    margin: "auto", // Centers the container
    overflowX: 'auto', // Allows horizontal scrolling if necessary
    overflowY: 'hidden' // Prevents vertical scrolling
  },
  configContainer: {
    width: "100%",
    flex: 1,
    display: "flex",
    flexDirection: "row",
  },
  content: {
    padding: theme.spacing(4),
    width: "100%",
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  button: {
    textTransform: "none",
    marginTop: theme.spacing(0),
    backgroundColor: theme.palette.blue.main,
  },

  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: theme.spacing(2),
    gap: theme.spacing(2),
  },

  iconButton: {
    padding: 0,
    width: "40px",
    height: "40px",
  },
  paper: {
    padding: theme.spacing(2),
    boxShadow: "0px 4px 12px rgba(0,0,0,0.1)", // Adds a subtle shadow
    borderRadius: theme.shape.borderRadius, // Uses theme's border radius for consistency
  },
  table: {
    minWidth: 650,
    width: "100%",
  },
  header: {
    margin: theme.spacing(2, 0), // Adds margin above and below the header
  },
  tableCell1: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.action.hover, // Adds a hover effect
    },
  },
  tableCell: {
    whiteSpace: 'normal',
    wordWrap: 'break-word', // Ensures long words will wrap too
    maxWidth: 150,
  },

  smallTableCell: {
    whiteSpace: 'normal',
    wordWrap: 'break-word', // Ensures long words will wrap too
    maxWidth: 80,
  },
  headCell: {
    backgroundColor: theme.palette.violet.main,
    color: "white",
  },
  evenRow: {
    backgroundColor: theme.palette.violet.mainAlpha,
    "&:hover": {
      // Apply the hover effect only when hovering
      backgroundColor: theme.palette.blue.hoverAlpha,
    },
  },
  oddRow: {
    backgroundColor: theme.palette.blue.mainAlpha,
    "&:hover": {
      // Apply the hover effect only when hovering
      backgroundColor: theme.palette.blue.hoverAlpha,
    },
  },
  selectedRow: {
    backgroundColor: theme.palette.action.selected, // Highlights the selected row
  },
}));

function GptConfigForAllUsers({ apiBasePath }: IApiBasePathProps) {
  const customFetch = useApi();
  const loaderData = useRouteLoaderData("root") as IAuthData;
  const token = loaderData.token;
  const user = loaderData.userData;
  const { t } = useTranslation();
  const { classes } = useStyles();
  const [gptVersions, setGptVersions] = useState<IGptVersion[]>([]);
  const [users, setUsers] = useState<IUser[]>([]);
  const [selectedGptVersion, setSelectedGptVersion] =
    useState<IGptVersion | null>(null);
  const [selectedUser, setSelectedUser] = useState<IUser | null>(null);
  const [isSideBarOpen, setIsSideBarOpen] = useState(true);
  const isAdmin = user.roleId === 1;

  let pages : IPage[] = [
    { text : t("promtConfig"), route: "/configuration" }, 
    { text: t("gptConfig"), route: "/gptConfig" },
    
  ];
  if (isAdmin) {
    pages.push({text: t("gptConfigForAllUsers"), route: "/gptConfigForAllUsers"});
    pages.push({ text: t("promptConfigForAllUsers"), route: "/promptConfigForAllUsers" });
  }


  const fetchGpts = async () => {
    try {
      const payload = {
        token: token,
      };
      const body = JSON.stringify(payload);
      const fetchOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: body,
      };

      const data = await customFetch(
        apiBasePath + "/api/get_all_gpt_versions",
        fetchOptions
      );
      // console.log(data);
      setGptVersions(data);
    } catch (error) {
      console.error("Error fetching options:", error);
    }
  };

  useEffect(() => {
    if (gptVersions.length === 0) {
      fetchGpts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gptVersions]);

  useEffect(() => {
    if (users.length === 0 && selectedUser !== null) {
      for (let i = 0; i < users.length; i++) {
        if (users[i].id === selectedUser.id) {
          setSelectedUser(users[i]);
          break;
        }
      }
    }
        // eslint-disable-next-line react-hooks/exhaustive-deps
  } , [users]);

  const fetchUsers = async () => {
    try {
      const payload = {
        token: token,
      };
      const body = JSON.stringify(payload);
      const fetchOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: body,
      };

      const data = await customFetch(
        apiBasePath + "/api/get_all_users",
        fetchOptions
      );
      // console.log(data);
      setUsers(data);
    } catch (error) {
      console.error("Error fetching options:", error);
    }
  };

  const assignSelectedGptVersionToTenantOfSelectedUser = async () => {
    if (selectedUser && selectedGptVersion) {
      try {
        const payload = {
          token: token,
          tenantId: selectedUser.tenantId,
          gptVersionId: selectedGptVersion.id,
        };
        const body = JSON.stringify(payload);
        const fetchOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: body,
        };

        await customFetch(
          apiBasePath + "/api/assign_gpt_version_to_tenant",
          fetchOptions
        );
        fetchUsers();
      } catch (error) {
        console.error("Error assigning GPT version to user:", error);
      }
    }
  }

  const assignSelectedGptVersionToAllUsers = async () => {
    if (selectedGptVersion) {
      try {
        const payload = {
          token: token,
          gptVersionId: selectedGptVersion.id,
        };
        const body = JSON.stringify(payload);
        const fetchOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: body,
        };

        await customFetch(
          apiBasePath + "/api/assign_gpt_version_to_all_tenants",
          fetchOptions
        );
        fetchUsers();
      } catch (error) {
        console.error("Error assigning GPT version to all users:", error);
      }
    }
  }

  const setSelectedGptVersionAsDefaultToAllUsers = async () => {
    if (selectedGptVersion) {
      try {
        const payload = {
          token: token,
          gptVersionId: selectedGptVersion.id,
        };
        const body = JSON.stringify(payload);
        const fetchOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: body,
        };

        await customFetch(
          apiBasePath + "/api/set_gpt_version_as_default_to_all_users",
          fetchOptions
        );
        fetchUsers();
      } catch (error) {
        console.error("Error setting GPT version as default to all users:", error);
      }
    }
  }

  const deleteGptVersionFromAllTenants = async () => {
    if (selectedGptVersion) {
      try {
        const payload = {
          token: token,
          gptVersionId: selectedGptVersion.id,
        };
        const body = JSON.stringify(payload);
        const fetchOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: body,
        };

        await customFetch(
          apiBasePath + "/api/delete_gpt_version_from_all_tenants",
          fetchOptions
        );
        fetchUsers();
      } catch (error) {
        console.error("Error deleting GPT version from all tenants:", error);
      }
    }
  }

  useEffect(() => {
    if (users.length === 0) {
      fetchUsers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users]);

  const handleDrawerOpen = () => {
    setIsSideBarOpen(true);
  };

  const handleDrawerClose = () => {
    setIsSideBarOpen(false);
  };

  return (
    <div className={classes.container}>
            { isSideBarOpen === false ? 
          <div className={classes.iconButton}>
              <IconButton 
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
            >
              <MenuIcon />
            </IconButton> 
            </div>
            : null
          }
        <div className={classes.configContainer}>
          <SideBar pages={pages} isOpen={isSideBarOpen} onClose={handleDrawerClose} />
          <div className={classes.content}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12} lg={5}>
          <Paper className={classes.paper}>
            <Typography variant="h6" className={classes.header}>
              GPT Versions
            </Typography>
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell className={`${classes.tableCell} ${classes.headCell}`}>GPT Version Name</TableCell>
                    <TableCell className={`${classes.tableCell} ${classes.headCell}`}>GDPR Compliant?</TableCell>
                    <TableCell className={`${classes.tableCell} ${classes.headCell}`}>Description</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {gptVersions.map((gptVersion, index) => (
                    <TableRow
                      key={gptVersion.id}
                      hover
                      onClick={() => setSelectedGptVersion(gptVersion)}
                      selected={selectedGptVersion?.id === gptVersion.id}
                      className={`${
                        index % 2 === 0 ? classes.evenRow : classes.oddRow
                      }`}
                    >
                      <TableCell className={classes.tableCell}>{gptVersion.name}</TableCell>
                      <TableCell className={classes.smallTableCell}>
                        {gptVersion.isGdrdCompliant ? "Yes" : "No"}
                      </TableCell>
                      <TableCell className={classes.tableCell}>{gptVersion.description}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
        <Grid item xs={12} md={12} lg={7}>
          <Paper className={classes.paper}>
            <UserSelectTable
              users={users}
              selectedUser={selectedUser}
              setSelectedUser={setSelectedUser}
            />
          </Paper>
        </Grid>
        <div className={classes.buttonContainer}>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={assignSelectedGptVersionToTenantOfSelectedUser}
          >
            {
            //t("history")
            }
            Assign GPT Version to User
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={assignSelectedGptVersionToAllUsers}
          >
            {
            //t("history")
            }
            Assign GPT Version to all Users
          </Button>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={setSelectedGptVersionAsDefaultToAllUsers}
          >
            {
            //t("history")
            }
            Set GPT Version as Default to all Users
          </Button>

          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={deleteGptVersionFromAllTenants}
          >
            {
            //t("history")
            }
            Delete GPT Version from all Tenants
          </Button>
        </div>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <Typography variant="h6" className={classes.header}>
              GPT Versions of Selected User
            </Typography>
            {selectedUser ? (
              <TableContainer component={Paper}>
                <Table className={classes.table} aria-label="user-gpt-versions">
                  <TableHead>
                    <TableRow>
                      <TableCell className={`${classes.smallTableCell} ${classes.headCell}`}>GPT Version Name</TableCell>
                      <TableCell className={`${classes.smallTableCell} ${classes.headCell}`}>API Type</TableCell>
                      <TableCell className={`${classes.smallTableCell} ${classes.headCell}`}>API Version</TableCell>
                      <TableCell className={`${classes.smallTableCell} ${classes.headCell}`}>Model Name</TableCell>
                      <TableCell className={`${classes.smallTableCell} ${classes.headCell}`}>Is GDPR Compliant?</TableCell>
                      <TableCell className={`${classes.tableCell} ${classes.headCell}`}>Description</TableCell>
                      <TableCell className={`${classes.smallTableCell} ${classes.headCell}`}>Default?</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {selectedUser.gptVersions.map((gptVersion, index) => (
                      <TableRow 
                        key={gptVersion.id}
                        className={`${
                          index % 2 === 0 ? classes.evenRow : classes.oddRow
                        }`}
                      >
                        <TableCell className={classes.smallTableCell}>{gptVersion.name}</TableCell>
                        <TableCell className={classes.smallTableCell}>{gptVersion.apiType}</TableCell>
                        <TableCell className={classes.smallTableCell}>{gptVersion.apiVersion}</TableCell>
                        <TableCell className={classes.smallTableCell}>{gptVersion.modelName}</TableCell>
                        <TableCell className={classes.smallTableCell}>
                          {gptVersion.isGdrdCompliant ? "Yes" : "No"}
                        </TableCell>
                        <TableCell className={classes.tableCell}>{gptVersion.description}</TableCell>
                        <TableCell className={classes.smallTableCell}>
                          {gptVersion.isDefault ? "Yes" : "No"}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <p>Please select a user to view their GPT versions.</p>
            )}
          </Paper>
        </Grid>
      </Grid>
      </div>
      </div>
    </div>
  );
}

GptConfigForAllUsers.propTypes = {
  apiBasePath: PropTypes.string.isRequired,
};

export default GptConfigForAllUsers;
