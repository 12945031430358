import { makeStyles } from "tss-react/mui";
import { type IPageContentProps } from "../../utils/interfaces";

const useStyles = makeStyles()((theme) => ({
  content: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    maxWidth: "960px",
    alignContent: "center",
  },
}));

function PageContent({ title, children } : IPageContentProps) {
  const { classes } = useStyles();
  return (
    <div className={classes.content}>
      <h1>{title}</h1>
      {children}
    </div>
  );
}

export default PageContent;
