import React, { useState, useEffect } from "react";
import {
  Form,
  useNavigation,
  useActionData,
  useNavigate,
} from "react-router-dom";
import { useTranslation } from "react-i18next";
import { makeStyles } from "tss-react/mui";
import { TextField, Button, Snackbar } from "@mui/material";
import { type IChangePasswordActionData, type SnackbarColor } from "../../../utils/interfaces";

const useStyles = makeStyles()((theme) => ({
  form: {
    maxWidth: "800px",
    margin: "2rem auto",
  },
  formContainer: {
    display: "flex",
    flexDirection: "column", // Stacks children vertically
    alignItems: "center", // Aligns children centrally
    // ... other styles ...
  },
  textField: {
    display: "block", // Ensures each TextField is a block-level element
    //maxWidth: '100%',
    margin: "0.5rem auto", // Centers the field and adds vertical margin
    //width: '100%', // Sets width to fill its container
    //[theme.breakpoints.down('sm')]: {
    maxWidth: "90%",
    //},
  },
  actions: {
    display: "flex",
    gap: "1rem",
    justifyContent: "center",
    alignItems: "center",
  },
  button: {
    textTransform: "none",
    marginTop: theme.spacing(1),
    backgroundColor: theme.palette.blue.main,
    color: "white",
    height: "40px",
  },
  errorMessage: {
    color: "red",
    textAlign: "center",
    marginTop: "1rem",
  },
  message: {
    color: "green",
    textAlign: "center",
    marginTop: "1rem",
  },
}));

export default function ChangePasswordForm() {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const navigation = useNavigation();
  const data = useActionData() as IChangePasswordActionData;
  const navigate = useNavigate();
  const isSubmitting = navigation.state === "submitting";

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarColor, setSnackbarColor] = useState<SnackbarColor>(""); // 'success' or 'error'

  const showSnackbar = (message : string, color: SnackbarColor) => {
    setSnackbarMessage(message);
    setSnackbarColor(color);
    setSnackbarOpen(true);
  };

  const [password, setPassword] = useState('');
  const [isPasswordValid, setIsPasswordValid] = useState(true);

  const validatePassword = (password : string) => {
    const hasMinLength = password.length >= 8;
    const hasNumbers = /\d/.test(password);
    const hasLowercase = /[a-z]/.test(password);
    const hasUppercase = /[A-Z]/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    return hasMinLength && hasNumbers && hasLowercase && hasUppercase && hasSpecialChar;
  };

  const handlePasswordChange = (event : React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const newPassword = event.target.value;
    setPassword(newPassword);
    setIsPasswordValid(validatePassword(newPassword));
  };

  useEffect(() => {
    if (data && data.status === 200) {
      showSnackbar(t(data.message), "success");
      data.message = "";
    }
  }, [data, t]);

  //console.log('PasswordResetForm data: ' + data);

  const login = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    //submit(null, { action: '/auth?mode=login', method: 'get' });
    navigate("/profile");
  };

  return (
    <div>
      <Form
        method="post"
        className={`${classes.form} ${classes.formContainer}`}
      >
        {data && data.errors && (
          <ul>
            {Object.values(data.errors).map((err) => (
              <li key={err}>{err}</li>
            ))}
          </ul>
        )}
        {data && data.message && (
          <p className={classes.errorMessage}>{t(data.message)}</p>
        )}
        <TextField
          className={classes.textField}
          label={t("email")}
          name="email"
          type="email"
          margin="normal"
          fullWidth
        />
        <TextField
          className={classes.textField}
          label={t("oldPassword")}
          name="oldPassword"
          type="password"
          margin="normal"
          fullWidth
        />
        <TextField
          className={classes.textField}
          label={t("newPassword")}
          name="newPassword"
          type="password"
          value={password}
          onChange={handlePasswordChange}
          margin="normal"
          fullWidth
          error={!isPasswordValid}
          helperText={!isPasswordValid && t("passwordRequirements")}
        />
        <TextField
          className={classes.textField}
          label={t("confirmPassword")}
          name="confirmPassword"
          type="password"
          margin="normal"
          fullWidth
        />
        <div className={classes.actions}>
          <Button
            className={classes.button}
            variant="contained"
            type="submit"
            color="primary"
            disabled={isSubmitting}
          >
            {isSubmitting ? t("submitting") : t("changePassword")}
          </Button>
        </div>
      </Form>
      <div className={classes.actions}>
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          type="button"
          onClick={login}
        >
          {t("back")}
        </Button>
      </div>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        ContentProps={{
          style: {
            backgroundColor: snackbarColor === "success" ? "green" : "red",
          },
        }}
      />
    </div>
  );
}
