import React, { useState } from "react";
import { makeStyles } from 'tss-react/mui';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import ArrowRight from '@mui/icons-material/ArrowRight';
import { useTranslation } from "react-i18next";
import { type IFAQItemProps } from "../../../utils/interfaces";

const useStyles = makeStyles()((theme) => ({
  details: {
    marginBottom: '1rem',
  },
  question: {
    cursor: 'pointer',
    listStyle: 'none',
  },
  summaryText: {
    display: 'flex',
    alignItems: 'left',
    fontWeight: 'bold',
  },
  arrowIcon: {
    marginRight: '0.5rem',
    // Additional styling for the arrow icon
  },
  content: {
    marginTop: '0.5rem',
    marginLeft: '1rem',
    paddingLeft: '1rem',
    borderLeft: `2px solid ${theme.palette.violet.main}`,
},
  // Additional styles...
  container: {
    width: '70%', // Set the width to 80% of the viewport width
    margin: '0 auto', // Center the container by setting the top and bottom margin to 0 and left and right margin to auto
    marginTop: theme.spacing(4), // Additional top margin
    padding: '2rem', // Padding inside the container
    maxWidth: '1000px', // The maximum width the container can stretch to
    textAlign: 'left', // Align text to the left
    display: 'flex', // Use flexbox for internal alignment
    flexDirection: 'column', // Stack children vertically
    alignItems: 'left', // Align children in the center along the cross axis
    justifyContent: 'left', // Align children in the center along the main axis
    [theme.breakpoints.down("md")]: {
        width: '90%', // Set the width to 80% of the viewport width
      },
  },
}));

const FAQItem = ({ question, answer, children } : IFAQItemProps) => {
    const { classes } = useStyles();
    const [isOpen, setIsOpen] = useState(false);

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    return (
        <details className={classes.details} open={isOpen} onToggle={handleToggle}>
            <summary className={classes.question}>
                <div className={classes.summaryText}>
                {isOpen ? <ArrowDropDown className={classes.arrowIcon}/> : <ArrowRight className={classes.arrowIcon}/>}
                <strong>{question}</strong>
                </div>
            </summary>
            <div className={classes.content}>
                <p>
                    {answer}
                </p>
                {children}
            </div>
        </details>
    );
};


function FAQPageStatic()  {
    const { classes } = useStyles();
    const { t } = useTranslation();
  return (
    <div className={classes.container}>
        <h2>{t("generalQuestions")}</h2>
        <FAQItem question={t("question1")} answer={t("answer1")} />
        <FAQItem question={t("question2")} answer={t("answer2")} />
        <FAQItem question={t("question3")} answer={t("answer3")} />
        <FAQItem question={t("question4")} answer={t("answer4")} />
        <h2>{t("productSpecificQuestions")}</h2>
        <FAQItem question={t("question5")} answer={t("answer5")} />
        <FAQItem question={t("question6")} answer={t("answer6")} />
        <FAQItem question={t("question7")} answer={t("answer7")} />
        <FAQItem question={t("question8")} answer={t("answer8")}>
            <ul>
                <li>{t("answer8Bullet1")}</li>
                <li>{t("answer8Bullet2")}</li>
                <li>{t("answer8Bullet3")}</li>
                <li>{t("answer8Bullet4")}</li>
            </ul>
        </FAQItem>
        <FAQItem question={t("question9")} answer={t("answer9")} />
        <FAQItem question={t("question10")} answer={t("answer10")} />
        <h2>{t("supportAndConsulting")}</h2>
        <FAQItem question={t("question11")} answer={t("answer11")} />
        <FAQItem question={t("question12")} answer={t("answer12")} />
        <h2>{t("dataProtectionAndSecurity")}</h2>
        <FAQItem question={t("question13")} answer={t("answer13")} />
        <h2>{t("costAndPricingStructure")}</h2>
        <FAQItem question={t("question14")} answer={t("answer14")} />   
    </div>
  );
};

export default FAQPageStatic;