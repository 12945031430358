// theme.ts or theme.tsx
import { createTheme } from '@mui/material/styles';
//import { Palette, PaletteOptions, PaletteColorOptions } from '@mui/material/styles/createPalette';
//import { ButtonPropsColorOverrides } from '@mui/material';

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    tertiary: true;
  }
}

declare module '@mui/material/styles' {
  interface Palette {
    blue: CustomColor;
    body: CustomColor;
    green: CustomColor;
    red: CustomColor;
    gray: CustomColor;
    yellow: CustomColor;
    violet: CustomColor;
    orange: CustomColor;
    greenBorder: CustomColor;
    profile: ProfileColor;
    violetSelect: CustomSelect;
    greenSelect: CustomSelect;
    redSelect: CustomSelect;
    blueSelect: CustomSelect;
  }
  interface PaletteOptions {
    blue?: CustomColor;
    body?: CustomColor;
    green?: CustomColor;
    red?: CustomColor;
    gray?: CustomColor;
    yellow?: CustomColor;
    violet?: CustomColor;
    orange?: CustomColor;
    greenBorder?: CustomColor;
    profile?: ProfileColor;
    violetSelect?: CustomSelect;
    greenSelect?: CustomSelect;
    redSelect?: CustomSelect;
    blueSelect?: CustomSelect;
  }
}

// Define custom color properties
export interface CustomColor {
  main: string;
  mainAlpha?: string;
  hover: string;
  hoverAlpha?: string;
}

// Define profile color properties
interface ProfileColor {
  shadow: string;
}

// Define custom selection properties
export interface CustomSelect {
  main: string;
  option: {
    main: string;
    hover: string;
  };
  selectedOption: {
    main: string;
    hover: string;
  };
}

const theme = {
    spacing: 8,
    palette: {
      primary: {
        main: "#2584AB",
      },
      secondary: {
        main: "#357068",
      },
      tertiary: {
        main: "#EA868D",
      },
      blue: {
        main: "#517FA0",
        mainAlpha: "rgba(81, 127, 160, .5)",
        hover: "#2584AB",
        hoverAlpha: "rgba(37, 132, 171, .5)",
      },
      body: {
        main: "#bfcfd8",
        mainAlpha: "rgba(191, 207, 216, .5)",
        hover: "#5FCDBF",
        hoverAlpha: "rgba(95, 205, 191, .5)",
      },
      green: {
        main: "#5FCDBF",
        mainAlpha: "rgba(100, 217, 196, .5)",
        hover: "#357068",
        hoverAlpha: "rgba(53, 112, 104, .5)",
      },
      red: {
        main: "#AF686D",
        mainAlpha: "rgba(175, 104, 109, .5)",
        hover: "#EA868D",
        hoverAlpha: "rgba(234, 134, 141, .5)",
      },
      gray: {
        main: "#424242",
        mainAlpha: "rgba(66, 66, 66, .5)",
        hover: "#5c5c5c",
        hoverAlpha: "rgba(92, 92, 92, .5)",
      },
      yellow: {
        main: "#ffd948",
        mainAlpha: "rgba(255, 217, 72, .5)",
        hover: "#ffb048",
        hoverAlpha: "rgba(255, 176, 72, .5)",
      },
      violet: {
        main: "#866996",
        mainAlpha: "rgba(134, 105, 150, .5)",
        hover: "#a35a9c",
        hoverAlpha: "rgba(163, 90, 156, .5)",
      },
      orange: {
        main: "#edc6a1",
        mainAlpha: "rgba(237, 198, 161, .5)",
        hover: "#dbb693",
        hoverAlpha: "rgba(219, 182, 147, .5)",
      },
      greenBorder: {
        main: "#357068",
        mainAlpha: "rgba(53, 112, 104, .5)",
        hover: "#5FCDBF",
        hoverAlpha: "rgba(95, 205, 191, .5)",
      },
      profile: {
        shadow: "rgba(81, 127, 160, .3)",
      },

      violetSelect: {
        main: "#866996",
        option: {
          main: "#424242",
          hover: "#5FCDBF",
        },
        selectedOption: {
          main: "#a35a9c",
          hover: "#5FCDBF",
        },
      },
      greenSelect: {
        main: "#5FCDBF",
        option: {
          main: "#424242",
          hover: "#357068",
        },
        selectedOption: {
          main: "#357068",
          hover: "#2584AB",
        },
      },
      redSelect: {
        main: "#EA868D",
        option: {
          main: "#424242",
          hover: "#5FCDBF",
        },
        selectedOption: {
          main: "#AF686D",
          hover: "#5FCDBF",
        },
      },
      blueSelect: {
        main: "#517FA0",
        option: {
          main: "#424242",
          hover: "#5FCDBF",
        },
        selectedOption: {
          main: "#2584AB",
          hover: "#5FCDBF",
        },
      },
    },
    components: {
      MuiContainer: {
        styleOverrides: {
          root: {
            padding: '0px !important', // Use !important to increase specificity
          },
          maxWidthLg: {
            padding: '0px !important', // Apply this for specific breakpoint overrides
          },
        },
      },
      MuiTable: {
        styleOverrides: {
          root: {
            fontFamily: "Montserrat",
            fontWeight: "regular",
          },
        },
      },
      MuiTableHead: {
        styleOverrides: {
          root: {
            fontFamily: "Montserrat",
            fontWeight: "regular",
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            fontFamily: "Montserrat",
            fontWeight: "regular",
          },
        },
      },
      MuiTablePagination: {
        styleOverrides: {
          toolbar: {
            fontFamily: "Montserrat",
            fontWeight: "regular",
          },
          selectLabel: {
            fontFamily: "Montserrat",
            fontWeight: "regular",
          },
          displayedRows: {
            fontFamily: "Montserrat",
            fontWeight: "regular",
          },
          actions: {
            fontFamily: "Montserrat",
            fontWeight: "regular",
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          input: {
            fontFamily: "Montserrat",
            fontWeight: "regular",
          },
          button : {
            fontFamily: "Montserrat",
            fontWeight: "regular",
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            fontFamily: "Montserrat",
            fontWeight: "regular",
          },
        },
      },
    },
  };



export default createTheme(theme);
