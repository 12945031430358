import React, { PropsWithChildren } from "react";
import { Navigate, useLocation, useRouteLoaderData } from "react-router-dom";
import { type IAuthData } from "../../utils/interfaces";

const CheckLoginRequired = ({ children } : PropsWithChildren) => {
  const loaderData = useRouteLoaderData("root") as IAuthData;
  const location = useLocation();
  //console.log(Navigate.name);
  //console.log(location);
  const token = loaderData.token;
  if (token) {
    return <Navigate to="/personalize" replace state={{ from: location }} />;
  }

  return children;
};

export { CheckLoginRequired };
