import React, { useEffect, useState } from "react";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  TablePagination,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { type IUserSelectTableProps } from "../../../utils/interfaces";
import { formatDate } from "../../../utils/generalFunctions";

const useStyles = makeStyles()((theme) => ({
  table: {
    minWidth: 650,
    width: "100%",
  },
  header: {
    margin: theme.spacing(2, 0), // Adds margin above and below the header
  },
  tableCell: {
    whiteSpace: "normal",
    wordWrap: "break-word", // Ensures long words will wrap too
    maxWidth: 150,
  },

  smallTableCell: {
    whiteSpace: "normal",
    wordWrap: "break-word", // Ensures long words will wrap too
    maxWidth: 80,
  },
  headCell: {
    backgroundColor: theme.palette.violet.main,
    color: "white",
  },
  evenRow: {
    backgroundColor: theme.palette.violet.mainAlpha,
    "&:hover": {
      // Apply the hover effect only when hovering
      backgroundColor: theme.palette.blue.hoverAlpha,
    },
  },
  oddRow: {
    backgroundColor: theme.palette.blue.mainAlpha,
    "&:hover": {
      // Apply the hover effect only when hovering
      backgroundColor: theme.palette.blue.hoverAlpha,
    },
  },
  selectedRow: {
    backgroundColor: theme.palette.action.selected, // Highlights the selected row
  },
  checkbox: {
    marginLeft: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
      marginLeft: theme.spacing(1),
    },
  },
}));

export default function UserSelectTable({
  users,
  setSelectedUser,
  selectedUser,
}: IUserSelectTableProps) {
  const { classes } = useStyles();
  const { t } = useTranslation();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [showInvalidUsers, setShowInvalidUsers] = useState(true);
  const [filteredUsers, setFilteredUsers] = useState(users);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0); // Reset to the first page when rows per page change
  };

  let rowsToDisplay = filteredUsers.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  useEffect(() => {
    setPage(0);
    setFilteredUsers(users);
  }, [users]);

  useEffect(() => {
    setPage(0);
  }, [filteredUsers]);

  const handleCheckBoxChange = (checked: boolean) => {
    setShowInvalidUsers(checked);

    if (checked) {
      setFilteredUsers(users);
    } else {
      const validUsers = users.filter((user) => user.isValidSubscription !== 'invalid');
      setFilteredUsers(validUsers);
    }
  }

  return (
    <div>
      <Typography variant="h6" className={classes.header}>
        Users
      </Typography>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell
                className={`${classes.smallTableCell} ${classes.headCell}`}
              >
                User Name
              </TableCell>
              {
                <TableCell
                  className={`${classes.smallTableCell} ${classes.headCell}`}
                >
                  Email
                </TableCell>
              }
              <TableCell
                className={`${classes.smallTableCell} ${classes.headCell}`}
              >
                Role
              </TableCell>
              <TableCell
                className={`${classes.smallTableCell} ${classes.headCell}`}
              >
                Organisation
              </TableCell>
              <TableCell
                className={`${classes.smallTableCell} ${classes.headCell}`}
              >
                Industry
              </TableCell>
              <TableCell
                className={`${classes.smallTableCell} ${classes.headCell}`}
              >
                Subscription Status
              </TableCell>
              <TableCell
                className={`${classes.smallTableCell} ${classes.headCell}`}
              >
                Subscription Current Period End
              </TableCell>
              <TableCell
                className={`${classes.smallTableCell} ${classes.headCell}`}
              >
                User Insert Time
              </TableCell>
              <TableCell
                className={`${classes.smallTableCell} ${classes.headCell}`}
              >
                Is Valid Subscription
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rowsToDisplay.map((user, index) => (
              <TableRow
                key={user.id}
                hover
                onClick={() => {
                  setSelectedUser(user);
                }}
                selected={selectedUser?.id === user.id}
                className={`${
                  index % 2 === 0 ? classes.evenRow : classes.oddRow
                }`}
              >
                <TableCell className={classes.smallTableCell}>
                  {user.firstName} {user.lastName}
                </TableCell>
                {
                  <TableCell className={classes.smallTableCell}>
                    {user.email}
                  </TableCell>
                }
                <TableCell className={classes.smallTableCell}>
                  {user.roleName}
                </TableCell>
                <TableCell className={classes.smallTableCell}>
                  {user.organisation}
                </TableCell>
                <TableCell className={classes.smallTableCell}>
                  {user.industry}
                </TableCell>
                <TableCell className={classes.smallTableCell}>
                  {user.subscriptionStatus}
                </TableCell>
                <TableCell className={classes.smallTableCell}>
                  {formatDate(user.subscriptionCurrentPeriodEnd)}
                </TableCell>
                <TableCell className={classes.smallTableCell}>
                  {formatDate(user.userInsertTime)}
                </TableCell>
                <TableCell className={classes.smallTableCell}>
                  {user.isValidSubscription}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 20, 50, 100]}
        component="div"
        count={filteredUsers.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        labelRowsPerPage={t("rowsPerPage")} // Localized label
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to} ${t("of")} ${
            count !== -1 ? count : `${t("moreThan")} ${to}`
          }`
        }
      />
      <div className={classes.checkbox}>
        <FormControlLabel
          label={t("showDeactivatedUsers")}
          control={
            <Checkbox
              checked={showInvalidUsers}
              onChange={(e, checked) => handleCheckBoxChange(checked)}
            />
          }
        />
      </div>
    </div>
  );
}
