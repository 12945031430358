import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "tss-react/mui";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { CustomSelect, CustomColor } from "../../utils/theme";
import { type IOptionSelectorProps } from "../../utils/interfaces";

const useStyles = makeStyles()((theme) => ({
  optionContainer: {
    minHeight: "80px",
    minWidth: "150px",
    position: "relative",
    maxWidth: "300px",
    padding: theme.spacing(0),
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap", // This allows the items to wrap onto the next line
    justifyContent: "left",
    margin: theme.spacing(0, 0),
  },
  toogleContainer: {
    overflow: "hidden",
    transition: "max-height 0.3s ease-in-out width 0.1s ease",
  },
  expanded: {
    maxHeight: "300px", // Adjust as needed to fit content
  },
  collapsed: {
    maxHeight: "50px",
  },
  option: {
    padding: theme.spacing(1, 1),
    margin: theme.spacing(1),
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
    // Added flex basis and a max-width to allow wrapping
    flexBasis: "auto", // Set to 'auto' instead of a fixed value for flexibility
    maxWidth: "100%", // Ensure that options can shrink if needed
    fontSize: "0.7rem",
    color: theme.palette.common.white,
    transition:
      "opacity 1s ease, max-height 1s ease, padding 1s ease, margin 1s ease, transform 1s ease, width 0.1s ease",
    opacity: 1,
    maxHeight: "50px", // Adjust to the height of your option
    overflow: "hidden",
  },
  selectedOption: {
    color: theme.palette.common.white,
  },
  accordionContainer: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  optionHidden: {
    display: "none",
  },
  title: {
    width: "100%", // Ensure the title takes up the full width of the container
    fontWeight: "bold", // Make the title bold
    color: theme.palette.text.primary, // Use the primary text color from the theme
    margin: theme.spacing(1), // Add some margin to the bottom of the title
    cursor: "pointer",
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}));

const OptionSelector = ({
  title,
  options,
  selectedOption,
  onOptionClick,
  colorKey,
  hoverDisplay = true,
} : IOptionSelectorProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { classes } = useStyles();
  const [isExpanded, setIsExpanded] = useState(!hoverDisplay);

  //const toggledClass = isExpanded ? classes.expanded : classes.collapsed;

  const getSelectedColor = (colorKey : string) => {
    
    const color = (theme.palette as any)[colorKey] as CustomSelect | undefined;
    if (color && color.selectedOption && color.selectedOption.main) {
      return color.selectedOption.main;
    }
    return  theme.palette.primary.main
    
  };

  const getOptionColor = (colorKey: string) => {
    const color = (theme.palette as any)[colorKey] as CustomSelect | undefined;
    if (color && color.option && color.option.main) {
      return color.option.main;
    }
    
    return  theme.palette.primary.main;
  };

  const getBackgroundColor = (colorKey : string) => {
    const color = (theme.palette as any)[colorKey] as CustomColor | undefined;
    if (color && color.main) {
      return color.main;
    }
    return  theme.palette.primary.main;
  };

  const toggleAccordion = () => {
    if (!hoverDisplay) {
      setIsExpanded(true);
    } else {
      setIsExpanded(!isExpanded);
    }
  };

  return (
    <div
      className={classes.optionContainer}
      style={{ backgroundColor: getBackgroundColor(colorKey) }}
    >
      <div className={classes.title} onClick={toggleAccordion}>
        {title}
      </div>

      {options.map((option) => {
        const isSelected = selectedOption === option.id;
        let style = {}; // Reset style object
        let optionClasses = `${classes.option} ${
          isSelected ? classes.selectedOption : ""
        }`;

        // Render selected option always and other options only if expanded
        if (isSelected || isExpanded) {
          return (
            <div
              key={option.id}
              onClick={() => {
                toggleAccordion();
                onOptionClick(option.id);
              }}
              className={optionClasses}
              style={{
                ...style,
                ...(isSelected
                  ? { backgroundColor: getSelectedColor(colorKey) }
                  : { backgroundColor: getOptionColor(colorKey) }),
              }}
            >
              {t(option.name)}
            </div>
          );
        }
        return null;
      })}
    </div>
  );
};

// PropTypes validation
OptionSelector.propTypes = {
  title: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  selectedOption: PropTypes.number,
  onOptionClick: PropTypes.func.isRequired,
  colorKey: PropTypes.string,
  hoverDisplay: PropTypes.bool,
};

export default OptionSelector;
