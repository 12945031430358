import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "tss-react/mui";
import { Button } from "@mui/material";

const useStyles = makeStyles()((theme) => ({
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    maxWidth: "960px",
    alignContent: "center",
  },
  button: {
    textTransform: "none",
    marginTop: theme.spacing(2),
    backgroundColor: theme.palette.blue.main,
  },
}));

const HomePage = () => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  const navigate = useNavigate();

  const onLogin = () => {
    navigate("/auth?mode=login");
  };

  return (
    <div className={classes.container}>
      <h2>{t("welcomeTitle")}</h2>
      <p>{t("welcomeText")}</p>

      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        onClick={onLogin}
      >
        {t("signin")}
      </Button>
    </div>
  );
};

export default HomePage;
