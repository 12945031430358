import * as z from 'zod'

const environmentSchema = z.object({
  NODE_ENV: z
    .enum(['development', 'production', 'test'])
    .default('production'),
    REACT_APP_FIREBASE_API_KEY: z.string().min(1),
    REACT_APP_FIREBASE_AUTH_DOMAIN: z.string().min(1),
    REACT_APP_FIREBASE_PROJECT_ID: z.string().min(1),
    REACT_APP_FIREBASE_STORAGE_BUCKET: z.string().min(1),
    REACT_APP_FIREBASE_MESSAGING_SENDER_ID: z.string().min(1),
    REACT_APP_FIREBASE_APP_ID: z.string().min(1),
    REACT_APP_FIREBASE_MEASUREMENT_ID: z.string().min(1),
    REACT_APP_API_BASE_PATH: z.string().min(1),
    REACT_APP_SECRET_KEY: z.string().min(1),
})

const environment = () => environmentSchema.parse(process.env)

export { environment }