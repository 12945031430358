import React from "react";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { useTranslation } from "react-i18next";
import { sendEmailVerification } from "firebase/auth";
import { auth } from "../../../utils/FirebaseAuth";

const useStyles = makeStyles()((theme) => ({
  body: {
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  product: {
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
    textAlign: "center",
    marginBottom: theme.spacing(2),
  },
  description: {
    marginBottom: theme.spacing(2),
  },
  h3: {
    color: theme.palette.text.primary,
    fontWeight: theme.typography.fontWeightMedium,
  },
  button: {
    textTransform: "none",
    marginTop: theme.spacing(0),
    marginLeft: theme.spacing(4),
    backgroundColor: theme.palette.blue.main,
  },
  p: {
    color: theme.palette.text.secondary,
  },
  link: {
    fontSize: "0.875rem",
    textTransform: "none",
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(4),
    backgroundColor: theme.palette.blue.main,
    color: "white",
    height: "36px",
    cursor: "pointer",
    textDecoration: "none",
    paddingLeft: "0.5rem",
    paddingRight: "0.5rem",
    borderRadius: "4px",
    textAlign: "center",
    display: "inline-flex", // Makes it a flex container
    alignItems: "center", // Vertically aligns the content
    justifyContent: "center", // Horizontally aligns the content
    lineHeight: "normal", // Adjust line height to ensure vertical centering
    [theme.breakpoints.down("sm")]: {
      height: "60px",
    },
    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)",
  },
}));

const VerifyEmailPage = () => {
  const { classes } = useStyles();
  const { t } = useTranslation();

  const handleResendVerificationEmailClick = async () => {
    try {
      await sendEmailVerification(auth.currentUser!);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <section className={classes.body}>
      <h1>{t("emailVerificationMissing")}</h1>
      <div className={classes.product}>
        <div className={classes.description}>
          <p className={classes.p}>
            {t("pleaseCheckYourEmailsAndVerifyYourEmailAddress")}.
          </p>
          <Link className={classes.link} to="/logout">
            {t("back")}
          </Link>
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            onClick={handleResendVerificationEmailClick}
          >
            {t("resentVerificationEmail")}
          </Button>
        </div>
      </div>
    </section>
  );
};

export default VerifyEmailPage;
